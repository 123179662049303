<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <v-container>
          <h2 class="mt-8">
            About
          </h2>
          <p class="pt-5">
            Toggle button group can be used to see content in two different views.
          </p>
        
          <v-card>
            <v-col class="ml-0 mt-3 pt-8 px-10 pb-10 d-flex justify-center">
              <img
                src="@/assets/togglebuttongroup/Grid.png"
                alt="display of grid view toolbar"
              >
            </v-col>
            <v-col class="ml-0 mt-3 pt-8 px-10 pb-10 d-flex justify-center">
              <img
                src="@/assets/togglebuttongroup/List.png"
                alt="display of list view toolbar"
              >
            </v-col>
          </v-card>
        </v-container>

        <v-card class="pl-6 mt-6">
          <v-row style="position: relative; left: -12px;">
            <v-card-title class="pb-0">
              Usage
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <!-- todo: figure out an efficient way to generate formatted code in html -->

              <p class="pt-5">
                To implement Toggle Button Group in a page, ensure the following is present:
              </p>
              <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold;">import</span> <span style="color:#800080;">{</span> ToggleButtonGroup <span style="color:#800080;">}</span> from <span style="color:#800000;">"</span><span style="color:#0000e6;">@nswdoe/doe-ui-core</span><span style="color:#800000;">";</span></pre>

              <pre>
                  <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                    <tbody><tr>
                      <td
id="tabnavigation-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                      <td
id="tabnavigation-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                      <td
id="tabnavigation-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">ToggleButtonGroup</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L4"
class="blob-num js-line-number"
data-line-number="3"
/>
                      <td
id="tabnavigation-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:leftClickHandler</span>="<span class="pl-s">leftClickHandler</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L5"
class="blob-num js-line-number"
data-line-number="4"
/>
                      <td
id="tabnavigation-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:rightClickHandler</span>="<span class="pl-s">rightClickHandler</span>"</td>
                    </tr>
                    
                    <tr>
                      <td
id="tabnavigation-html-L6"
class="blob-num js-line-number"
data-line-number="5"
/>
                      <td
id="tabnavigation-html-LC6"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L7"
class="blob-num js-line-number"
data-line-number="6"
/>
                      <td
id="tabnavigation-html-LC7"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">ToggleButtonGroup</span><span class="pl-kos">&gt;</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L8"
class="blob-num js-line-number"
data-line-number="7"
/>
                      <td
id="tabnavigation-html-LC8"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                    </tr>
                   </tbody>
                  </table>
                </pre>
            </v-col>
          </v-row>
        </v-card>

        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'ToggleButtonGroupUsage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [
            {
              name: 'leftClickHandler',
              type: 'function',           
              desc: 'Function that handles action on grid view click.'
            }, {
              name: 'rightClickHandler',
              type: 'function',            
              desc: 'Function that handles action on list view click.'
            }
          ]
        }
      }
    }
  }
</script>

<style scoped>
import 
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>