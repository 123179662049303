<template>
  <v-card class="mt-6">
    <!-- todo: componentise and format this parmas table better -->
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th 
              class="text-left" 
              v-for="(head, i) in heading" 
              :key="`${head}-${i}`"
            >
              {{ head }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(p, i) in properties"
            :key="i"
          >
            <td>{{ p.name }}</td>
            <td>{{ p.type }}</td>
            <td>{{ p.desc }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
export default {
  props: ['definition'], 
  name: 'Card',
  computed:{
    title(){
      return Object.entries(this.definition)[0][1]
    },
    heading(){
      return Object.entries(this.definition)[1][1]
    },
    properties(){
      return Object.entries(this.definition)[2][1]
    }
  }
}
</script>