<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card
          outlined
          class="mr-3"
        >
          <div
            class="d-flex align-start"
            :class="{'flex-columm': $vuetify.breakpoint.smAndDown}"
          >
            <div
              class="navigation-drawer--container"
              :class="{'mobile': $vuetify.breakpoint.smAndDown}"
            >
              <NavigationDrawer
                tag="aside"
                absolute
                permanent
                :clipped="false"
                :items="items"
                :show-profile-mobile="showProfileMobile"
                profileName="Joe Black"
                profile-job-title="Teacher"
                :profile-menu-items="profileMenuItems"
                :app="false"
              />
            </div>
            <div
              class="navigation-drawer--controls"
              :class="{'mobile': $vuetify.breakpoint.smAndDown}"
            >
              <div class="ml-10 mt-10">
                <v-switch
                  v-model="showProfileMobile"
                  label="Show profile in mobile"
                />
                <div>
                  <p>Hint: Resize viewport to view profile in navigation drawer</p>
                </div>
              </div>
              <div class="ml-10 mt-10">
                <v-app-bar-nav-icon
                  tabindex="1"
                  @click.stop="handleNavClick"
                  class="navigation-drawer-toggle"
                  aria-label="Toggle navigation menu"
                />
                Toggle navigation
              </div>
            </div>
          </div>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { NavigationDrawer } from '@nswdoe/doe-ui-core';
 export default {
  name: 'NavigationDrawerDemo',
  components: {
    NavigationDrawer
  },
  data() {
    return {
      showProfileMobile: true,
      items: [
        {
          id: 'nav-demo-home',
          title: 'Home',
          icon: 'home',
          iconOutlined: true,
          clickHandler: () => this.makeActive('nav-demo-home')
        },
        {
          id: 'nav-demo-navigation-item',
          title: 'Navigation item',
          icon: 'mdi-compass-outline',
          clickHandler: () => this.makeActive('nav-demo-navigation-item')
        },
        {
          title: 'Category',
          icon: 'mdi-menu',
          items: [
            {
              id: 'nav-demo-category-sub-category',
              title: 'Sub-category',
              clickHandler: () => this.makeActive('nav-demo-category-sub-category')
            }
          ]
        }
      ],
      profileMenuItems: [
        {
          id: 'nav-demo-profile-manage',
          title: 'Manage DoE account',
          icon: 'mdi-cog-outline',
        },
        {
          id: 'nav-demo-profile-notifications',
          title: 'Notifications',
          icon: 'mdi-alert',
          notificationCount: '10'
        }
      ]
    }
  },
  created() {
    // This nav bar's 'permament' property is interferring with the main app nav bar in small viewports.
    // This is a hack to stop the app applying padding to v-main when the menu is collapsed
    if (this.$vuetify.breakpoint.mdAndDown) {
      setTimeout(() => {
        const mainElement = document.getElementById('app-main');
        if (mainElement) {
          mainElement.style.paddingLeft = '0px';
        }
      });
    }
  },
  destroyed() {
    // For some reason, the main app's nav drawer loses its left padding when this component is destroyed.
    // This is a hack to put it back programmatically.
    if (this.$vuetify.breakpoint.lgAndUp) {
      setTimeout(() => {
        const mainElement = document.getElementById('app-main');
        if (mainElement) {
          mainElement.style.paddingLeft = '300px';
        }
      });
    }
  },
  methods: {
    deactivate(id) {
      const element = document.getElementById(id);
      if (element) {
        element.classList.remove('v-list-item--active');
      }
    },
    makeActive(id) {
      // add activation style - this is handled automatically by the real component based on the route.
      this.deactivate('nav-demo-home');
      this.deactivate('nav-demo-navigation-item');
      this.deactivate('nav-demo-category-sub-category');
      document.getElementById(id).classList.add('v-list-item--active');
    },
    handleNavClick() {
      this.$eventHub.$emit('toggleNavigationDrawer');
    },
  }
};
</script>

<style lang="scss" scoped>
  .navigation-drawer--container {
    height: 360px;
    &.mobile {
      height: 530px;
      aside.v-navigation-drawer {
        border-bottom: 1px solid #E4E4E6 !important;
      }
    }
    aside.v-navigation-drawer {
      max-height: 360px !important;
    }
  }
  .navigation-drawer--controls {
    margin-left: 300px;
    &.mobile {
      margin-left: 0px;
      position: absolute;
      top: 344px;
    }
  }
</style>
