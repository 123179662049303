<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <p class="pt-5">
          The fullscreen modal is an overlay window best used for settings screens, large amounts of tutorial/guide
          information, or any sort of content or functionality that will take up too much space to fit in a smaller
          modal. The fullscreen modal is best used for features that are ancillary to an existing app screen or section.
          As such, the fullscreen modal interaction follows a “open/close” paradigm and is not represented in the app’s
          navigation hierarchy.
        </p>
        <p>
          Fullscreen modals can be closed by the user selecting the X (close) icon in the top left of the modal title
          bar, or using the Action button on the top right. The title bar is fixed and does not scroll, ensuring both close
          and save are always accessible to the user.
        </p>
        <v-card class="imageContainer my-6">
          <v-img
            src="@/assets/fullscreendialog/fullscreendialog.png"
            class="imageExample rounded-0 elevation-2"
          />
        </v-card>
        <v-card class="code-card px-6 pb-10">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p class="mt-6">
            Component code in Vue component &lt;template&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
          <!-- eslint-disable -->
          <div
            style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre
            style="margin: 0; line-height: 125%">&lt;FullscreenDialog
   title=<span style="color: #0000FF">&quot;Fullscreen Dialog Example&quot;</span>
   v-model=<span style="color: #0000FF">&quot;showDialog&quot;</span>
&gt;
   &lt;template v-slot:activator&gt;
      &lt;AdsButton
         button-text=<span style="color: #0000FF">&quot;Open Fullscreen Dialog&quot;</span>
         v-on:click=<span style="color: #0000FF">&quot;showDialog = true&quot;</span>
      /&gt;
   &lt;/template&gt;
   &lt;template v-slot:content&gt;
      Your content goes here.
   &lt;/template&gt;
&lt;/FullscreenDialog&gt;
</pre>
          </div>
          <!-- eslint-enable -->
          <p class="mt-6">
            Component code in Vue component &lt;script&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
          <!-- eslint-disable -->
          <div
            style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre
            style="margin: 0; line-height: 125%"><span style="color: #000080; font-weight: bold">import</span> { FullscreenDialog, AdsButton } <span
            style="color: #000080; font-weight: bold">from</span> <span style="color: #0000FF">&#39;@nswdoe/doe-ui-core&#39;</span>;

export default {
   name: <span style="color: #0000FF">&#39;FullscreenDialogDemo&#39;</span>,
   components: {
      FullscreenDialog,
      AdsButton
   },
   data(){
      <span style="color: #000080; font-weight: bold">return</span> {
         showDialog: false,
      }
   }
}
</pre>
          </div>
          <!-- eslint-enable -->
        </v-card>
        <SimpleTableCard
          title="Component properties"
          :headers="componentProps.headers"
          :data="componentProps.data"
        />
        <SimpleTableCard
          title="Slots"
          :headers="componentSlots.headers"
          :data="componentSlots.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';

export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      componentProps: {
        headers: ['Name', 'Type', 'Description'],
        data: [
          {
            name: 'title',
            type: 'String',
            description: 'Heading displayed in toolbar'
          },
          {
            name: 'v-model',
            type: 'Boolean',
            description: 'Two way binding to open/close the fullscreen dialog'
          }
        ]
      },
      componentSlots: {
        headers: ['Name', 'Description'],
        data: [
          {
            name: 'activator',
            description: 'Slot for the component that activates the fullscreen dialog'
          },
          {
            name: 'title',
            description: 'Replace the h1 heading for title'
          },
          {
            name: 'action',
            description: 'Include an action at the top right, in the toolbar'
          },
          {
            name: 'content',
            description: 'All content below the toolbar needs to be inside the content slot'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px;

  .imageExample {
    padding: 24px;
  }
}

</style>
