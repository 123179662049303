import Vue from 'vue'
import VueRouter from 'vue-router'

import AboutADS from '../views/AboutADS';
import Styles from '../views/Styles';
import Guidelines from '../views/Guidelines';

// components
import AccountMenu from '../views/Components/AccountMenu';
import AdsDataTable from '../views/Components/AdsDataTable';
import Alerts from '../views/Components/Alerts';
import AppBars from '../views/Components/AppBars';
import AppIcons from '../views/Components/AppIcons';
import AppSwitcher from '../views/Components/AppSwitcher';
import Banner from '../views/Components/Banner';
import Buttons from '../views/Components/Buttons';
import Charts from '../views/Components/Charts';
import Chips from '../views/Components/Chips';
import DataSwitcher from '../views/Components/DataSwitcher';
import DataTable from '../views/Components/DataTable';
import DatePickers from '../views/Components/DatePickers';
import Dialog from '../views/Components/Dialog';
import EnvironmentInfo from '../views/Components/EnvironmentInfo';
import ErrorPages from '../views/Components/ErrorPages';
import ExpansionPanel from '../views/Components/ExpansionPanels';
import FormatContactField from '../views/Components/FormatContactField';
import FocusTrap from '../views/Components/FocusTrap';
import IeBlocker from '../views/Components/IEBlocker';
import Loader from '../views/Components/Loader';
import NavigationDrawer from '../views/Components/NavigationDrawer';
import ParentIdLogin from '../views/Components/ParentIdLogin';

import FeatureIcon from '../views/Components/FeatureIcon';
import ShowMore from '../views/Components/ShowMore';
import Stepper from '../views/Components/Stepper';
import TabNavigation from '../views/Components/TabNavigation';
import Inputs from '../views/Components/Inputs';
import OrderedList from '../views/Components/OrderedList';
import Weather from '../views/Components/Weather';
import SkeletonLoader from '../views/Components/SkeletonLoader';
import FullscreenDialog from '@/views/Components/FullscreenDialog';
import ToggleButtonGroup from '../views/Components/ToggleButtonGroup';
import Notifications from '../views/Components/Notifications';
import SessionCountdown from '../views/Components/SessionCountdown';
//plugins
import OAuth from '../views/Plugins/OAuth';

import Resources from '../views/Resources'
import CaseStudies from '../views/CaseStudies';
import ChangeLogs from '../views/ChangeLogs';
import ContactUs from '../views/ContactUs/';

// Developer's Guide
import Accessibility from '../views/DeveloperGuide/Accessibility'
import AdsDesignPatterns from '../views/DeveloperGuide/AdsDesignPatterns'
import Miscellaneous from '../views/DeveloperGuide/Miscellaneous'
import Styling from '../views/DeveloperGuide/Styling'
import ProjectStructure from '../views/DeveloperGuide/ProjectStructure'
import TypescriptVersion from '../views/DeveloperGuide/TypescriptVersion'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'about',
    component: AboutADS
  },
  {
    path: '/styles',
    name: 'styles',
    component: Styles
  },
  {
    path: '/guidelines',
    name: 'guidelines',
    component: Guidelines
  },
  {
    path: '/components/accountmenu',
    name: 'account-menu',
    component: AccountMenu
  },
  {
    path: '/components/alerts',
    name: 'alerts',
    component: Alerts
  },
  {
    path: '/components/appbars',
    name: 'components-appbars',
    component: AppBars
  },
  {
    path: '/components/appicons',
    name: 'components-appicons',
    component: AppIcons
  },
  {
    path: '/components/appswitcher',
    name: 'components-appswitcher',
    component: AppSwitcher
  },
  {
    path: '/components/banner',
    name: 'banner',
    component: Banner
  },
  {
    path: '/components/buttons',
    name: 'buttons',
    component: Buttons
  },
  {
    path: '/casestudies/:appKey',
    name: 'case-studies-app',
    props: true,
    component: CaseStudies
  },
  {
    path: '/casestudies',
    name: 'case-studies',
    component: CaseStudies
  },
  {
    path: '/components/charts',
    name: 'charts',
    component: Charts
  },
  {
    path: '/components/chips',
    name: 'chips',
    component: Chips
  },
  {
    path: '/components/dataswitcher',
    name: 'components-dataswitcher',
    component: DataSwitcher
  },
  {
    path: '/components/datatable',
    name: 'components-datatable',
    component: DataTable
  },
  {
    path: '/components/datepickers',
    name: 'components-datepickers',
    component: DatePickers
  },
  {
    path: '/components/dialog',
    name: 'components-dialog',
    component: Dialog
  },
  {
    path: '/components/environmentInfo',
    name: 'components-environmentInfo',
    component: EnvironmentInfo
  },
  {
    path: '/components/errorpages',
    name: 'errorpages',
    component: ErrorPages
  },
  {
    path: '/components/expansionpanel',
    name: 'expansionpanels',
    component: ExpansionPanel
  },
  {
    path: '/components/featureIcon',
    name: 'feature-icon',
    component: FeatureIcon
  },
  {
    path: '/components/focustrap',
    name: 'focus-trap',
    component: FocusTrap
  },
  {
    path: '/components/formatcontactfield',
    name: 'format-contact-field',
    component: FormatContactField,
  },
  {
    path: '/components/fullscreendialog',
    name: 'fullscreen-dialog',
    component: FullscreenDialog
  },
  {
    path: '/components/ieblocker',
    name: 'ieblocker',
    component: IeBlocker
  },
  {
    path: '/components/inputs',
    name: 'inputs',
    component: Inputs
  },
  {
    path: '/components/loader',
    name: 'components-loader',
    component: Loader
  },
  {
    path: '/components/navigationdrawer',
    name: 'navigation-drawer',
    component: NavigationDrawer
  },
  {
    path: '/components/notifications',
    name: 'notifications-drawer',
    component: Notifications
  },
  {
    path: '/components/orderedlist',
    name: 'ordered-list',
    component: OrderedList
  },
  {
    path: '/components/parentidlogin',
    name: 'parent-id-login',
    component: ParentIdLogin 
  },
  {
    path: '/components/sessioncountdown',
    name: 'components-sessioncountdown',
    component: SessionCountdown
  },
  {
    path: '/components/showmore',
    name: 'components-showmore',
    component: ShowMore
  },
  {
    path: '/components/stepper',
    name: 'components-stepper',
    component: Stepper
  },
  {
    path: '/components/tabnavigation',
    name: 'tab-navigation',
    component: TabNavigation
  },
  {
    path: '/components/skeletonloader',
    name: 'components-skeletonloader',
    component: SkeletonLoader
  },
  {
    path: '/components/weather',
    name: 'components-weather',
    component: Weather
  },
  {
    path: '/plugins/oauth',
    name: 'oauth',
    component: OAuth
  },
  // Developer's Guide
  {
    path: '/developerguide/projectstructure',
    name: 'ads-component-project-structure',
    component: ProjectStructure
  },
  {
    path: '/developerguide/adsdesignpatterns',
    name: 'ads-component-design-patterns',
    component: AdsDesignPatterns
  },
  {
    path: '/developerguide/styling',
    name: 'css-styling-best-practices',
    component: Styling
  },
  {
    path: '/developerguide/accessibility',
    name: 'accessibility-best-practices',
    component: Accessibility
  },
  {
    path: '/developerguide/miscellaneous',
    name: 'miscellaneous',
    component: Miscellaneous
  },
  {
    path: '/developerguide/typescript',
    name: 'typescript',
    component: TypescriptVersion
  },
  {
    path: '/resources',
    name: 'resources',
    component: Resources
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUs
  },
  {
    path: '/changelogs',
    name: 'changelogs',
    component: ChangeLogs
  },
  {
    path: '/components/adsdatatable',
    name: 'adsdatatable',
    component: AdsDataTable
  },
  {
    path: '/components/togglebuttongroup',
    name: 'toggle-button-group',
    component: ToggleButtonGroup
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
