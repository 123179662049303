<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <ul>
          <li>
            Also known as MyEssentials.
          </li>
          <li>
            The parent component passes in the data of My Essentials Apps.
          </li>
        </ul>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/appswitcher/appswitcher.png"
            class="screenshot"
            alt="App switcher"
          >
        </v-row>
        <h3 class="mt-6">
          Default usage in App Bar
        </h3>
        <ul>
          <li>
            Normally displayed at the right side of the App Bar.
          </li>
          <li>
            Can be toggled to be hidden or shown on the App Bar.
          </li>
        </ul>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/appswitcher/appbar.png"
            class="screenshot"
            alt="App bar usage"
          >
        </v-row>

        <v-card class="pl-6 mt-6">
          <v-row
            style="position: relative; left: -12px;"
          >
            <v-card-title class="pb-0">
              Usage
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <!-- todo: link to codepen -->
              <!-- todo: figure out an efficient way to generate formatted code in html -->
              <pre style="color:#000000;background:#ffffff;"><span
                style="color:#800000; font-weight:bold; "
>import</span> <span
                style="color:#800080; "
>{</span> MyEssentials <span style="color:#800080; ">}</span> from <span
                style="color:#800000; "
>"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span
                style="color:#800000; "
>";</span></pre>
              <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody>
                <tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span
                    class="pl-kos"
>&lt;</span><span class="pl-ent">v-container</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span
                    class="pl-ent"
>MyEssentials</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:essentials-data</span>=<span
                    class="pl-s"
>"essentialsData"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">title</span>=<span
                    class="pl-s"
>"Recently used"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">essentialText</span>=<span
                    class="pl-s"
>"Go to My Essentials"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">essentialUrl</span>=<span
                    class="pl-s"
>"https://google.com"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span
                    class="pl-ent"
>AdsTextField</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span
                    class="pl-ent"
>v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
            </v-col>
          </v-row>
        </v-card>

        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';

export default {
  name: 'AppIconUsage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'essentialsData',
            type: 'Array',
            desc: 'My Essentials data'
          },
          {
            name: 'title',
            type: 'String',
            desc: 'Title to be displayed'
          },
          {
            name: 'essentialText',
            type: 'String',
            desc: 'Text to be displayed'
          },
          {
            name: 'essentialUrl',
            type: 'String',
            desc: 'URL for essentialText'
          }
        ]
      }
    }
  }
}
</script>
