<template>
  <v-row>
    <v-col>
      <v-container>
        <v-card
          outlined
          class="mt-10"
        >
          <AdsChart
            title="Stacked Bar Chart"
            :series="seriesBar"
            :inverseLegend="true"
            :segLabelColours="segLabelColours"
            :chartOptions="chartOptions"
          />
        </v-card>

        <v-card
          outlined
          class="mt-5"
        >
          <AdsChart
            title="Stacked Column Chart"
            :series="seriesCol"
            :xlabelShow="true"
            :horizontalBar="false"
            :reversedY="false"
            :segColours="segColours"
            :xLabelCat="xLabelCat"
            :chartOptions="chartOptions"
          />
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { AdsChart } from '@nswdoe/doe-ui-core';
export default {
  name: 'Demo',
  components: {
    AdsChart
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: 'ads-chart',
          stacked: true,
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 500,
            animateGradually: {
              enabled: true,
              delay: 50
            }
          }
        }
      },
      componentKey: 0,
      seriesBar: [
        {
          name: 'Buffer',
          data: [15]
        },
        {
          name: 'Remaining spots',
          data: [65]
        },
        {
          name: 'Walkups',
          data: [5]
        },
        {
          name: 'Shortlisted',
          data: [10]
        },
        {
          name: 'Offers made',
          data: [45]
        },
        {
          name: 'Submitted',
          data: [20]
        }
      ],
      seriesCol: [
        {
          name: 'Local',
          data: [86, 25, 22, 15]
        },
        {
          name: 'Out-of-area',
          data: [19, 15, 15, 0]
        }
      ],
      segColours: ['#041E42', '#407EC9'],
      segLabelColours: ['#fff', '#000', '#000', '#fff', '#fff'],
      xLabelCat: [
        ['Round 1', '11 May - 03 June'],
        ['Round 2', '04 June - 23 June'],
        ['Round 3', '25 July - 05 August'],
        ['Round 4', '-']
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .theme--dark .apexcharts-legend-text {
  color: #fff !important;
}

::v-deep .theme--dark .apexcharts-xaxis-texts-g text {
  color: #fff !important;
  fill: #fff !important;
}
</style>
