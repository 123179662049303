<template>
  <v-container fluid>
    <Banner
      heading="Accessibility Best Practices"
      subtitle="ADS Developer's Guide"
      contentClass="pl-0"
    />
    <v-container fluid>
      Accessibility Best Practices
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner';

export default {
  name: 'Accessibility',
  components: {
    Banner
  },
};
</script>

<style scoped>
</style>
