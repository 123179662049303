<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-skeleton-loader
              class="mx-auto mt-10"
              max-width="300"
              type="card"
            />

            <v-card-title>
              <p class="body-1">
                For more examples please refer to the Vuetify Skeleton Loader document at <a
                  href="https://vuetifyjs.com/en/components/skeleton-loaders/"
                  target="_blank"
                >
                  https://vuetifyjs.com/en/components/skeleton-loaders/
                </a>.
              </p>
            </v-card-title>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'Demo',
};
</script>
