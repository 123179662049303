<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col>
            <v-row justify="end">
              <p>Last updated: 24 December 2020</p>
            </v-row>
          </v-col>
        </v-row>

        <h2 class="mt-6">
          About
        </h2>
        <p>
          The feature icon is a component used to create more prominent representations of standard material icons. It is used throughout the Application Design System when icons need to be emphasized as standalone elements.
        </p>
        <p>
          The properties and its respective default values are:
          <ul>
            <li>icon: "mdi-alert-outline”</li>
            <li>iconSize: null</li>
            <li>color: ADS Navy | #041E42</li>
            <li>backgroundColor: ADS Light Blue | #C8DCF0”</li>
            <li>size: 48 x 48 px</li>
          </ul>
        </p>
        

        <v-card class="mt-12">
          <v-row align="center">
            <v-col
              align="center"
              justify="center"
              class="ml-0 mt-5 mb-6 pa-7 d-flex justify-center"
            >
              <img
                src="../../../assets/featureIcon/circle-icon-mdi-security.png"
                alt="circle icon with mdi-security icon"
              >
            </v-col>
          </v-row>
        </v-card>
        <h2 class="mt-12">
          Example
        </h2>
        <v-card class="mt-1">
          <v-row align="center">
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 d-flex justify-center"
            >
              <img src="../../../assets/featureIcon/circle-icon-dialog.png"> 
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-12">
          <v-row align="center">
            <v-col
              class="ml-0 mt-1 mb-1 pa-1 d-flex justify-center"
            >
              <img src="../../../assets/featureIcon/feature-icon-windowed.png"> 
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-12 pl-6">
          <v-row
            style="position: relative; left: -12px;"
          >
            <v-card-title class="pb-0">
              Usage
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> FeatureIcon <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
              <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-accountmenu-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-accountmenu-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-accountmenu-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">FeatureIcon</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-accountmenu-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">size</span>="<span class="pl-s">48</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-accountmenu-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">color</span>="<span class="pl-s">navy</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-accountmenu-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">backgroundColor</span>="<span class="pl-s">light blue</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-accountmenu-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">icon</span>="<span class="pl-s">mdi-axis</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-accountmenu-html-LC12"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-kos">&lt;</span><span class="pl-ent">template</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-accountmenu-html-LC6"
class="blob-code blob-code-inner js-file-line"
>      <span class="pl-c1">v-slot:icon</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>     <span
v-pre
class="pl-ent"
>&#123;&#123; ICON_CONTENT &#125;&#125;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-accountmenu-html-LC12"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-kos">&lt;/</span><span class="pl-ent">template</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-accountmenu-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">FeatureIcon</span><span class="pl-kos">&gt;</span></td>
                </tr>
             
                <tr>
                  <td
id="file-accountmenu-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-accountmenu-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
            </v-col>
          </v-row>
        </v-card>
        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>    
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'size',
            type: 'String',           
            desc: 'Overall icon size in pixels.'
          }, {
            name: 'iconSize',
            type: 'String',            
            desc: 'Size of the icon in pixels.'
          }, {
            name: 'color',
            type: 'String',
            desc: 'Icon fill colour.'
          }, {
            name: 'backgroundColor',
            type: 'String',
            desc: 'Colour of the icon background.'
          }, {
            name: 'icon',
            type: 'String',
            desc: 'mdi icon names supported.'
          }]
        }
    }
  }
};
</script>

<style>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.imgBackground img {
  width: 100%;
  height: 100%;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>