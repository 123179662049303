<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <Profile
                given-name="Sam"
                surname="Hancock"
                job-title="Community Liason"
                :update-profile="profileUpdater"
                :change-password="passwordUpdater"
                :update-secret-question="secretUpdater"
                :portal-settings="portalUpdater"
                :logout="logoutUpdater"
              />
            </v-row>
          
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="profileUpdater"
                label="updateProfile"
              />
              <v-switch
                v-model="passwordUpdater"
                label="changePassword"
              />
              <v-switch
                v-model="secretUpdater"
                label="updateSecretQuestion"
              />
              <v-switch
                v-model="portalUpdater"
                label="portalSettings"
              />
              <v-switch
                v-model="logoutUpdater"
                label="logout (*Available Soon)"
              />
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { Profile } from '@nswdoe/doe-ui-core';
 export default {
  name: 'Demo',
  components: {
    Profile
  },
  data() {
    return {
      profileUpdater: true,
      passwordUpdater: true,
      secretUpdater: true,
      portalUpdater: true,
      logoutUpdater: true
    }
  }
};
</script>

