<template>
  <v-container>
    <div>
      <h2>Icon guidelines</h2>
      <p>
        ADS uses Material Design system icons (Outlined) for DoE apps (IOS icons for IOS apps). Icons by default are displayed at 24px x 24px. They can be resized by increments of 8px if needed but
        they should be kept consistent to 24px as much as possible.
      </p>
      <p>The icon is limited to a 20px by 20px live area which gives 2px padding around the perimeter.</p>
    </div>
    <v-card>
      <div class="d-flex align-center justify-center">
        <div class="pa-6 pr-8">
          <img
            src="@/assets/styles/icons/live-area.png"
            alt="Live Area"
          >
        </div>
        <div class="pa-6 pl-8">
          <img
            src="@/assets/styles/icons/padding-area.png"
            alt="Padding Area"
          >
        </div>
      </div>
    </v-card>
    <Alert
      class="pt-5"
      in-page
      type="info"
      text="Use them sparingly and follow convention"
      subtext="Remember to use icon’s sparingly. Too many icons can overwhelm the user and text should be the primary method of communication. Only use them where appropriate and be sure to follow
        convention e.g. a printer icon always means ‘print’."
    />
    <h2>Resources</h2>
    <ResourceCard
      title="Material Icons (Outlined) Figma"
      href="https://www.figma.com/file/y7n4UmFNmjO5E6K2lgKbPk/Material-Icons-(Outlined)"
    >
      <img
        slot="image"
        src="@/assets/resources/material-icons.png"
        alt="material icons"
        style="width: 152px"
      >
    </ResourceCard>

    <ResourceCard
      title="Material.io"
      href="https://material.io"
    >
      <img
        slot="image"
        src="@/assets/resources/material-logo.png"
        alt="Material.io logo"
        style="width: 100px"
      >
    </ResourceCard>
  </v-container>
</template>

<script>
import { Alert } from '@nswdoe/doe-ui-core';
import ResourceCard from '@/components/ResourceCard';

export default {
  name: 'Icons',
  components: {
    Alert,
    ResourceCard
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>

</style>
