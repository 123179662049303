<template>
  <v-container>
    <h2>Collaborate with Figma</h2>
    <div class="d-flex align-center mb-6">
      <p class="mb-0">
        View designs and collaborate with ADS designers in Figma.
      </p>
      <v-spacer />
      <v-btn
        class="primary mr-5 mb-2"
        width="133px"
        href="https://www.figma.com/"
        target="_blank"
      >
        Figma
      </v-btn>
    </div>
    <hr>
    <ResourceCard
      title="ADS UI Component Library"
      href="https://www.npmjs.com/package/@nswdoe/doe-ui-core"
    >
      <img
        slot="image"
        src="@/assets/resources/ads-npmjs.png"
        alt="ads npm icon"
      >
    </ResourceCard>

    <ResourceCard
      title="ADS Template for Rapid Development"
      href="https://bitbucket.det.nsw.edu.au/projects/UI/repos/doe-ui-template/browse"
    >
      <img
        slot="image"
        src="@/assets/resources/ads-bitbucket.png"
        alt="ads bitbucket icon"
      >
    </ResourceCard>

    <ResourceCard
      title="See it live"
      href="https://doe-ui-template.surge.sh/"
    >
      <img
        slot="image"
        src="@/assets/resources/ads-template.png"
        alt="ads live template icon"
      >
    </ResourceCard>

    <ResourceCard
      title="Vuetify"
      href="https://vuetifyjs.com/"
    >
      <img
        slot="image"
        src="@/assets/resources/vuetify.png"
        alt="Vuetify icon"
      >
    </ResourceCard>
  </v-container>
</template>

<script>
import ResourceCard from '@/components/ResourceCard';
export default {
  name: 'Developers',
  components: {
    ResourceCard
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
</style>
