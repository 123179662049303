<template>
  <v-row>
    <v-col cols="12">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card outlined>
          <v-row class="row pa-5 pr-8 d-flex justify-center">
            <Banner
              heading="Page Heading"
              :subtitle="showSubtitle ? 'Subtitle text':''"
              :icon="showIcon ? 'security': ''"
              :info="showInfo ? 'info' : ''"
              :icon-size="iconSize"
              :info-size="infoSize"
              :background-image-url="backgroundImage"
            >
              <template
                v-if="showRightPanel"
                v-slot:rightPanel
              >
                <v-card class="counter-card text-center text-no-wrap">
                  <v-card-title class="justify-center white--text">
                    <h1>23</h1>
                  </v-card-title>
                  <v-card-text class="white--text">
                    applications in progress
                  </v-card-text>
                </v-card>
              </template>
            </Banner>
          </v-row>
          <v-row
            class="pa-5"
            justify="space-around"
          >
            <v-switch
              v-model="showSubtitle"
              label="Subtitle"
            />
            <v-switch
              v-model="showIcon"
              label="Show Icon"
            />
            <v-switch
              v-model="showRightPanel"
              label="Show Right Panel"
            />
          </v-row>
          <v-row
            class="pa-5"
            justify="space-around"
          >
            <v-col cols="6">
              <v-select
                :disabled="!showIcon"
                solo
                :items="sizeItems"
                label="Icon Size"
                @change="selectSize"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                solo
                :items="backgroundImages"
                label="Background Image"
                @change="changeBackground"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core'
 export default {
  name: 'Demo',
  components:{
    Banner
  },
  data() {
    return {
      showSubtitle: true,
      showIcon: true,
      showRightPanel: false,
      showInfo: true,
      iconSize: '50px',
      infoSize: '30px',
      sizeItems: [{text:'Small (25px)',value:'25px'},{text:'Default (50px)',value:'50px'},{text:'Large (75px)',value:'75px'}],
      backgroundImages: [{text:'Default',value:'../banner/background1.jpg'},{text:'Background Art',value:'../banner/background2.png'},{text:'Another Option',value:'../banner/background3.png'}],
      backgroundImage:'../banner/background1.jpg'
    }
  },
  methods: {
    selectSize:function(size){
       this.iconSize = size;
    },
    changeBackground: function(background){
      this.backgroundImage = background;
    }
  }
};
</script>
<style scoped>
.counter-card {
  width: 202px;
  height: 128px;
  background-color: #1A3B69;  /* $ads-custom-navy-3 */
}
</style>
