<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-8">
          About
        </h2>
        <p class="pt-5">
          Apps that don't support Internet Explorer can use this page to block the user's access. It also prompts them to download Google Chrome, which is DoE's recommended default browser for all platforms.
        </p>

        <v-card>
          <v-col class="ml-0 mt-3 pt-8 px-10 pb-10 imgBackground d-flex justify-center">
            <img
              src="../../../assets/ieblocker/incompatible-browser.png"
              width="100%"
              height="100%"
              alt="display for incompatible browser"
            >
          </v-col>
        </v-card>

        <p class="pt-10">
          An alternative is to use an in-page alert which still allows the user to access the app but prompts them to switch over to Google Chrome.
        </p>

        <v-card>
          <v-col class="ml-0 mt-3 pa-8 imgBackground d-flex justify-center">
            <img
              src="../../../assets/ieblocker/ieblocker-warning.png"
              width="100%"
              height="100%"
              alt="warning for incompatible browser"
            >
          </v-col>
        </v-card>

        <p class="pt-10">
          For more information on how to best communicate errors and messages to users, refer to our <a href="/guidelines">guidelines</a>.
        </p>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <p class="pt-5">
              To implement the IE Blocker in full page mode:
            </p>

            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> IeBlocker <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000;">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-expansion-html-L1"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L2"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">IeBlocker</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L3"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC3"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-c1">block</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L4"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC4"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">/&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>

            <p class="pt-0">
              To implement the IE Blocker in in-page alert mode:
            </p>

            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> IeBlocker <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-expansion-html-L1"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L2"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">IeBlocker</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L3"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC3"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-c1">inline</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L4"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC4"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">/&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>

      <SimpleTableCard
        title="Component properties"
        :headers="cProps.headers"
        :data="cProps.data"
      />
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'IeBlockerUsage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'appName',
            type: 'String',           
            desc: 'Specify the name of the application being blocked (defaults to "This application")'
          }, {
            name: 'block',
            type: 'Boolean',            
            desc: 'If used, displays the full page IE blocker.<br />(defaults to <span class="code">false</span>)'
          }, {
            name: 'isDemo',
            type: 'Boolean',            
            desc: 'Displays a demonstration version of the IE blocker, even if the browser is Internet Explorer. Note: "[DEMO]" will be displayed to indicate that it is a demonstration instance of the IE blocker.<br />If set to <span class="code">true</span>: displays modified version of the full page IE blocker.<br />If set to <span class="code">false</span>: displays in-page alert IE blocker.<br />(defaults to false)'
          }, {
            name: 'inline',
            type: 'Boolean',            
            desc: 'If used, displays the in-page alert IE blocker.<br />(defaults to <span class="code">false</span>)'
          }, {
            name: 'inlineDismissable',
            type: 'Boolean',            
            desc: 'Show/Hide dismiss button.(No dismiss button for success, defaults to <span class="code">false</span>)'
          }, {
            name: 'publicApp',
            type: 'Boolean',            
            desc: 'Show/Hide browser suggestions for MS Edge in addition to Chrome. (defaults to <span class="code">false</span>)'
          }]
        }
      }
    }
  }
</script>

<style scoped>
import 
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>