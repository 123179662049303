<template>
  <v-container class="d-flex">
    <v-card style="width: 872px">
      <v-row>
        <v-col>
          <v-simple-table
            :style="{
              'border-top-right-radius': showDark ? '0px' : '4px',
              'border-bottom-right-radius': showDark ? '0px' : '4px',
              'background-color': backgroundColour
            }"
            v-bind="{'dark': dark,'light': !dark}"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 100px; text-align: center">
                    Colour
                  </th>
                  <th style="width: 200px">
                    Name
                  </th>
                  <th
                    v-if="showScssName"
                    style="width: 300px"
                  >
                    SCSS Name
                  </th>
                  <th v-if="showHexValue">
                    Hex. Value
                  </th>
                  <th v-if="showUsage">
                    Usage
                  </th>
                  <th v-if="showDescription">
                    Use in light theme
                  </th>
                  <th v-if="showDescription">
                    Use in dark theme
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="c in colours"
                  :key="c.name"
                >
                  <td class="align-center justify-center">
                    <div
                      :style="{'min-width': '40px', 'min-height': '40px', 'border-radius': '50%', 'background-color': c.colour,
                               'border': c.colour === '#FFFFFF' ? '1px solid #A0A5AE' : 'none'}"
                      class="ma-3"
                    />
                  </td>
                  <td>{{ c.name }}</td>
                  <td
                    v-if="showScssName"
                    style="width: 300px"
                  >
                    {{ c.scss }}
                  </td>
                  <td v-if="showHexValue">
                    {{ c.colour }}
                  </td>
                  <td
                    v-if="showUsage"
                    v-html="c.usage"
                  />
                  <td v-if="showDescription">
                    {{ c.useInLightTheme }}
                  </td>
                  <td v-if="showDescription">
                    {{ c.useInDarkTheme }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col v-if="showDark">
          <v-simple-table
            dark
            style="border-top-left-radius: 0px; border-bottom-left-radius: 0px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 100px; text-align: center">
                    Colour
                  </th>
                  <th style="width: 200px">
                    Name
                  </th>
                  <th
                    v-if="showScssName"
                    style="width: 300px"
                  >
                    SCSS Name
                  </th>
                  <th v-if="showHexValue">
                    Hex. Value
                  </th>
                  <th v-if="showUsage">
                    Usage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="c in colours"
                  :key="c.name"
                >
                  <td class="align-center justify-center">
                    <div
                      :style="{'min-width': '40px', 'min-height': '40px', 'border-radius': '50%', 'background-color': c.dark}"
                      class="ma-3"
                    />
                  </td>
                  <td>{{ c.nameDark ? c.nameDark : c.name }}</td>
                  <td
                    v-if="showScssName"
                    style="width: 300px"
                  >
                    {{ c.scss }}-dark
                  </td>
                  <td v-if="showHexValue">
                    {{ c.dark }}
                  </td>
                  <td
                    v-if="showUsage"
                    v-html="c.usage"
                  />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ColourPalette',
  props: {
    colours: {type: Array},
    showDark: {type: Boolean},
    backgroundColour: {type: String, default: '#FFFFFF'},
    dark: {type: Boolean},
    showDescription: {type: Boolean},
    showUsage: {type: Boolean},
    showHexValue: {
      type: Boolean,
      default: true
    },
    showScssName: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.row .col {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;

  &:nth-child(2) {
    padding-left: 0px;
  }
}

td {
  height: 60px;
  border-bottom-color: #a0a5ae !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: auto;
}
</style>
