<template>
  <v-container class="mb-12">
    <div>
      <h2>Alerts and Error Handling</h2>
      <p>We have 4 ways of communicating messages to users. Use this flowchart to determine which component to use.</p>
    </div>
    <!--    <div style="background-color: #E4E4E6; padding: 30px 30px 20px 30px; max-width: 860px;" class="ml-3">-->
    <div style="background-color: #ffffff; padding: 40px 40px 25px 40px; max-width: 800px;">
      <img
        src="../../assets/guidelines/alertsErrorHandling.png"
        height="100%"
        width="100%"
        @click.stop="dialog = true"
      >
    </div>
    <v-dialog
      v-model="dialog"
      max-width="900px"
    >
      <img src="../../assets/guidelines/alertsErrorHandling.png">
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'AlertsErrorHandling',
    data(){
      return {
        dialog: false
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
