<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <AppBar
              not-app
              color="primary"
              home-link="#"
              title="App Bar demo"
              :extended="showExtended"
              :search-filter-mutator="searchFilterMutator"
              :show-full-screen="showFullScreen"
              :show-nav-icon="showNavIcon"
              :enable-home-link="enableHomeLink"
              :show-back-button="showBackButton"
              :show-notifications="showNotifications"
              :show-search="showSearch"
              expand-search="false"
            >
              <template slot="dataSwitcher">
                <DataSwitcher
                  v-model="selected"
                  :items="items"
                  return-object
                  placeholder="Select School..."
                  :prepend-inner-icon="icon ? 'mdi-magnify' : null"
                  :clearable="clearable"
                />
              </template>
              <template slot="extension">
                <p>This is extended content</p>
              </template>
              <template slot="appIcon">
                <AppIcon
                  app-name="my-nsw-gov"
                  transparent="transparent"
                  size="40px"
                />
              </template>
              <template slot="myEssentials">
                <MyEssentials
                  :essentials-data="myEssentialsApps"
                  title="Recently used"
                />
              </template>
              <template slot="profile">
                <Profile
                  given-name="Joe"
                  surname="Smith"
                  job-title="Principle"
                />
              </template>
            </AppBar>
            <v-row justify="space-around">
              <v-switch 
                v-model="showNavIcon" 
                label="showNavIcon"
              />
              <v-switch
                v-model="showFullScreen"
                label="showFullScreen"
              />
            </v-row>
            <v-row justify="space-around">
              <v-switch
                v-model="enableHomeLink"
                label="enableHomeLink"
              />
              <v-switch
                v-model="showBackButton"
                label="showBackButton"
              />
            </v-row>
            <v-row justify="space-around">
              <v-switch
                v-model="showSearch"
                label="showSearch"
              />
              <v-switch
                v-model="showNotifications"
                label="showNotifications"
              />
            </v-row>
            <v-row justify="space-around">
              <v-switch
                v-model="showExtended"
                label="showExtended"
              />
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { AppBar, AppIcon, Profile, MyEssentials, DataSwitcher } from '@nswdoe/doe-ui-core';
import essentials from '../../../api/essentials';

export default {
  name: 'Demo',
  components: {
    AppBar,
    AppIcon,
    DataSwitcher,
    MyEssentials,
    Profile
  },
  data() {
    return {
      dataSwitcher: true,
      showExtended: false,
      showFullScreen: false,
      showNavIcon: true,
      searchFilterMutator: true,
      enableHomeLink: true,
      showBackButton: false,
      showNotifications: false,
      showSearch: false,
      myEssentialsApps: essentials,
      transparent: true,
      icon: true,
      clearable: true,
      light: false,
      items: [{
        value: 1,
        text: 'Rooty Hill Public'
      }, {
        value: 2,
        text: 'Bella Vista Public'
      }],
      selected: null 
    };
  }
};
</script>

<style scoped>
.v-app-bar.v-app-bar--fixed {
  position: relative;
}

.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
