<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <Weather
                app-id="caed0cabe816a8810fcf0d37e4ba311a"
                :is-white="false"
              />
            </v-row>

            <v-card-title>
              <p class="body-1">
                The <b>Weather</b> component fetches weather data from the free <b>Weather API</b> at <a
                  href="https://api.openweathermap.org"
                  target="_blank"
                >https://api.openweathermap.org</a>. The weather
                icon you see above is based on your current location.
              </p>
            </v-card-title>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {Weather} from '@nswdoe/doe-ui-core';

export default {
  name: 'Demo',
  components: {
    Weather
  }
};
</script>
