<template>
  <v-container fluid>
    <Banner
      heading="Case Studies"
      subtitle="Examples of DoE apps using ADS"
      content-class="pl-0"
    />
    <v-container fluid>
      <div class="d-flex flex-wrap pa-12">
        <AppCard
          v-for="(app, i) in apps"
          :key="i"
          class="ml-6 mb-6"
          :thumbnail-src="`${app.assetFolder}/${app.thumbnail}`"
          :title="app.name"
          :subtitle="app.description"
          @click="openAppDialog(app.key)"
        />
        <v-card
          width="275px"
          height="290px"
          class="pt-12 px-6 ml-6"
        >
          <div class="font-weight-bold mb-6">
            Want to showcase your app here?
          </div>
          <p>Contact us with the name of the app, description, and screenshots.</p>
          <a href="mailto:ui@det.nsw.edu.au">ui@det.nsw.edu.au</a>
        </v-card>
      </div>
    </v-container>
    <AppDialog
      v-model="showDialog"
      v-bind="selectedApp"
      @close="closeAppDialog"
    />
  </v-container>
</template>

<script>
import Banner from '@/components/Banner';
import AppCard from './AppCard';
import AppDialog from './AppDialog';
import comps from './components';

export default {
  name: 'CaseStudies',
  components: {
    Banner,
    AppCard,
    AppDialog
  },
  props: {
    appKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showDialog: false,
      selectedAppKey: null,
      apps: [
        {
          key: 'ictplus',
          name: 'ICT PLUS',
          description: 'Provides school leaders with insights about their schools technology usage.',
          assetFolder: 'ictplus',
          thumbnail: 'ictplus_thumbnail.png',
          screenshots: ['ictplus_1.png', 'ictplus_2.png', 'ictplus_3.png', 'ictplus_4.png'],
          paragraphs: [
            'ICT PLUS was developed to give school principals and IT coordinators a single source of truth for data on their schools technology usage.',
            'It features a dashboard showing a breakdown of the school’s managed devices and a data table with details about each device.',
            'This combined with the internet and network usage graphs helps them to better plan and allocated resources in their school.'
          ],
          components: [
            comps.appBar,
            comps.pageHeader,
            comps.dataSwitcher,
            comps.tabNavigation,
            comps.filterChips,
            comps.dataTable,
            comps.inputsAndControls,
            comps.inPageAlerts
          ],
          suffix: 'Charts are created using Highcharts'
        }, {
          key: 'edpush',
          name: 'EdPush',
          description: 'Create, manage and send out push notifications to DoE apps.',
          assetFolder: 'edpush',
          thumbnail: 'edpush_thumbnail.png',
          screenshots: ['edpush_1.png', 'edpush_2.png', 'edpush_3.png'],
          paragraphs: [
            'EdPush allows product admins to create and send out push notifications to their users.'
          ],
          components: [
            comps.appBar,
            comps.navigationDrawer,
            comps.pageHeader,
            comps.filterChips,
            comps.dataTable,
            comps.inputsAndControls,
            comps.progressStepper,
            comps.dialogs
          ],
          moreInformation: [
            'Hosted on Openshift',
            {
              text: 'Test environment (VPN required)',
              url: 'https://notification-service-ui-spaaws.cloudapps.pre.nsw.education/'
            }
          ]
        }, {
          key: 'staffportal',
          name: 'Staff Portal',
          description: 'Access to DoE tools and resources.',
          assetFolder: 'staffportal',
          thumbnail: 'staffportal_thumbnail.png',
          screenshots: ['staffportal_1.png', 'staffportal_2.png', 'staffportal_3.png'],
          paragraphs: [
            'A redesign of the Staff Portal to make it more on brand, user friendly and accessible.'
          ],
          components: [
            comps.appBar,
            comps.navigationDrawer,
            comps.pageHeader,
            comps.cards,
            comps.inputsAndControls
          ],
          moreInformation: [
            'Hosted on Amazon S3 + Liferay',
            {
              text: 'Test environment',
              url: 'https://portal.pre.det.nsw.edu.au/group/student3'
            }
          ]
        }, {
          key: 'dlrh',
          name: 'Digital Learning Resources Hub',
          description: 'Access to digital teaching material.',
          assetFolder: 'dlrh',
          thumbnail: 'dlrh_thumbnail.png',
          screenshots: ['dlrh_1.png', 'dlrh_2.png', 'dlrh_3.png'],
          paragraphs: [
            'An app for school teachers to access digital learning resources for their classes and connect with other teachers via virtual staffrooms.'
          ],
          components: [
            comps.appBar,
            comps.navigationDrawer,
            comps.pageHeader,
            comps.cards,
            comps.inputsAndControls,
            comps.tabNavigation
          ]
        }, {
          key: 'oes',
          name: 'Online Enrolment System',
          description: 'Simplifying online enrolment for parents and school staff.',
          assetFolder: 'oes',
          thumbnail: 'oes_thumbnail.png',
          screenshots: ['oes_1.png', 'oes_2.png', 'oes_3.png', 'oes_4.png'],
          paragraphs: [
            'The Online Enrolment System (OES) is designed to make applying to enrol in NSW public schools easier for parents, and easier for school staff to track and verify.',
            'OES provides a fast, digital and convenient way to process applications for primary school students and submit their details to the DoE student database.',
            'Extensions are in production for out-of-area enrolment as well as year 6/7 tranisition.'
          ],
          components: [
            comps.appBar,
            comps.pageHeader,
            comps.dataSwitcher,
            comps.filterChips,
            comps.dataTable,
            comps.inputsAndControls,
            comps.formElements,
            comps.expansionPanels
          ],
          platforms: [
            'Desktop browser',
            'Mobile web (PWA)'
          ],
          moreInformation: [{
            text: 'Official website',
            href: 'https://education.nsw.gov.au/parents-and-carers/online-enrolment-for-nsw-public-schools'
          }]
        }, {
          key: 'msa',
          name: 'Manage Staff Access',
          description: 'Schools managing staff\'s access to applications.',
          assetFolder: 'msa',
          thumbnail: 'msa_thumbnail.png',
          screenshots: ['msa_1.png', 'msa_2.png', 'msa_3.png', 'msa_4.png'],
          paragraphs: [
            'Manage Staff Access (MSA) is a design-first re-design of the Access Management Utility (AMU) web application which schools use to manage access to their teachers and staff. It significantly improves the application administration workflow for schools by taking a user centric view over a data centric view.',
            'It works hand-in-hand with the Manage My Access (MMA) application which is used by teachers and administrative staff to request access to roles and applications.'
          ],
          components: [
            comps.appBar,
            comps.navigationDrawer,
            comps.dataSwitcher,
            comps.pageHeader,
            comps.filterChips,
            comps.inputsAndControls,
            comps.dialogs
          ],
          platforms: [
            'Desktop browser',
            'Mobile web (PWA)'
          ],
          
          moreInformation: [
            'Hosted on Openshift',
            {
              text: 'Test environment',
              url: 'https://msa.pre.education.nsw.gov.au/'
            }
          ]
        }, {
          key: 'mma',
          name: 'Manage My Access',
          description: 'Staff requesting access to roles and applications.',
          assetFolder: 'mma',
          thumbnail: 'mma_thumbnail.png',
          screenshots: ['mma_1.png', 'mma_2.png', 'mma_3.png', 'mma_4.png'],
          paragraphs: [
            'Manage My Access (MMA) was built to enable teachers and administrative staff to request access to applications at schools.',
            'It reduces the administrative burden on schools by grouping together a standard set of application access for teachers and administrative staff.',
            'Requests are sent to the Manage Staff Access (MSA) application for schools to approve or deny requests.'
          ],
          components: [
            comps.appBar,
            comps.navigationDrawer,
            comps.dataSwitcher,
            comps.expansionPanels,
            comps.pageHeader,
            comps.inputsAndControls,
            comps.dialogs
          ],
          platforms: [
            'Desktop browser',
            'Mobile web (PWA)'
          ],
          moreInformation: [
            'Hosted on Openshift',
            {
              text: 'Test environment',
              url: 'https://mma.pre.education.nsw.gov.au/'
            }
          ]
        },
      ]
    };
  },
  computed: {
    selectedApp() {
      return this.apps.find(a => a.key === this.selectedAppKey);
    }
  },
  watch: {
    $route() {
      // close any dialog when browser back to this location
      if (this.$router.currentRoute.name === 'case-studies') {
        this.showDialog = false;
        this.selectedAppKey = null;
      }
    }
  },
  created() {
    if (this.appKey) {
      this.selectedAppKey = this.appKey;
      this.showDialog = true;
    }
  },
  methods: {
    openAppDialog(appKey) {
      this.$router.push(`/casestudies/${appKey}`);
      this.selectedAppKey = appKey;
      this.showDialog = true;
    },
    closeAppDialog() {
      this.$router.push('/casestudies');
      this.showDialog = false;
      this.selectedAppKey = null;
    }
  }
};
</script>

<style scoped>
</style>
