<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="showNotifications"
                label="showNotifications"
                @change="updateNotification"
              />
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 export default {
  name: 'Demo',
  data() {
    return {
      showNotifications: false
    }
  },
  methods:{
    updateNotification(){
      this.$eventHub.$emit('showNotifications');
    }
  }
};
</script>

