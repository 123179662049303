<template>
  <v-container>
    <div class="mt-12">
      <h2>Spacing system</h2>
      <p>Space and size everything in increments of 8px. You can use 4px where needed.</p>
    </div>
    <div style="background-color: #ffffff; padding: 40px 40px 25px 40px; max-width: 750px;">
      <img
        src="../../assets/guidelines/spacingSystem.png"
        height="100%"
        width="100%"
      >
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'SpacingSystem'
  }
</script>

<style lang="scss" scoped>
</style>
