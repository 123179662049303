<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <ul>
          <li>
            Data switchers allow users to switch between different views within an app, primarily different schools
          </li>
          <li>
            It is based on the <a href="https://vuetifyjs.com/en/components/autocompletes/#autocompletes">Vuetify autocomplete component</a>
          </li>
          <li>
            When the data switcher is active, it may show a dropdown list of views available to that user
          </li>
          <li>
            The field at the top acts as a search filter for that list
          </li>
        </ul>

        <h3 class="mt-6">
          Note on accessibility with JAWS screen reader
        </h3>
        <p>
          Accessibility tests with the <a href="https://www.freedomscientific.com/products/software/jaws/">JAWS screen reader</a> have reported 'critical' issues with keyboard control of the switicher. When using JAWS, pressing the down-arrow key to open the list and make a selection does not function consistently.
        </p>
        <p>
          According to <a href="https://www.freedomscientific.com/training/jaws/hotkeys/">JAWS keyboard hotkeys</a> documentation, "ALT + Down-Arrow" should be used to open a combo box list. We consider this to be an issue with JAWS. The component has been shown to work correctly using other screen readers such as NVDA, VoiceOver (MacOs), and ChromeVox.
        </p>

        <h3 class="mt-10">
          Example - no view selected yet
        </h3>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/dataswitcher/noview.png"
            class="screenshot"
            alt="Data Switcher no view selected"
          >
        </v-row>

        <h3 class="mt-10">
          Example - switching views
        </h3>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/dataswitcher/switchingviews.png"
            class="screenshot"
            alt="Data Switcher switching views"
          >
        </v-row>

        <h3 class="mt-10">
          Example - suggested views
        </h3>
        <p>
          If a user has access to a large number of views that may cause performance issues, it may be appropriate to have a list of suggested views such as recent or most frequently viewed. The user can still search for all available views in the input field.
        </p>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/dataswitcher/suggestedviews.png"
            class="screenshot"
            alt="Data Switcher suggested views"
          >
        </v-row>

        <h3 class="mt-10">
          States
        </h3>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/dataswitcher/states.png"
            class="screenshot"
            alt="Data Switcher states"
          >
        </v-row>

        <v-card class="code-card px-6 py-3 mt-12 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <v-card-subtitle class="pl-0">
            ADS DataSwticher wraps <a href="https://vuetifyjs.com/en/components/autocompletes/#autocompletes">vuetify's v-autocomplete.</a>
          </v-card-subtitle>
          <p class="mt-3">
            Component code in Vue component &lt;template&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
<!-- eslint-disable -->
<div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
<pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;DataSwitcher</span>
  <span style="color: #0000CC">v-model=</span><span>&quot;selected&quot;</span>
  <span style="color: #0000CC">:items=</span><span>&quot;items&quot;</span>
  <span style="color: #0000CC">placeholder=</span><span>&quot;Search for an item...&quot;</span>  
<span style="color: #007700">&gt;</span>
  <span style="color: #007700">&lt;template</span> <span style="color: #0000CC">v-slot:item=</span><span>&quot;data&quot;</span><span style="color: #007700">&gt;</span>
    <span style="color: #007700">&lt;span&gt;</span><span v-html="'{{ `${data.item.text} (${data.item.value})` }}'"></span><span style="color: #007700">&lt;/span&gt;</span>
  <span style="color: #007700">&lt;/template&gt;</span>
<span style="color: #007700">&lt;/DataSwitcher&gt;</span>
</pre>
</div>
          <!-- eslint-enable -->
          <p class="mt-6">
            Component code in Vue component &lt;script&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
<!-- eslint-disable -->
<div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #008000; font-weight: bold">import</span> { DataSwitcher } from <span style="color: #BA2121">&quot;@nswdoe/doe-ui-core&quot;</span>;

<span style="color: #008000; font-weight: bold">export</span> <span style="color: #008000; font-weight: bold">default</span> {
  name<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;DataSwitcherDemo&quot;</span>,
  components<span style="color: #666666">:</span> {
    DataSwitcher
  },
  data() {
    <span style="color: #008000; font-weight: bold">return</span> {
      selected<span style="color: #666666">:</span> <span style="color: #008000; font-weight: bold">null</span>,
      items<span style="color: #666666">:</span> [{
        value<span style="color: #666666">:</span> <span style="color: #666666">1</span>,
        text<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;Apple&quot;</span>
      }, {
        value<span style="color: #666666">:</span> <span style="color: #666666">2</span>,
        text<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;Banana&quot;</span>
      }, {
        value<span style="color: #666666">:</span> <span style="color: #666666">3</span>,
        text<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;Dragon Fruit&quot;</span>
      }, {
        value<span style="color: #666666">:</span> <span style="color: #666666">4</span>,
        text<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;Fig&quot;</span>
      }]
    };
  }
};
</pre></div>
          <!-- eslint-enable -->
        </v-card>

        <SimpleTableCard
          title="Component properties"
          subtitle="All props, slots, and events from <a href=&quot;https://vuetifyjs.com/en/components/autocompletes/#api&quot;>vuetify's v-autocomplete</a> are available."
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'DataSwitcherUsage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'items',
            type: 'Array',           
            desc: "An array of options. Default structure: {value: 123, text: 'abc'}"
          }, {
            name: 'itemText',
            type: 'String',
            desc: "The name of the text property. (default 'text')"
          }, {
            name: 'itemValue',
            type: 'String',
            desc: "The name of the value property. (default 'value')"
          }]
        }
      }
    }
  }
</script>
