<template>
  <v-container
    fluid
    class="content"
  >
    <v-row
      align="center"
      class="banner ml-0 contentSpacer mb-10"
    >
      <v-col md="8">
        <div class="d-flex align-start app-banner">
          <img src="../../assets/design-system-logo.svg">
          <div class="d-flex flex-column mt-2 ml-8">
            <div class="apptitle">
              application
            </div>
            <div class="apptitle appsubtitle">
              design system
            </div>
            <div class="mt-10">
              A flexible app design system for NSW Education
            </div>
            <div class="mt-10">
              <v-btn
                class="pl-10 pr-10 white--text"
                color="#CE0037"
                x-large
                @click="getStartedClicked"
              >
                Get started
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="pb-12 contentSpacer"
      :class="{'px-6': $vuetify.breakpoint.smAndDown}"
    >
      <v-col md="8">
        <v-row
          align="center"
          :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
        >
          <v-col md="6">
            <h2 style="line-height: 2rem">
              ADS is a library of UI components you can use to design and build DOE apps flexibly, easily and fast.
            </h2>
            <p class="mt-3">
              Every aspect of the Application Design System has been optimised to enable project teams to rapidly prototype, design and develop apps using proven, accessible components
              fully compliant with the NSW Department of Education’s standards and guidelines.
            </p>
          </v-col>
          <v-col
            md="6"
            align="center"
          >
            <img
              src="../../assets/about/about-ads-icons.png"
              alt="ads-icons"
              style="max-width: 100%"
            >
          </v-col>
        </v-row>

        <h2 class="mt-8">
          Why use ADS?
        </h2>
        <v-row>
          <v-col>
            <v-sheet>
              <div class="item rc" />
              <h4 class="mt-3 mb-3">
                Reusable Components
              </h4>
              <p>More efficient delivery across multiple apps</p>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet>
              <div class="item rd" />
              <h4 class="mt-3 mb-3">
                Rapid Development
              </h4>
              <p>Fast onboaring for new team members</p>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet>
              <div class="item bc" />
              <h4 class="mt-3 mb-3">
                Better Compliance
              </h4>
              <p>Consistent, usable, accessible applications</p>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet>
              <div class="item bp" />
              <h4 class="mt-3 mb-3">
                Better Products
              </h4>
              <p>Liberates designers, empowers developers</p>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row
          align="center"
          class="mt-6"
          :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
        >
          <v-col
            md="6"
            class="align-self-stretch pt-12"
            order-md="2"
          >
            <h2 class="pb-3">
              How does it work?
            </h2>
            <p>ADS is the product of three core layers, each based on years of ongoing research, compliance and testing.</p>
            <p>First, we use DOE’s GEL to ensure brand compliance. Secondly, we apply Google’s Material Design for core design principles.</p>
            <p>And lastly, we employ Vuetify as our implementation layer for app development. (VueJs for web apps and Apple or Android native frameworks for native mobile apps).</p>
          </v-col>
          <v-col
            md="6"
            align="center"
            order-md="1"
          >
            <img
              src="../../assets/about/ads-diagram.png"
              alt="Ads diagram"
              style="max-width: 100%"
            >
          </v-col>
        </v-row>

        <h2 class="mt-12">
          Get an overview
        </h2>
        <p class="mb-4 body-1">
          Get a quick peek at what ADS is all about with this short video.
        </p>
        <p v-if="videoId">
          <youtube
            :video-id="videoId"
            player-width="100%"
          />
        </p>

        <h2 class="mt-8">
          Dive right in!
        </h2>
        <div class="d-flex align-center">
          <p class="mb-0 mr-6">
            The best way to discover what ADS can do for your design, development or project team is to start exploring our styles, guidelines and components. So get started
            now!
          </p>
          <v-btn
            class="pl-10 pr-10 white--text"
            color="#CE0037"
            x-large
            @click="getStartedClicked"
          >
            Get started
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getIdFromURL} from 'vue-youtube-embed';

export default {
  name: 'AboutADS',
  data() {
    return {
      videoId: null
    };
  },
  mounted() {
    this.videoId = getIdFromURL('https://www.youtube.com/watch?v=TIbcLZY0UDY');
  },
  methods: {
    getStartedClicked() {
      this.$router.push('/resources');
    }
  }
};
</script>

<style scoped>
.v-sheet {
  background-color: transparent;
}

.item {
  width: 189px;
  height: 189px;
  border-radius: 3.5px;
}

.rd {
  background-image: url(../../assets/icon-rapid.svg);
  background-size: auto 100%;
  display: inline-block;
}

.bc {
  background-image: url(../../assets/icon-accessible.svg);
  background-size: auto 100%;
  display: inline-block;
}

.bp {
  background-image: url(../../assets/icon-better.svg);
  background-size: auto 100%;
  display: inline-block;
}

.rc {
  background-image: url(../../assets/icon-reusable.svg);
  background-size: auto 100%;
  display: inline-block;
}

.app-banner {
  color: #FFFFFF;
}

.apptitle {
  font-size: 3em;
  font-weight: 600;
  color: #fff;
}

.appsubtitle {
  font-weight: normal;
  line-height: 1.0;
}

.banner {
  background-image: url(../../assets/background3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner .col {
  padding: 30px 0;
}
</style>
