<template>
  <v-card class="mt-6">
    <div class="d-flex align-center pa-6">
      <div
        class="d-flex justify-center"
        style="width: 160px"
      >
        <slot name="image" />
      </div>
      <div
        class="d-flex flex-grow-1 align-self-stretch"
        :class="{'flex-column': $vuetify.breakpoint.xs}"
      >
        <div class="pl-6 pt-6">
          <h3>{{ title }}</h3>
          <slot name="description">
            <p class="mt-6">
              {{ description }}
            </p>
          </slot>
        </div>
        <v-spacer />
        <div class="d-flex align-center justify-end">
          <v-btn
            outlined
            class="pl-10 pr-10"
            :href="href"
            target="_blank"
          >
            View
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  export default {
    props: {
      imageSrc: String,
      title: String,
      href: String,
      description: String
    }
  }
</script>