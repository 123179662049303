<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <Dialog
              :open-dialog="openDialog"
              :title="dialogTitle"
              max-width="520px"
              :icon="displayIcon ? icon : null"
              :display-close-btn="displayCloseBtn"
              :message="dialogMessage"
              :actions="showActions ? actions : null"
              :return-handler="handleDialogReturn"
              @close="openDialog=false"
            />
            <v-row
              class="pa-5"
              justify="center"
            >
              <v-btn
                class="primary"
                @click="openDialog = true"
              >
                Open Dialog
              </v-btn>
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="displayCloseBtn"
                label="Close Button"
              />
              <v-switch
                v-model="showActions"
                label="Action Buttons"
              />
              <v-switch
                v-model="displayIcon"
                label="Display Icon"
              />
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="mx-12"
              >
                <AdsTextField
                  v-model="dialogTitle"
                  label="Dialog title"
                  maxlength="50"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-12">
                <AdsTextField
                  v-model="dialogMessage"
                  label="Dialog message"
                  maxlength="200"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { Dialog, AdsTextField } from '@nswdoe/doe-ui-core';

 export default {
  name: 'DialogDemo',
  components: {
    Dialog,
    AdsTextField
  },
  data() {
    return {
      openDialog: false,
      actions: [
        { name: 'Cancel', color:'#041E42', size: 'large', outlined: true },
        { name: 'Ok', color:'#041E42', size: 'large' }
      ],
      displayCloseBtn: true,
      displayIcon: true,
      showActions: true,
      dialogTitle: 'Dialog title',
      dialogMessage: 'Message goes here!',
      icon: 'security'
    }
  },
  methods: {
    handleDialogReturn(result) {
      if (result === 'Ok') {
        window.alert('You clicked Ok')
      }
      this.openDialog = false
    }
  }
};
</script>

