<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <ul>
          <li>
            Date pickers open when the user clicks on the date field
          </li>
          <li>
            The user can choose the date from the picker or enter it manually
          </li>
          <li>
            Default format dd/mm/yyyy
          </li>
          <li>
            Date range is a separate component that wraps two date pickers
          </li>
        </ul>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/datepicker/datepicker.png"
            class="screenshot"
            alt="date picker"
          >
        </v-row>

        <h2>
          Single Date Picker
        </h2>
        <v-card class="code-card px-6 py-3 mt-6 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p>
            The ADS Date Picker wraps vuetify's v-date-picker with a v-text-field.
            Attributes and events are bound to the v-text-field by default. Use
            the 'datePickerAttrs' prop to bind attributes to the v-date-picker.
          </p>
          <p>
            Date formatting is handled by
            <a href="https://day.js.org/docs/en/display/format">Day.js</a>
          </p>
          <p class="mt-6">
            Component code in Vue component &lt;template&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
          <!-- eslint-disable -->
          <div
            style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"
          >
            <pre
              style="margin: 0; line-height: 125%"
            ><span style="color: #007700">&lt;DatePicker</span>
  <span style="color: #0000CC">v-model=</span><span>&quot;singleDate&quot;</span>
  <span style="color: #0000CC">label=</span><span>&quot;Select Date&quot;</span>
<span style="color: #007700">/&gt;</span>
</pre>
          </div>
          <!-- eslint-enable -->
          <br>
        </v-card>

        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />

        <SimpleTableCard
          title="Component events"
          :headers="cEvents.headers"
          :data="cEvents.data"
        />

        <h2>
          Date Picker Range
        </h2>
        <v-card class="code-card px-6 py-3 mt-6 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p>
            The ADS Date Picker Range component wraps two single ADS Date Pickers with some
            default from/to logic.
          </p>
          <p class="mt-6">
            Component code in Vue component &lt;template&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
          <!-- eslint-disable -->
          <!-- HTML generated using hilite.me -->
          <div
            style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"
          >
            <pre
              style="margin: 0; line-height: 125%"
            ><span style="color: #007700">&lt;DatePickerRange</span>
  <span style="color: #0000CC">v-model=</span><span>&quot;rangeDate&quot;</span>
<span style="color: #007700">/&gt;</span>
</pre>
          </div>
          <!-- eslint-enable -->
          <br>
        </v-card>

        <SimpleTableCard
          title="Component properties"
          :headers="rangeProps.headers"
          :subtitle="rangeProps.subtitle"
          :data="rangeProps.data"
        />

        <SimpleTableCard
          title="Component events"
          :headers="cEvents.headers"
          :data="cEvents.data"
        />

        <SimpleTableCard
          title="Component slots"
          v-bind="rangeSlots"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'AppIconUsage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'value',
            type: '[String, Array]',
            desc: 'The date in ISO string format (yyyy-mm-dd)'
          },
          {
            name: 'label',
            type: 'String',
            desc: 'Input label'
          },
          {
            name: 'placeholder',
            type: 'String',
            desc: 'Input placeholder text'
          },
          {
            name: 'datePickerAttrs',
            type: 'Object',
            desc: 'Attributes to bind onto the underlying v-date-picker'
          },
          {
            name: 'dateFormat',
            type: 'String',
            desc:
              "The format of the date that should display once a date is selected. (Default 'dd/mm/yyyy' or 'mm/yyyy')"
          },
          {
            name: 'outputDateFormat',
            type: 'String',
            desc:
              "The format of the output date that will be emitted once a date is selected. (Default 'yyyy-mm-dd')"
          },
          {
            name: 'yearPicker',
            type: 'Boolean',
            desc:
              "Date picker forces year selection, then month, then day on date picker. Typically used for birthdate fields. (Default 'false')"
          },
          {
            name: 'rules',
            type: 'Array',
            desc:
              "Additional field validation rules. e.g. [val => val < '2030-01-01' || 'Date must be earlier than 2030']"
          },
          {
            name: 'allowFuture',
            type: 'Boolean',
            desc: 'Allow future dates. (Default true)'
          },
          {
            name: 'allowPast',
            type: 'Boolean',
            desc: 'Allow past dates. (Default true)'
          },
          {
            name: 'allowToday',
            type: 'Boolean',
            desc: 'Allow today. (Default true)'
          }
        ]
      },
      cEvents: {
        headers: ['Event', 'Desc'],
        data: [
          {
            event: 'date-picker-activated',
            desc: 'Emitted when the date picker is opened (true) or closed (false)'
          }
        ]
      },
      rangeProps: {
        headers: ['Name', 'Type', 'Desc'],
        subtitle: 'Properties bound to this date range component will be passed through and bound to BOTH date pickers.',
        data: [
          {
            name: 'value',
            type: 'Array',
            desc: 'The from and to dates in ISO string format (yyyy-mm-dd)'
          },
          {
            name: 'rangeSeparatorText',
            type: 'String',
            desc: "The text separating the two fields. (Default 'to')"
          },
          {
            name: 'startDatePickerAttrs',
            type: 'Object',
            desc: 'Properties to bind into the start ADS date picker.'
          },
          {
            name: 'endDatePickerAttrs',
            type: 'Object',
            desc: 'Properties to bind into the end ADS date picker'
          },
          {
            name: 'vertical',
            type: 'Boolean',
            desc: 'Arranges date fields one above the other. (Default false)'
          }
        ]
      },
      rangeSlots: {
        headers: ['Name', 'Desc'],
        data: [
          {
            name: 'split',
            desc: 'The area between the two date pickers'
          }
        ]
      }
    };
  }
};
</script>
