<template>
  <div>
    <v-row>
      <v-col cols="9">
        <v-container>
          <h2 class="mt-6">
            Demo
          </h2>
          <v-container>
            <v-row>
              <v-col>
                <Stepper
                  aria-label="12345"
                  :steps="steps"
                  :current-step="currentStep"
                  :step-spacing="stepSpacing+'em'"
                  @select="select"
                />
              </v-col>
              <v-col>
                <v-card
                  outlined
                  style="padding: 1em"
                >
                  <h2>{{ currentStep }}. {{ steps[currentStep - 1].name }}</h2>
                  <v-radio-group
                    v-model="steps[currentStep - 1].status"
                    label="Step status:"
                  >
                    <v-radio
                      label="[none]"
                      value="none"
                    />
                    <v-radio
                      label="complete"
                      value="complete"
                    />
                    <v-radio
                      label="invalid"
                      value="invalid"
                    />
                    <v-radio
                      label="disabled"
                      value="disabled"
                    />
                  </v-radio-group>
                  <div style="text-align:right">
                    <v-btn
                      depressed
                      class="mr-2"
                      :disabled="currentStep===1"
                      @click="currentStep--"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      depressed
                      class="primary"
                      :disabled="currentStep===steps.length"
                      @click="currentStep++"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>

                <v-card
                  outlined
                  style="padding: 1em"
                >
                  <h2>Step spacing ({{ stepSpacing }}em)</h2>
                  <v-slider
                    v-model="stepSpacing"
                    class="align-center"
                    :max="3"
                    :min="0.3"
                    :step="0.1"
                    hide-details
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-container>
          <h2>
            Horizontal stepper
          </h2>
          <div>
            <HStepper
              v-model="currentHStep"
              :steps="hSteps"
              @changeStep="handleHStepChanged"
            />
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <v-card class="pa-6">
          <h2>{{ currentHStep }}. {{ hSteps[currentHStep - 1].name }}</h2>
          <v-radio-group
            v-model="hSteps[currentHStep - 1].status"
            label="Step status:"
          >
            <v-radio
              class="mt-2"
              label="[none]"
              value="none"
            />
            <v-radio
              label="complete"
              value="complete"
            />
            <v-radio
              label="invalid"
              value="invalid"
            />
            <v-radio
              label="disabled"
              value="disabled"
            />
          </v-radio-group>
          <div>
            <v-btn
              depressed
              class="mr-2"
              :disabled="currentHStep===1"
              @click="currentHStep--"
            >
              Back
            </v-btn>
            <v-btn
              depressed
              class="primary"
              :disabled="currentHStep===hSteps.length"
              @click="currentHStep++"
            >
              Next
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Stepper, HStepper } from '@nswdoe/doe-ui-core';

export default {
  name: 'Demo',
  components: {
    Stepper,
    HStepper
  },
  data() {
    return {
      steps: [
        { name: 'Order details', status: 'none' },
        { name: 'Delivery address', status: 'none' },
        { name: 'Payment method', status: 'none' },
        { name: 'Order complete', status: 'none' },
      ],
      currentStep: 1,
      stepSpacing: 1,
      currentHStep: 1,
      hSteps: [{
        name: 'Step 1'
      }, {
        name: 'Step 2',
        status: 'complete'
      }, {
        name: 'Step 3',
        status: 'invalid'
      }, {
        name: 'Step 4'
      }]
    };
  },
  methods: {
    select(stepNumber) {
      this.currentStep = stepNumber
    },
    handleHStepChanged(newVal) {
      this.currentHStep = newVal;
    }
  }
};
</script>
<style>
  .theme--light.v-label {
    color: black;
}
</style>