import api from '../../api';

// Vuex Essentials module
const moduleEssentials = {
  namespaced: true,
  state: {
    apps: []
  },
  getters: {
    myEssentialsApps: state => state.apps
  },
  mutations: {
    SET_MY_ESSENTIALS_APPS: function (state, apps) {
      state.apps = apps;
    }
  },
  actions: {
    fetchMyEssentialsApps: function ({commit}) {
      if (!this.getters.isLoading) {
        commit('SET_IS_LOADING', true, {root: true});
      }

      api.essentials.fetchApps(123)
        .then((response) => {
          return commit('SET_MY_ESSENTIALS_APPS', response);
        })
        .finally(() => {
          if (this.getters.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        });
    },
  }
};

export default moduleEssentials;
