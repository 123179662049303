<template>
  <v-row>
    <v-col cols="9">
      <v-container class="usage">
        <h2 class="my-6">
          Information categories
        </h2>
        <div>
          <h3>Success</h3>
          <p>Used to inform the user that an action has been successfully completed.</p>
          <h3 class="mt-4">
            Info
          </h3>
          <p>Used for information the user should know, but is not critical.</p>
          <h3 class="mt-4">
            Warning
          </h3>
          <p>Used to warn the user of a possible issue.</p>
          <h3 class="mt-4">
            Error
          </h3>
          <p class="mb-5">
            Used to inform the user of an error or convey information which is vital to the user.
          </p>
        </div>
        <h2 class="mb-5 mt-10">
          Alert types
        </h2>

        <h3>Snackbars</h3>
        <p>Appear over the page in the bottom left corner of the screen (desktop) or bottom centre (mobile).</p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/alerts/Snackbars_ADS.jpg">
          </v-col>
        </v-card>
        <p>Info, Warning and Error snackbars remain on screen until the user has dismissed them. They may also have an action button.</p>
        <p class="pt-2">
          Success snackbars disappear automatically and have no action button.
        </p>

        <h3 class="mt-10">
          In-page alerts
        </h3>
        <p>Displayed alongside the content</p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/alerts/In_page_alerts_ADS.png">
          </v-col>
        </v-card>
        <p>With a background colour</p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-1 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/alerts/In_page_alerts_ADS-bg.png">
          </v-col>
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> Alert <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">Alert</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">showAlert</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">type</span>="<span class="pl-s">info</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-alerts-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">allowDismiss</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-alerts-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">text</span>="<span class="pl-s">Content for the alert</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                  <td
id="file-alerts-html-LC7"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">subtext</span>="<span class="pl-s">This text is optional</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L8"
class="blob-num js-line-number"
data-line-number="8"
/>
                  <td
id="file-alerts-html-LC8"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">actionText</span>="<span class="pl-s">Optional Action text</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">actionHandler</span>="<span class="pl-s">actionHandlerFunction</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L10"
class="blob-num js-line-number"
data-line-number="10"
/>
                  <td
id="file-alerts-html-LC10"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">inPage</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">backgroundColour</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-alerts-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">Alert</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>type</td>
                <td>String</td>
                <td>Specify type of the alert.( info, success, error, warning)</td>
              </tr>
              <tr>
                <td>inPage</td>
                <td>Boolean</td>
                <td>In-page alert or snackbar.(default to false)</td>
              </tr>
              <tr>
                <td>border</td>
                <td>String</td>
                <td>Border position for the in-page alert. (default to left)</td>
              </tr>
              <tr>
                <td>position</td>
                <td>String</td>
                <td>Position of the snackbar alert.(default to Bottom-Right)</td>
              </tr>
               <tr>
                <td>backgroundColour</td>
                <td>Boolean</td>
                <td>Add to show the background colour on in-page alerts. (default false)</td>
              </tr>
              <tr>
                <td>icon</td>
                <td>String</td>
                <td>Specify icon for alert.</td>
              </tr>
              <tr>
                <td>allowDismiss</td>
                <td>Boolean</td>
                <td>Show/Hide dismiss button.(No dismiss button for success)</td>
              </tr>
              <tr>
                <td>showAlert</td>
                <td>Boolean</td>
                <td>Show/Hide the Alert.</td>
              </tr>
              <tr>
                <td>timeout</td>
                <td>Number</td>
                <td>Disappear alert after this time.<span class="orange--text"> (**default to 0 for no auto dismiss)</span></td>
              </tr>
              <tr>
                <td>htmlText</td>
                <td>String</td>
                <td>Display alert text with html styling.</td>
              </tr>
              <tr>
                <td>text</td>
                <td>String</td>
                <td>Display text for the alert.</td>
              </tr>
              <tr>
                <td>subtext</td>
                <td>String</td>
                <td>Add optional text to the alert.</td>
              </tr>
              <tr>
                <td>actionText</td>
                <td>String</td>
                <td>Optional text to show for action.</td>
              </tr>
              <tr>
                <td>alertClass</td>
                <td>String</td>
                <td>Optional class to insert into component.</td>
              </tr>
              <tr>
                <td>actionHandler</td>
                <td>Function</td>
                <td>Function to handle click on optional text.</td>
              </tr>
              <tr>
                <td>persistBetweenRoutes</td>
                <td>Boolean</td>
                <td>Show off page alerts even on context change.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Usage'
  }
</script>

<style lang="scss" scoped>
.usage {
  font-family: Arial;
  font-style: normal;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }
  p {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
  }
}
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
