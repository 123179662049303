<template>
  <v-row
    v-bind="{'justify': centered ? 'center' : 'start'}"
    class="banner ml-0 pb-11"
    :style="{
      backgroundImage: overrideBackgroundImage
        ? 'url(' + backgroundImageUrl + ')' /* image will be served from the 'public' folder */
        : overrideBackgroundColour ? 'none' : '',
      backgroundColor: overrideBackgroundColour ? backgroundColour : ''
    }"
  >
    <v-col class="contentSpacer">
      <slot name="content">
        <v-container :class="contentClass">
          <div class="d-flex align-start">
            <div class="pt-1">
              <slot name="icon">
                <v-icon
                  :size="iconSize"
                  class="material-icons-outlined"
                >
                  {{ icon }}
                </v-icon>
              </slot>
            </div>
            <div class="d-flex flex-column pt-1">
              <div>
                <span class="apptitle">{{ heading }}</span>
              </div>
              <slot name="subtitle">
                <div v-if="subtitle">
                  <span class="appSubtitle">{{ subtitle }}</span>
                </div>
              </slot>
            </div>
          </div>
        </v-container>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Banner',
    props: {
      icon: {
        type: String
      },
      iconSize: {
        type: String,
        default: '50'
      },
      heading: {
        type: String
      },
      subtitle: {
        type: String
      },
      tabs: {
        type: Array
      },
      backgroundImageUrl: {
        // image assets should be stored in the public folder
        type: String
      },
      backgroundColour: {
        type: String
      },
      centered: {
        type: Boolean,
        default: false
      },
      contentClass: {
        type: String,
        default: 'pl-6'
      }
    },
    data() {
      return {
        overrideBackgroundImage: this.backgroundImageUrl != null,
        overrideBackgroundColour: this.backgroundImageUrl == null && this.backgroundColour
      }
    }
  };
</script>

<style scoped>
  .banner {
    color: #FFFFFF;
  }
  .apptitle {
    font-size: 40px;
    font-weight: 600;
    display: inline-block;
    padding: 0;
    vertical-align: bottom;
  }
  .appSubtitle {
    font-size: 16px;
    padding: 0;
    margin-top:2px;
  }

  .banner {
    /* image will be served by webpack from this folder */
    background-image: url(./background2.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: right;
    background-color: #041E42;
  }

  .banner .col {
    padding-top: 28px;
    padding-bottom: 0px;
  }

  i.v-icon {
    color: #FFFFFF;
  }
</style>
