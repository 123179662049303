<template>
  <v-container>
    <v-row>
      <v-col
        cols="8"
      >
        <h2 class="my-6">
          Demo
        </h2>
        <p>
          The demo below shows two instances of the Parent ID Login component.
        </p>
        <p>
          The first one illustrates the use of the component without passing any properties or using slots. In other other words, that is the component's default content.
        </p>
        <p>
          The second one displays the component with custom values for all its properties.
        </p>
        <v-card
          outlined
          class="mt-6"
        >
          <parent-id-login />
        </v-card>
        <v-card
          outlined
          class="mt-6"
        >
          <parent-id-login
            title="Custom title"
            description="This content has been passed through the description property."
            checkboxDescription="This is a custom label for the checkbox. Check it to enable the action button below."
            buttonTextContent="Custom button content"
            :isActionPositionLeft="true"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ParentIdLogin } from '@nswdoe/doe-ui-core';

export default {
  name: 'ParentIdLoginDemo',
  components: {
    ParentIdLogin
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">

</style>