<template>
  <v-container>
    <v-row>
      <v-col
        md="10"
        class="pt-0"
      >
        <div>
          <h2>Public Sans font</h2>
          <p>ADS uses Public Sans font, as per the latest NSW Government brand guidelines. Pages will need to load the Public Sans font using the Google fonts API:</p>
          <p>API: <a href="https://developers.google.com/fonts/docs/developer_api" target="_blank">https://developers.google.com/fonts/docs/developer_api</a></p>
          <p>Website: <a href="https://fonts.google.com/specimen/Public+Sans" target="_blank">https://fonts.google.com/specimen/Public+Sans</a></p>
          <p>Please specify the following font stack:</p>
          <p>
            <code>{{fontFamily}}</code>
          </p>
          <p>As a good rule of thumb, you should still refer to a system font as a fallback in your CSS font stack as shown above, and this is because ADS framework is used for iOS and Android PWA as well.</p>
          <p>The standard stylesheet reference used by the NSW Government is:</p>
          <p>
            <code>{{rawHTML}}</code>
          </p>

        </div>
        <v-card>
          <div
            v-for="fontClass in Object.keys(fonts)"
            :key="fontClass"
            class="v-flex flex-column pa-6 pl-12 font-style-container"
          >
            <div :class="fontClass">
              .{{ fontClass }}
            </div>
            <div
              v-for="prop in Object.keys(fonts[fontClass])"
              :key="prop"
              class="body"
            >
              {{ `${prop}: ${[fonts[fontClass][prop]]};` }}
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="10"
        class="pt-0"
      >
        <div class="colour-palette-title-container mb-4">
          <h2>Text colours</h2>
        </div>
        <div>
          <ColourPalette
            :colours="colours"
            show-dark
            show-usage
            :show-hex-value="false"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ColourPalette from '../../components/ColourPalette';

const fontFamily = '"Public Sans", -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export default {
  name: 'Colours',
  components: {
    ColourPalette
  },
  data() {
    return {
      rawHTML: '<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap">',
      fontFamily,
      colours: [
        {name: 'Dark', nameDark: 'Light 10', colour: '#121212', dark: '#F4F4F7', usage: 'Primary'},
        {name: 'Dark 60', nameDark: 'Light 40', colour: '#6D7079', dark: '#A0A5AE', usage: 'Secondary'},
        {name: 'Blue 2', nameDark: 'Blue 2', colour: '#407EC9', dark: '#6BACE4', usage: 'Tertiary'},
      ],
      fonts: {
        'display-4': {
          'font-family': fontFamily,
          'font-size': '96px',
          'font-weight': 'regular',
          'line-height': '120px'
        },
        'display-3': {
          'font-family': fontFamily,
          'font-size': '60px',
          'font-weight': 'regular',
          'line-height': '75px'
        },
        'display-2': {
          'font-family': fontFamily,
          'font-size': '48px',
          'font-weight': 'regular',
          'line-height': '60px'
        },
        'display-1': {
          'font-family': fontFamily,
          'font-size': '34px',
          'font-weight': 'bold',
          'line-height': '40px'
        },
        'headline': {
          'font-family': fontFamily,
          'font-size': '24px',
          'font-weight': 'bold',
          'line-height': '30px'
        },
        'title': {
          'font-family': fontFamily,
          'font-size': '20px',
          'font-weight': 'bold',
          'line-height': '25px'
        },
        'subtitle-1': {
          'font-family': fontFamily,
          'font-size': '16px',
          'font-weight': 'bold',
          'line-height': '24px'
        },
        'body-1': {
          'font-family': fontFamily,
          'font-size': '16px',
          'font-weight': 'regular',
          'line-height': '24px'
        },
        'subtitle-2': {
          'font-family': fontFamily,
          'font-size': '14px',
          'font-weight': 'bold',
          'line-height': '17px'
        },
        'body-2': {
          'font-family': fontFamily,
          'font-size': '14px',
          'font-weight': 'regular',
          'line-height': '17px'
        },
        'caption': {
          'font-family': fontFamily,
          'font-size': '12px',
          'font-weight': 'regular',
          'line-height': '16px'
        }
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.font-style-container:not(:last-child) {
  border-bottom: 1px solid #A0A5AE;
}
</style>
