<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <FullscreenDialog
              title="Error page example"
              v-model="showErrorPage"
            >
              <template v-slot:content>
                <ErrorPage
                  :is404Page="is404Page"
                  :title="title"
                  :subtitle="subtitle"
                  :errorCode="errorCode"
                  :errorId="errorId"
                  :actions="JSON.parse(actions)"
                />
              </template>
            </FullscreenDialog>
            <v-row
              class="pa-5"
              justify="center"
            >
              <v-btn
                class="primary"
                @click="showErrorPage = true"
              >
                Show Error Page
              </v-btn>
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="is404Page"
                label="404 error page"
                @click="toggle()"
              />
            </v-row>
            <v-row>
              <v-col class="mx-12">
                <AdsTextField
                  v-model="title"
                  label="Title"
                />
              </v-col>
              <v-col class="mx-12">
                <AdsTextField
                  v-model="subtitle"
                  label="Subtitle"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-12">
                <AdsTextField
                  v-model="errorCode"
                  label="Error Code"
                  :disabled="is404Page"
                />
              </v-col>
              <v-col class="mx-12">
                <AdsTextField
                  v-model="errorId"
                  label="Error ID"
                  :disabled="is404Page"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-12">
                <AdsTextArea
                  v-model="actions"
                  label="Action buttons"
                  rows="5"
                  counter="1000"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { ErrorPage, FullscreenDialog, AdsTextField, AdsTextArea } from '@nswdoe/doe-ui-core';

 export default {
  name: 'Demo',
  components: {
    ErrorPage,
    FullscreenDialog,
    AdsTextField,
    AdsTextArea
  },
  data() {
    return {
      showErrorPage: false,
      is404Page: true,
      title: 'The page you are looking for does not exist.',
      subtitle: '',
      errorCode: '',
      errorId: '',
      actionsText1: '[ { "text": "technical support", "attrs": { "href": "https://enrolmentassist.freshdesk.com/support/tickets/new", "target": "_blank", "rel": "noopener" } } ]',
      actionsText2: '[ { "text": "Try again", "attrs": { "href": "https://enrolmentassist.freshdesk.com/support/tickets/new", "target": "_blank", "rel": "noopener" } }, { "text": "Contact support", "attrs": { "href": "https://enrolmentassist.freshdesk.com/support/tickets/new", "target": "_blank", "rel": "noopener" } } ]',
      actions: '[ { "text": "technical support", "attrs": { "href": "https://enrolmentassist.freshdesk.com/support/tickets/new", "target": "_blank", "rel": "noopener" } } ]',
    }
  },
  methods: {
    toggle() {
      this.title = this.is404Page ? 'The page you are looking for does not exist.' : 'An error has occurred and we are trying to fix the problem.';
      this.subtitle = this.is404Page ? '' : 'Sorry, we are unable to authenticate you right now.';
      this.errorCode = this.is404Page ? '' : 'PILIA-400';
      this.errorId = this.is404Page ? '' : 's723ru234t824tjk42yweg';
      this.actions = this.is404Page ? this.actionsText1 : this.actionsText2;
    },
  }
};
</script>

