<template>
  <v-row>
    <v-col cols="9">
      <v-container class="usage">
        <h2 class="mt-6">
          Inputs types
        </h2>
        <div class="mt-3">
          Types of date inputs:
        </div>
        <ul>
          <li>Manual input (type the date in dd/mm/yyyy)</li>
          <li>TextArea input (enter as multiple lines)</li>
          <li>Date picker (select dates from a calendar)</li>
          <li>
            Drop down list with ‘November 2019, December 2019’ as options.
          </li>
        </ul>

        <h3 class="mt-6 mb-3">
          Inputs (Form Fields and Detached)
        </h3>
        <v-row>
          <v-col>
            <v-img src="../../../assets/inputs/inputs.png" />
          </v-col>
        </v-row>
      </v-container>

      <v-card class="pl-6 mt-6">
        <v-row style="position: relative; left: -12px;">
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre
              style="color:#000000;background:#ffffff;"
            ><span style="color:#800000; font-weight:bold; ">import</span> <span
              style="color:#800080; "
>{</span> AdsTextField, AdsSelect, AdsFileInput <span style="color:#800080; ">}</span> from <span
              style="color:#800000; "
>"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody>
                <tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AdsTextField</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">v-model</span>=<span class="pl-s">"textFieldValue"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">AdsTextField</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>

                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AdsTextArea</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">v-model</span>=<span class="pl-s">"textAreaValue"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">AdsTextArea</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>

                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AdsSelect</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L5"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">v-model</span>=<span class="pl-s">"selectValue"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L6"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC6"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">AdsSelect</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>

                <tr>
                  <td
id="file-alerts-html-L7"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC7"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AdsFileInput</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L8"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC8"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">v-model</span>=<span class="pl-s">"inputValue"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">AdsFileInput</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>

                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-card-text>
          <p style="color: #121212;">
            Component properties are the same as the native Vuetify components:
          </p>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-text-field/"
              target="_blank"
            >Vuetify TextField Properties
            </a>
          </p>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-textarea/"
              target="_blank"
            >
              Vuetify TextArea Properties
            </a>
          </p>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-select/"
              target="_blank"
            >
              Vuetify Select Properties
            </a>
          </p>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-file-input/"
              target="_blank"
            >Vuetify FileInput Properties
            </a>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Usage'
};
</script>

<style lang="scss" scoped>
.usage {
  font-family: Arial;
  font-style: normal;

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }

  p {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
  }
}

.pl-ent {
  color: #22863a;
}

.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
