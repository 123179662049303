<template>
  <v-container>
    <div class="mt-12">
      <h2>Styling guidelines for light theme</h2>
      <ul class="pl-5 pb-4">
        <li>Corners are 4px</li>
        <li>Background is Greys/Light 10</li>
        <li>Cards are Greys/White</li>
        <li>Default text colour is Greys/Dark</li>
        <li>Icons are Light/Navy for regular emphasis or Greys/Dark 60 for low emphasis.</li>
      </ul>
    </div>
    <div style="background-color: #ffffff; padding: 40px 40px 36px 40px; max-width: 750px;">
      <img
        src="../../assets/guidelines/stylingGuidelinesLight.png"
        height="100%"
        width="100%"
      >
    </div>
    <div class="mt-12">
      <h2>Styling guidelines for dark theme</h2>
      <ul class="pl-5 pb-4">
        <li>Corners are 4px</li>
        <li>Background is Greys/Dark</li>
        <li>Tiles Dark Tint/01dp</li>
        <li>Default text colour is Greys/Light 20</li>
        <li>Icons are Dark/Navy for regular emphasis or Greys/Light 40 for low emphasis.</li>
      </ul>
    </div>
    <div style="background-color: #ffffff; padding: 40px 40px 25px 40px; max-width: 750px;">
      <img
        src="../../assets/guidelines/stylingGuidelinesDark.png"
        height="100%"
        width="100%"
      >
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'StylingGuidelines'
  }
</script>

<style lang="scss" scoped>
</style>
