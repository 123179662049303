import { render, staticRenderFns } from "./Usage.vue?vue&type=template&id=36153aa0&scoped=true&"
import script from "./Usage.vue?vue&type=script&lang=js&"
export * from "./Usage.vue?vue&type=script&lang=js&"
import style0 from "./Usage.vue?vue&type=style&index=0&id=36153aa0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36153aa0",
  null
  
)

export default component.exports