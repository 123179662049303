import Vue from 'vue';
import Vuex from 'vuex';
import modulePosts from './modules/posts';
import moduleEssentials from './modules/essentials';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    modulePosts,
    moduleEssentials,
  },
  state: {
    isLoading: false
  },
  getters: {
    isLoading: state => state.isLoading
  },
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    }
  },
  actions: {}
})
