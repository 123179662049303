/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import posts from './modules/posts';
import essentials from './modules/essentials';

export default {
  posts,
  essentials,
};
