<template>
  <v-container>
    <div>
      <h2>What is dark theme?</h2>
      <p>Dark theme (also know as dark mode or night mode) is a colour scheme that uses light coloured elements on top of dark backgrounds. It can help reduce eye strain.</p>
    </div>
    <div class="d-flex flex-wrap">
      <v-card
        class="py-6 px-1 pb-0"
        style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; height: 545px; overflow-y: hidden;"
      >
        <img
          src="@/assets/styles/dark/light.png"
          alt="light themed screen"
        >
      </v-card>
      <v-card
        class="py-6 px-1 pb-0"
        style="background-color: #333333; border-top-left-radius: 0px; border-bottom-left-radius: 0px; height: 545px; overflow-y: hidden;"
      >
        <img
          src="@/assets/styles/dark/dark.png"
          alt="dark themed screen"
        >
      </v-card>
    </div>

    <br><br>
    <div class="pt-1">
      <h2>Dark theme in ADS</h2>
      <p>Vuetify automatically generates the dark theme variant as per Material Design spec but we have customised the colours of icons and buttons to use those in DoE brand guidelines.</p>
      <!--      <p>All icons and buttons change from Navy to Blue 3. The primary button in dark theme is an exception as it used Blue 2 to meet accessibility contrast requirements.</p>-->
      <ul class="pl-4">
        <li>Background is Greys/Dark</li>
        <li>Tiles Dark Tint/01dp</li>
        <li>Default text colour is Greys/Light 20</li>
        <li>Icons are Dark/Navy for regular emphasis or Greys/Light 40 for low emphasis.</li>
      </ul>
    </div>
    <v-card dark>
      <v-row>
        <v-col
          class="pb-0"
          style="height: 30px; overflow-y: visible"
        >
          <p class="caption mb-0 mr-3 text-right">
            Background colour:
          </p>
          <p class="caption mb-0 mr-3 text-right">
            #121212
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-6">
          <v-expansion-panels accordion>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                My Quick Links
                <template v-slot:actions>
                  <v-icon color="#6BACE4">
                    mdi-plus
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Policy Library</v-expansion-panel-header>
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Brand Guidelines</v-expansion-panel-header>
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>SAP</v-expansion-panel-header>
              <v-expansion-panel-content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col>
          <div class="d-flex flex-column">
            <div class="pa-1">
              <v-btn
                x-large
                color="primary"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                Button
              </v-btn>
            </div>
            <div class="pa-1">
              <v-btn
                x-large
                outlined
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                Button
              </v-btn>
            </div>
            <div class="pa-1">
              <v-btn
                x-large
                text
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                Button
              </v-btn>
            </div>
            <div class="pa-1">
              <v-btn
                x-large
                disabled
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                Button
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <br><br>
    <h2>Resources</h2>
    <ResourceCard
      title="Vuetify Theme"
      href="https://vuetifyjs.com/en/customization/theme/#theme"
    >
      <img
        slot="image"
        src="@/assets/resources/vuetify.png"
        alt="vuetify logo"
      >
    </ResourceCard>

    <ResourceCard
      title="Material Design: Dark Theme"
      href="https://material.io/design/color/dark-theme.html"
    >
      <img
        slot="image"
        src="@/assets/resources/material-dark.png"
        alt="Material.io dark logo"
      >
    </ResourceCard>
  </v-container>
</template>

<script>
import ResourceCard from '@/components/ResourceCard';

export default {
  name: 'DarkTheme',
  components: {
    ResourceCard
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.v-expansion-panel-header >>> i.v-icon {
  color: #6BACE4 !important;
}
</style>
