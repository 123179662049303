
<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Style Options
        </h2>
        <p>There are 5 main elements that make up the page header. You can use any combination of these:</p>
        <ol
          class="mt-2"
          type="1"
        >
          <li>
            Background
          </li>
          <li>
            Page icon (should match the icon in the side navigation)
          </li>
          <li>
            Page title (should match the label in the side navigation)
          </li>
          <li>
            Subtitle (information about the page)
          </li>
          <li>
            Tooltip icon (when clicked on, it opens a dialog with information about the page)
          </li>
        </ol>
        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-5">
          <img src="../../../assets/banner/header1.png">
          <img src="../../../assets/banner/header2.png">
          <img src="../../../assets/banner/header3.png">
          <img src="../../../assets/banner/header4.png">
        </v-card>
        <h2 class="pt-9">
          Cards in page headers
        </h2>
        <p>You may also want to use page headers as part of a dashboard page. Here is an example of one from ICT PLUS which uses the background and cards to display information about a school. These cards are flexible in terms of layout and typography like regular Cards but you have the option to use the colour Navy 3 for the background.</p>
        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-5">
          <img src="../../../assets/banner/header5.png">
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> Banner <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-accountmenu-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-accountmenu-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-accountmenu-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">Banner</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-accountmenu-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">heading</span>="<span class="pl-s">Heading text for the header</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-accountmenu-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">subtitle</span>="<span class="pl-s">Subtitle for header</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-accountmenu-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">icon</span>="<span class="pl-s">security</span>"</td>
                </tr>
                  <tr>
                  <td
id="file-accountmenu-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-accountmenu-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">info</span>="<span class="pl-s">info</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-accountmenu-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">contentClass</span>="<span class="pl-s">pl-5</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-accountmenu-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">Banner</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-accountmenu-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>icon</td>
                <td>String</td>
                <td>Material icon name for header</td>
              </tr>
              <tr>
                <td>info</td>
                <td>String</td>
                <td>Material icon name for info</td>
              </tr>
              <tr>
                <td>infoSize</td>
                <td>String</td>
                <td>info icon size</td>
              </tr>
              <tr>
                <td>infoMessage</td>
                <td>String</td>
                <td>Tooltip message, it appear when hovered over <strong>'i'</strong> icon</td>
              </tr>
              <tr>
                <td>iconSize</td>
                <td>String</td>
                <td>Size of the header icon</td>
              </tr>
              <tr>
                <td>heading</td>
                <td>String</td>
                <td>Heading of the header</td>
              </tr>
              <tr>
                <td>subtitle</td>
                <td>String</td>
                <td>Subtitle of the header</td>
              </tr>
              <tr>
                <td>tabs</td>
                <td>Array</td>
                <td>Tabbed options for the header</td>
              </tr>
              <tr>
                <td>backgroundImageUrl</td>
                <td>String</td>
                <td>Custom Background image url <span class="warning">image assets should be stored in the public folder</span></td>
              </tr>
              <tr>
                <td>backgroundColour</td>
                <td>String</td>
                <td>Background color for header if no background image found</td>
              </tr>
              <tr>
                <td>centered</td>
                <td>Boolean</td>
                <td>Position content to center</td>
              </tr>
              <tr>
                <td>contentClass</td>
                <td>String</td>
                <td>Custom class for contents in header</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <SimpleTableCard
        title="Component slots"
        :headers="cSlots.headers"
        :data="cSlots.data"
      />
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cSlots: {
          headers: ['Name', 'Desc'],
          data: [
            {
              name: 'rightPannel',
              desc: 'A panel that floats to the right'
            }
          ]
      }
    }
  }
}
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.imgBackground img{
  width: 100%;
  height: 100%;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
