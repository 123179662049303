<template>
  <v-container fluid>
    <Banner
      heading="Styles"
      subtitle="Styling guidelines to ensure your app is on brand"
      content-class="pl-0"
    />
    <v-container fluid>
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-tabs>
            <v-container class="d-flex">
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
                :href="`#tab-${tab.key}`"
              >
                {{ tab.title }}
              </v-tab>
            </v-container>
            <v-tab-item
              value="tab-colours"
              class="content py-6 pr-6 pt-12 pb-12"
            >
              <Colours />
            </v-tab-item>
            <v-tab-item
              value="tab-fonts"
              class="content py-6 pr-6 pt-12 pb-12"
            >
              <Fonts />
            </v-tab-item>
            <v-tab-item
              value="tab-icons"
              class="content py-6 pr-6 pt-12 pb-12"
            >
              <Icons />
            </v-tab-item>
            <v-tab-item
              value="tab-dark"
              class="content py-6 pr-6 pt-12 pb-12"
            >
              <DarkTheme />
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '../../components/Banner';
import Colours from './Colours';
import Fonts from './Fonts';
import Icons from './Icons';
import DarkTheme from './DarkTheme';

export default {
  name: 'Styles',
  components: {
    Banner,
    Colours,
    Fonts,
    Icons,
    DarkTheme
  },
  data() {
    return {
      tabs: [
        {
          title: 'Colours',
          key: 'colours'
        },
        {
          title: 'Fonts',
          key: 'fonts'
        },
        {
          title: 'Icons',
          key: 'icons'
        },
        {
          title: 'Dark theme',
          key: 'dark'
        }
      ]
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
</style>