<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card>
            <v-row
              align="center"
              justify="center"
              class="pa-6"
            >
              <FullscreenDialog
                v-model="showDialog"
                title="Fullscreen Dialog Example"
              >
                <template v-slot:activator>
                  <AdsButton
                    button-text="Open Fullscreen Dialog"
                    @click="showDialog = true"
                  />
                </template>
                <template
                  v-if="showAction"
                  v-slot:action
                >
                  <AdsButton button-text="Button Action" />
                </template>
                <template v-slot:content />
              </FullscreenDialog>
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="showAction"
                label="Action Button"
              />
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
// import FullscreenDialog from "@/views/Components/FullscreenDialog/FullscreenDialog";
import {AdsButton, FullscreenDialog} from '@nswdoe/doe-ui-core';

export default {
  name: 'Demo',
  components: {
    FullscreenDialog,
    AdsButton
  },
  data() {
    return {
      showDialog: false,
      showAction: true,
    }
  },
};
</script>

