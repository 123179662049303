<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col>
            <v-row justify="end">
              <p>Last updated: 28 Feb 2020</p>
            </v-row>
          </v-col>
        </v-row>
        <h2 class="mt-6">
          Usage
        </h2>
        <v-row class="ml-0 mt-4 mb-6">
          <v-img :src="datatableImage" />
        </v-row>
        <ol type="1">
          <li>Expand details - shows or hides the row's expanded data</li>
          <li>External link - to brows an external web link in a new tab</li>
          <li>Order by - initiates order by column</li>
          <li>Icons: shows icon based on pre-defined conditions</li>
          <li>Replace filter: replaces a text in a column value</li>
          <li>Date Time: converts the date value to dd/MM/yyyy hh:mm:ss format</li>
          <li>Short Date : converts the date value to dd/MM/yyyy format</li>
          <li>Actions : shows pre-defined actions for each row</li>
          <li>Extra Filter : filters table rows by a pre-defined values from dropdown</li>
          <li>Search Criteria : filters table rows against an input text</li>
        </ol>
        <h2 class="mt-6">
          API
        </h2>
        <v-card class="mt-6">
          <!-- todo: componentise and format this parmas table better -->
          <v-card-title>Props</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Type
                  </th>
                  <th
                    class="text-left"
                    style="width:200px"
                  >
                    Default
                  </th>
                  <th class="text-left">
                    Desc
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>title</td>
                  <td>String</td>
                  <td />
                  <td>the Data Table title</td>
                </tr>
                <tr>
                  <td>headers</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>
                <tr>
                  <td>items</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>
                <tr>
                  <td>rowKey</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>
                <tr>
                  <td>search</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>
                <tr>
                  <td>loading</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>
                <tr>
                  <td>rowsPerPageItems</td>
                  <td>Array</td>
                  <td>[10, 20, 30, 50]</td>
                  <td>Set number of rows per page</td>
                </tr>
                <tr>
                  <td class="top-col">
                    formattedColumns
                  </td>
                  <td class="top-col">
                    Array
                  </td>
                  <td class="top-col">
                    []
                  </td>
                  <td>
                    <div>
                      <div>To change display format of a certain column.</div>Parameters include :
                      <h3>id</h3>
                      <p>represents the target column name to be formatted</p>
                      <h3>pipe:</h3>
                      <p>filter type to be used. Possible pipes include:</p>
                      <ol type="1">
                        <li>
                          <h4>FILED_TYPE_SHORT_DATE</h4>
                          <p>To format date column value to short date (dd/MM/yyyy).</p>
                          <h4>Example:</h4>
                          { id: "creation_time", pipe: FILED_TYPE_SHORT_DATE },
                          <h4>Result:</h4>01/01/2020
                        </li>
                        <li>
                          <h4>FILED_TYPE_DATE_TIME</h4>
                          <p>To format date column value to short date (dd/MM/yyyy hh:mm:ss AM/PM).</p>
                          <h4>Example:</h4>
                          { id: "creation_date", pipe: FILED_TYPE_DATE_TIME },
                          <h4>Result:</h4>01/01/202 9:10 AM
                        </li>
                        <li>
                          <h4>FILED_TYPE_EXTERNAL_LINK</h4>
                          <p>To display an external link. See example in Options section.</p>
                        </li>
                        <li>
                          <h4>FILED_TYPE_CONDITIONAL_ICON</h4>
                          <p>To conditionally display an icon based. See example in Options section.</p>
                        </li>
                        <li>
                          <h4>FILED_TYPE_STRING_REPLACE</h4>
                          <p>to replace portion of a column content text with a new value. See example in Options section</p>
                        </li>
                      </ol>
                      <h3>options</h3>
                      <p>provides more details to handle applying a format. This is an optional field. Possible options are:</p>
                      <ol type="1">
                        <li>
                          <h4>url: (string)</h4>
                          <p>Using with FILED_TYPE_EXTERNAL_LINK pipe to brows an external webpage in new tab.</p>
                          <h4>Example:</h4>
                          {
                          id: "id",
                          pipe: FILED_TYPE_EXTERNAL_LINK,
                          options:{
                          url: "https://doe-ads.surge.sh/colors"
                          }
                          },
                        </li>
                        <li>
                          <h4>replace: (string)</h4>
                          <p>Using with FILED_TYPE_STRING_REPLACE pipe. Represents the string to be replaced</p>
                          <h4>Example:</h4>
                          {
                          id: "email",
                          pipe: FILED_TYPE_STRING_REPLACE,
                          options:{
                          replace: "@det.nsw.edu.au",
                          replaceBy: ""
                          }
                          },
                          <p>Which replaces "@det.nsw.edu.au" in email address by an empty string for “email” column.</p>
                          <h4>Result:</h4>Column value: “Mahan.Tafreshi@det.nsw.edu.au”
                          Shows as value: “Mahan.Tafreshi”
                        </li>
                        <li>
                          <h4>replaceBy: (string)</h4>
                          <p>Using with FILED_TYPE_STRING_REPLACE pipe. Represents the string to be replaced</p>
                          <h4>Example:</h4>
                          {
                          id: "email",
                          pipe: FILED_TYPE_STRING_REPLACE,
                          options:{
                          replace: "@det.nsw.edu.au",
                          replaceBy: ""
                          }
                          },
                          <p>Which replaces "@det.nsw.edu.au" in email address by an empty string for “email” column.</p>
                          <h4>Result:</h4>Column value: “Mahan.Tafreshi@det.nsw.edu.au”
                          Shows as value: “Mahan.Tafreshi”
                        </li>
                        <li>
                          <h4>condition: (Object) { icon: string, color: string, tooltip: string }</h4>
                          <div>
                            Using with FILED_TYPE_CONDITIONAL_ICON pipe where
                            condition represents the column data value to select icon/tooltip details. Condition object has following properties:
                          </div>
                          <div class="ml-4">
                            <span class="font-weight-bold">a) icon:</span> material design icon name to show
                          </div>
                          <div class="ml-4">
                            <span class="font-weight-bold">b) color:</span> colour of the icon,
                          </div>
                          <div class="ml-4">
                            <span class="font-weight-bold">c) tooltip:</span> tooltip string to be show on hover the icon
                          </div>
                          <h4>Example:</h4>
                          <ul style="list-style: none;">
                            <li>{</li>
                            <li class="ml-4">
                              id: "status",
                            </li>
                            <li class="ml-4">
                              pipe: FILED_TYPE_CONDITIONAL_ICON,
                            </li>
                            <li class="ml-4">
                              options: {
                            </li>
                            <li
                              class="ml-6"
                            >
                              active: { icon: "check_circle", color: "green", tooltip: "Active" },
                            </li>
                            <li
                              class="ml-6"
                            >
                              inactive: { icon: "cancel", color: "red", tooltip: "Inactive" },
                            </li>
                            <li class="ml-6">
                              pending: {
                            </li>
                            <li class="ml-8">
                              icon: "hourglass_empty",
                            </li>
                            <li class="ml-8">
                              color: "deep-purple darken-4",
                            </li>
                            <li class="ml-8">
                              tooltip: "Pending"
                            </li>
                            <li class="ml-8">
                              }
                            </li>
                            <li class="ml-4">
                              }
                            </li>
                            <li>},</li>
                          </ul>
                        </li>
                      </ol>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top-col">
                    actions
                  </td>
                  <td class="top-col">
                    function
                  </td>
                  <td />
                  <td>
                    <div>Point to a handler in the parent component to handle the click event of a Action item in each row.</div>
                    <div>Each action will have following properties:</div>
                    <div class="ml-4">
                      <span class="font-weight-bold">a) title:</span> to show in the list of actions menu
                    </div>
                    <div class="ml-4">
                      <span class="font-weight-bold">b) icon:</span> if needs to show and icon for the action
                    </div>
                    <div class="ml-4">
                      <span class="font-weight-bold">c) handler:</span> will pass to handler which is defined in “emitEvent” props to handle the click event
                    </div>
                    <h4>Example:</h4>
                    <h5>Template</h5>
                    <ul style="list-style: none;">
                      <li>&lt; CTable</li>
                      <li class="ml-4">
                        :headers="headers"
                      </li>
                      <li class="ml-4">
                        :items="persons"
                      </li>
                      <li class="ml-4">
                        :title="tableHeader"
                      </li>
                      <li class="ml-4">
                        :rowKey="rowKey"
                      </li>
                      <li class="ml-4">
                        :actions="getActions"
                      </li>
                      <li class="ml-4">
                        @emitEvent="onTableEventHandler"
                      </li>
                      <li>/&gt;</li>
                    </ul>
                    <h5>Script</h5>
                    <ul style="list-style: none;">
                      <li>methods:{</li>
                      <li class="ml-2">
                        getActions(item) {
                      </li>
                      <li class="ml-4">
                        let actions = [];
                      </li>
                      <li class="ml-4">
                        if (item.isManager) {
                      </li>
                      <li
                        class="ml-6"
                      >
                        actions.push({ title: "Stop", icon: "stop", handler: "stopMonitoring" });
                      </li>
                      <li class="ml-5">
                        }
                      </li>
                      <li class="ml-4">
                        },
                      </li>
                      <li class="ml-2">
                        onTableEventHandler(eventOption) {
                      </li>
                      <li class="ml-4">
                        switch (eventOption.key) {
                      </li>
                      <li class="ml-6">
                        case " stopMonitoring ": // Stop Monitoring
                      </li>
                      <li class="ml-6">
                        // do something
                      </li>
                      <li class="ml-6">
                        break;
                      </li>
                      <li class="ml-6">
                        }
                      </li>
                      <li class="ml-4">
                        }
                      </li>
                      <li>},</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>showExpand</td>
                  <td>Boolean</td>
                  <td>False</td>
                  <td>Shows the expand icons to handle show/hide row details/child information</td>
                </tr>
                <tr>
                  <td>sortBy</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>
                <tr>
                  <td>sortDesc</td>
                  <td>Array</td>
                  <td>[]</td>
                  <td>Refer to Vuetify v-data-table headers props</td>
                </tr>                
                <tr>
                  <td class="top-col">
                    expandedItems
                  </td>
                  <td class="top-col">
                    Array
                  </td>
                  <td class="top-col">
                    []
                  </td>
                  <td>
                    <div>This is a key-value array of items to show when a expand icon of a row is clicked. </div>
                    <div>  The key-value object has following properties:</div>
                    <div class="ml-4">
                      <span class="font-weight-bold">a) title:</span> item label
                    </div>
                    <div class="ml-4">
                      <span class="font-weight-bold">b) value:</span> item value to show
                    </div>
                    <div>
                      it will use when “showExpand” is true. A “rowExpanded” will send to handler defined by “emitEvent” props.
                    </div>
                    <h4>Example:</h4>
                    <h5>Template</h5>
                    <ul style="list-style: none;">      
                      <li>&lt;CTable</li>
                      <li class="ml-4">
                        :headers="headers"
                      </li>
                      <li class="ml-4">
                        :items="persons"
                      </li>
                      <li class="ml-4">
                        :title="tableHeader"
                      </li>
                      <li class="ml-4">
                        :rowKey="rowKey"
                      </li>
                      <li class="ml-4">
                        :actions="getActions"
                      </li>
                      <li class="ml-4">
                        :expandedItems="expandedItems"
                      </li>
                      <li class="ml-4">
                        @emitEvent="onTableEventHandler"
                      </li>
                      <li class="ml-2">
                        /&gt;
                      </li>
                    </ul>
                    <h5>Script</h5>
                    <ul style="list-style: none;">    
                      <li class="ml-2">
                        methods:{
                      </li>
                      <li class="ml-4">
                        getActions(item) {
                      </li>
                      <li class="ml-6">
                        let actions = [];
                      </li>
                      <li class="ml-6">
                        if (item.isManager) {
                      </li>
                      <li class="ml-8">
                        actions.push({ title: "Stop", icon: "stop", handler: "stopMonitoring" });
                      </li>
                      <li class="ml-6">
                        }
                      </li>
                      <li class="ml-4">
                        },
                      </li>
                      <li class="ml-4">
                        onTableEventHandler(eventOption) {
                      </li>
                      <li class="ml-6">
                        switch (eventOption.key) {
                      </li>
                      <li class="ml-8">
                        case "rowExpanded":
                      </li>
                      <li class="ml-8">
                        this.expandedItems = Object.keys(eventOption.value).map(item => {
                      </li>
                      <li class="ml-8">
                        return { title: item, value: eventOption.value[item] };
                      </li>
                      <li class="ml-8">
                        });
                      </li>
                      <li class="ml-8">
                        break;
                      </li>
                      <li class="ml-6">
                        }
                      </li>
                      <li class="ml-4">
                        }
                      </li>
                      <li class="ml-2">
                        }
                      </li>
                    </ul>                    
                  </td>
                </tr>    
                <tr>
                  <td class="top-col">
                    dropdownColumns
                  </td>
                  <td class="top-col">
                    object
                  </td>
                  <td class="top-col">
                    null
                  </td>
                  <td>
                    <div>To add an extra filter in a selectable drop down format to the table. </div>
                    <div>properties include:</div>
                    <div class="ml-4">
                      <span class="font-weight-bold">a) fieldname:</span> column name to be filtered
                    </div>
                    <div class="ml-4">
                      <span class="font-weight-bold">b) title:</span> text to display
                    </div>
                    <div class="ml-4">
                      <span class="font-weight-bold">c) defaultValue:</span> default selected value
                    </div>
                    <div class="ml-4">
                      <span class="font-weight-bold">d) values:</span> possible values
                    </div>                                        
                    <h4>Example:</h4>
                    <h5>Template</h5>
                    <ul style="list-style: none;">      
                      <li>&lt;CTable</li>
                      <li class="ml-4">
                        :headers="headers"
                      </li>
                      <li class="ml-4">
                        :items="persons"
                      </li>
                      <li class="ml-4">
                        :title="tableHeader"
                      </li>
                      <li class="ml-4">
                        :rowKey="rowKey"
                      </li>
                      <li class="ml-4">
                        :dropdownColumns="dropdownColumns"
                      </li>
                      <li class="ml-2">
                        /&gt;
                      </li>
                    </ul>
                    <h5>Script</h5>
                    <ul style="list-style: none;">    
                      <li class="ml-2">
                        data() {
                      </li>
                      <li class="ml-4">
                        return {
                      </li>
                      <li class="ml-5">
                        …
                      </li>
                      <li class="ml-5">
                        …
                      </li>
                      <li class="ml-5">
                        dropdownColumns: {
                      </li>
                      <li class="ml-6">
                        fieldname: "status",
                      </li>
                      <li class="ml-6">
                        title: "Status",
                      </li>
                      <li class="ml-6">
                        defaultValue: "All",
                      </li>
                      <li class="ml-6">
                        values: ["All", "Active", "Inactive", "Pending"]
                      </li>
                      <li class="ml-5">
                        },
                      </li>
                      <li class="ml-4">
                        }
                      </li>
                      <li class="ml-2">
                        }
                      </li>
                    </ul>                    
                  </td>
                </tr>                                                
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Usage',
  data() {
    return {
      datatableImage: require('@/assets/datatable/datatable.png')
    };
  }
};
</script>
<style scoped>
.top-col{
    padding-top: 10px;
    vertical-align: top;
}
</style>