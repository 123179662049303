<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Status chip
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <Chip
                :chip-class="chipClass"
                :label="label"
                text="Chip Text"
                :outlined="outlined"
                :small="small"
                :close="close"
                :disabled="disabled"
                :filter="filter"
                :large="large"
                :avatar="avatar"
                :avatar-icon="avatarIcon"
              />
            </v-row>

            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="label"
                label="Label"
              />
              <v-switch
                v-model="outlined"
                label="Outlined"
              />
              <v-switch
                v-model="close"
                label="Close"
              />
              <v-switch
                v-model="disabled"
                label="Disabled"
              />
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="small"
                label="Small Size"
              />
              <v-switch
                v-model="large"
                label="Large Size"
              />
              <v-switch
                v-model="filter"
                label="Filter Chip"
              />
              <v-switch
                v-model="showAvatar"
                label="Avatar"
              />
            </v-row>
          </v-card>
        </v-container>
        <h2 class="mt-6">
          Filter chip
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <v-col
                cols="6"
                md="4"
              >
                <ChipFilter
                  v-model="selectedOperatingSystems"
                  name="Operating system"
                  :disabled="chipFilterDisabled"
                  :clear-link="clearLink"
                  :align-right="alignRight"
                  :multi-level="multiLevel"
                  :mandatory="mandatory"
                  :hide-arrows="hideArrows"
                  :selectable-parent="selectableParents"
                  :type="filterType"
                  :items="operatingSystems"
                />
                <div class="mt-3">
                  Filter value:
                  {{ selectedOperatingSystems && selectedOperatingSystems.length ? selectedOperatingSystems : 'none' }}
                </div>
              </v-col>
              <v-col>
                <h4>Type</h4>
                <v-radio-group
                  v-model="filterType"
                  @change="selectedOperatingSystems = null"
                >
                  <v-radio
                    label="Checkbox"
                    value="checkbox"
                  />
                  <v-radio
                    label="Radio"
                    value="radio"
                  />
                </v-radio-group>
              </v-col>
              <v-col>
                <v-switch
                  v-model="alignRight"
                  label="Align right"
                  hide-details
                />
                <v-switch
                  v-model="clearLink"
                  label="Clear link"
                  hide-details
                />
                <v-switch
                  v-model="chipFilterDisabled"
                  label="Disabled"
                  hide-details
                />
                <v-switch
                  v-if="filterType === 'checkbox'"
                  v-model="multiLevel"
                  label="Multi-level"
                  hide-details
                />
                <v-switch
                  v-if="filterType === 'checkbox'"
                  v-model="selectableParents"
                  label="Selectable parents"
                  hide-details
                />
                <v-switch
                  v-if="filterType === 'checkbox'"
                  v-model="hideArrows"
                  label="Hide arrows"
                  hide-details
                />
                <v-switch
                  v-if="filterType === 'radio'"
                  v-model="mandatory"
                  label="Mandatory"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="pa-5 d-flex justify-center">
              <v-col />
            </v-row>
          </v-card>
        </v-container>
        <h2 class="mt-6">
          Select filter chips
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <v-col>
                <v-combobox
                  v-model="select"
                  :items="items"
                  label="Select multiple"
                  multiple
                >
                  <template v-slot:selection="data">
                    <Chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :close="true"
                      :input-value="data.item"
                      @close="closeItem(data.item)"
                      @click:close="data.parent.selectItem(data.item)"
                    />
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {Chip, ChipFilter} from '@nswdoe/doe-ui-core';

export default {
  name: 'ChipsDemo',
  components: {
    Chip,
    ChipFilter
  },
  data() {
    return {
      label: false,
      outlined: false,
      small: false,
      close: false,
      disabled: false,
      large: false,
      closeIcon: 'cancel',
      filterIcon: '',
      filter: false,
      showOption: false,
      backgroundColor: false,
      avatar: '',
      showAvatar: false,
      inputValue: 'Microsoft Windows',
      chipClass: '',
      operatingSystems: [
        {
          id: 'MS',
          name: 'Microsoft Windows'
        },
        {
          id: 'LIN',
          name: 'Linux',
          children: [
            {
              id: 'UBU',
              name: 'Ubuntu'
            },
            {
              id: 'FED',
              name: 'Fedora'
            }
          ]
        },
        {
          id: 'MAC',
          name: 'Mac Os',
          children: [
            {
              id: 'CAT',
              name: 'Catalina'
            },
            {
              id: 'BS',
              name: 'Big Sur'
            },
            {
              id: 'MON',
              name: 'Monterey'
            }
          ]
        },
        {
          id: 'AND',
          name: 'Android'
        },
        {
          id: 'UNI',
          name: 'Unix'
        }
      ],
      filterType: 'checkbox',
      alignRight: false,
      clearLink: false,
      chipFilterDisabled: false,
      multiLevel: false,
      mandatory: false,
      hideArrows: false,
      selectableParents: true,
      selectedOperatingSystems: null,  // string or array
      select: ['Vuetify', 'Programming'],
      items: ['Programming', 'Design', 'Vue', 'Vuetify']
    };
  },
  computed: {
    avatarIcon() {
      if (this.showAvatar) {
        return 'mdi-account-circle';
      }
      return '';
    }
  },
  methods: {
    closeItem(item) {
      this.select = this.select.filter(value => value != item);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-list-item--active {
  background-color: transparent !important;
}
</style>
