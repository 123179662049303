<template>
  <v-row>
    <v-col cols="11">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-system-bar
            color="primary"
            dark
          >
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="showCode = !showCode"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </template>
              <span>View Source</span>
            </v-tooltip>
          </v-system-bar>
          <v-card
            v-if="showCode"
            outlined
          >
            <v-bottom-navigation
              v-model="activeBtn"
              color="blue"
            >
              <v-btn value="0">
                <span>Template</span>
              </v-btn>
              <v-btn value="1">
                <span>Script</span>
              </v-btn>
            </v-bottom-navigation>
          </v-card>
          <v-card v-if="activeBtn==0 && showCode">
            <code>
              <div v-html="htmlTemplate" />
            </code>
          </v-card>
          <v-card
            v-if="activeBtn==1 && showCode"
            outlined
          >
            <code>
              <div style="  height: 380px;overflow: scroll;">{{ jsScriptCodes }}</div>
            </code>
          </v-card>
          <v-card outlined>
            <DataTable
              :headers="headers"
              :items="demoInfo"
              :title="tableHeader"
              :row-key="rowKey"
              :rows-per-page-items="rowsPerPageItems"
              :show-expand="showExpand"
              :search="search"
              :sort-by="sortBy"
              :loading="loading"
              :formatted-columns="formattedColumns"
              :actions="getActions"
              :expanded-items="expandedItems"
              :dropdown-columns="dropdownColumns"
              @emitEvent="onTableEventHandler"
            />
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import {DataTable, DataTableFieldTypes} from '@nswdoe/doe-ui-core';

  const DOE_ADS_URL = 'https://doe-ads.surge.sh/';
  import jsScriptCode from './jsScriptCode.json'

  export default {
    name: 'Demo',
    components: {
      DataTable
    },
    data() {
      return {
        showCode: false,
        activeBtn: 0,
        headers: [
          {text: '#', value: 'id'},
          {text: 'Operating System', value: 'os'},
          {text: 'Status', value: 'status'},
          {text: 'State', value: 'state'},
          {text: 'Size', value: 'size'},
          {text: 'Built by', value: 'email'},
          {text: 'Build time', value: 'buildDate'},
          {text: 'Last Modified Date', value: 'modifiedDate'}
        ],
        tableHeader: 'Data Table',
        rowKey: 'id',
        showExpand: true,
        sortBy: ['os'],
        search: '',
        loading: false,
        rowsPerPageItems: [10, 20, 30, 50],
        formattedColumns: [
          {
            id: 'id',
            pipe: DataTableFieldTypes.FIELD_TYPE_EXTERNAL_LINK,
            options: {
              url: DOE_ADS_URL
            }
          },
          {
            id: 'email',
            pipe: DataTableFieldTypes.FIELD_TYPE_STRING_REPLACE,
            options: {
              replace: '@det.nsw.edu.au',
              replaceBy: ''
            }
          },
          {
            id: 'status',
            pipe: DataTableFieldTypes.FIELD_TYPE_CONDITIONAL_ICON,
            options: {
              active: {icon: 'check_circle', color: 'green', tooltip: 'Active'},
              inactive: {icon: 'cancel', color: 'red', tooltip: 'Inactive'},
              pending: {
                icon: 'hourglass_empty',
                color: 'deep-purple darken-4',
                tooltip: 'Pending'
              }
            }
          },
          {
            id: 'state',
            pipe: DataTableFieldTypes.FIELD_TYPE_CONDITIONAL_ICON,
            options: {
              running: {
                icon: 'brightness_1',
                color: 'green',
                tooltip: 'Running'
              },
              terminated: {
                icon: 'brightness_1',
                color: 'red',
                tooltip: 'Terminated'
              },
              stopped: {
                icon: 'stop',
                color: 'black',
                tooltip: 'Stopped'
              },
              empty: {
                icon: 'brightness_1',
                color: 'black',
                tooltip: 'Not Set'
              }
            }
          },
          {id: 'modifiedDate', pipe: DataTableFieldTypes.FIELD_TYPE_SHORT_DATE},
          {id: 'buildDate', pipe: DataTableFieldTypes.FIELD_TYPE_DATE_TIME}
        ],
        expandedItems: [],
        dropdownColumns: {
          fieldname: 'status',
          title: 'Status',
          defaultValue: 'All',
          values: ['All', 'Active', 'Inactive', 'Pending']
        },
        demoInfo: [
          {
            id: '123',
            os: 'Windows 2010',
            status: 'active',
            state: 'running',
            size: 'Tiny',
            email: 'Mahan.Tafreshi@det.nsw.edu.au',
            buildDate: '2019-06-20T11:16:59.122000+10:00',
            modifiedDate: '2019-06-20T11:16:59.122000+10:00'
          },
          {
            id: '213',
            os: 'Windows 2016',
            status: 'inactive',
            state: 'terminated',
            size: 'Tiny',
            email: 'Mahan.Tafreshi@det.nsw.edu.au',
            buildDate: '2012-12-11T04:52:00.983143Z',
            modifiedDate: '2012-12-11T04:52:00.983143Z'
          },
          {
            id: '518',
            os: 'Rethat',
            status: 'pending',
            state: 'stopped',
            size: 'Tiny',
            email: 'Mahan.Tafreshi@det.nsw.edu.au',
            buildDate: '2019-10-11T17:52:00.983143Z',
            modifiedDate: '2020-12-11T04:52:00.983143Z'
          }
        ]
      };
    },
    computed: {
      htmlTemplate() {
        let retval =
          '\n' +
          this.startTag('template') +
          this.tabSpace(1) +
          this.startTagWithAttrib('DataTable') +
          this.tabSpace(2) +
          this.attribute(':headers', 'headers') +
          this.tabSpace(2) +
          this.attribute(':items', 'vms') +
          this.tabSpace(2) +
          this.attribute(':title', 'tableHeader') +
          this.tabSpace(2) +
          this.attribute(':rowKey', 'rowKey') +
          this.tabSpace(2) +
          this.attribute(':showExpand', 'showExpand') +
          this.tabSpace(2) +
          this.attribute(':search', 'search') +
          this.tabSpace(2) +
          this.attribute(':sortBy', 'sortBy') +
          this.tabSpace(2) +
          this.attribute(':sortDesc', 'sortDesc') +
          this.tabSpace(2) +
          this.attribute(':loading', 'loading') +
          this.tabSpace(2) +
          this.attribute(':formattedColumns', 'formattedColumns') +
          this.tabSpace(2) +
          this.attribute(':expandedItems', 'expandedItems') +
          this.tabSpace(2) +
          this.attribute(':actions', 'getActions') +
          this.tabSpace(2) +
          this.attribute(':dropdownColumns', 'dropdownColumns') +
          this.tabSpace(2) +
          this.attribute('@emitEvent', 'onTableEventHandler') +
          this.tabSpace(1) +
          this.endTagWithAttributes +
          this.endTag('template');

        return retval;
      },
      jsScriptCodes() {
        return jsScriptCode.content.join('\n');
      },
      startTag() {
        return tag =>
          `<span class="blue--text text--darken-4">&lt;</span><span class="teal--text">${tag}</span><span class="blue--text text--darken-4">&gt;</span>\n`;
      },
      startTagWithAttrib() {
        return tag =>
          `<span class="blue--text text--darken-4">&lt;</span><span class="teal--text">${tag}</span>\n`;
      },
      endTag() {
        return tag =>
          `\n<span class="blue--text text--darken-4">&lt;/</span><span class="teal--text">${tag}</span><span class="blue--text text--darken-4">&gt;</span>`;
      },
      endTagWithAttributes() {
        return '</span><span class="green--text text--darken-4">/&gt;</span>';
      },
      attribute() {
        return (attr, value) =>
          `<span class="blue--text text--darken-4" >${attr}</span><span class="blue-grey--text text--darken-3">="${value}"</span>\n`;
      },
      tabSpace() {
        return count => {
          let space = '&nbsp&nbsp';
          for (var i = 0; i < count; i++) {
            space += space;
          }
          return `<space>${space}</space>`;
        };
      }
    },
    methods: {
      onTableEventHandler(eventOption) {
        switch (eventOption.key) {
          case 'searchText':
            //   set search filter
            break;
          case 'rowExpanded':
            // select items to show in expanded section
            this.expandedItems = Object.keys(eventOption.value).map(item => {
              return {title: item, value: eventOption.value[item]};
            });
            break;
          case 'Stop':
            // do stop action
            break;
          case 'Start':
          // do start action
        }
      },
      getActions(item) {
        let actions = [];
        if (item.state) {
          if (
            !(item.state.includes('terminated') || item.state.includes('pending'))
          ) {
            if (item.state && item.state === 'running') {
              actions.push({title: 'Stop', icon: 'stop', handler: ''});
            }

            if (item.state && item.state === 'stopped') {
              actions.push({title: 'Start', icon: 'play_arrow', handler: ''});
            }
          }
        }
        return actions;
      }
    }
  };
</script>

<style scoped>
  code {
    background-color: #eee;
    border-radius: 3px;
    font-family: courier, monospace;
    padding: 0 3px;
    width: 100%;
  }
</style>
