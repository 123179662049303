<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          404 error page
        </h2>
        <ul
          class="mt-2"
          type="1"
        >
          <li>
            The 404 error page will show a '404 error' title, optional subtitle (if not provided it will dsiplay 'The page you are looking for does not exist.'),
            and an optional contact support link.
          </li>
        </ul>
        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-8">
          <v-img
            src="@/assets/errorpages/error-404.png"
            class="pr-1 pb-1"
          />
        </v-card>
      </v-container>

      <v-card class="code-card pl-6 mt-6">
        <v-row style="position: relative; left: -12px;">
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- HTML generated using hilite.me --><div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%">import { ErrorPage } from &quot;@nswdoe/doe-ui-core&quot;;

<span style="font-weight: bold">&lt;ErrorPage</span>
  :is404page=<span style="font-style: italic">&quot;true&quot;</span>
  :actions=<span style="font-style: italic">&quot;[</span>
<span style="font-style: italic">    {</span>
<span style="font-style: italic">      text: &#39;technical support&#39;,</span>
<span style="font-style: italic">      attrs: {</span>
<span style="font-style: italic">        href: &#39;https://enrolmentassist.freshdesk.com/support/tickets/new&#39;,</span>
<span style="font-style: italic">        target: &#39;_blank&#39;,</span>
<span style="font-style: italic">        rel: &#39;noopener&#39;</span>
<span style="font-style: italic">      }</span>
<span style="font-style: italic">    }</span>
<span style="font-style: italic">  ]&quot;</span>
<span style="font-weight: bold">/&gt;</span>
</pre>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-container>
        <h2 class="mt-6">
          Generic error page
        </h2>
        <ul
          class="mt-2"
          type="1"
        >
          <li>
            The generic error page will show an optional title (if not provided it will display 'An error has occurred and we are trying to fix the problem.'),
            optional subtitle, optional error code, optional error id and optional action button(s).
          </li>
        </ul>
        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-8">
          <v-img
            src="@/assets/errorpages/error-generic.png"
            class="pr-1 pb-1"
          />
        </v-card>
      </v-container>

      <v-card class="code-card pl-6 mt-6">
        <v-row style="position: relative; left: -12px;">
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- HTML generated using hilite.me --><div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%">import { ErrorPage } from &quot;@nswdoe/doe-ui-core&quot;;

<span style="font-weight: bold">&lt;ErrorPage</span>
  subtitle=<span style="font-style: italic">&quot;Sorry, we are unable to authenticate you right now.&quot;</span>
  errorCode=<span style="font-style: italic">&quot;PILIA-400&quot;</span>
  errorId=<span style="font-style: italic">&quot;s723ru234t824tjk42yweg&quot;</span>
  :actions=<span style="font-style: italic">&quot;[</span>
<span style="font-style: italic">    {</span>
<span style="font-style: italic">      text: &#39;Try again&#39;,</span>
<span style="font-style: italic">      attrs: {</span>
<span style="font-style: italic">        href: &#39;https://enrolmentassist.freshdesk.com/support/tickets/new&#39;,</span>
<span style="font-style: italic">        target: &#39;_blank&#39;,</span>
<span style="font-style: italic">        rel: &#39;noopener&#39;</span>
<span style="font-style: italic">      }</span>
<span style="font-style: italic">    },</span>
<span style="font-style: italic">    {</span>
<span style="font-style: italic">      text: &#39;Contact support&#39;,</span>
<span style="font-style: italic">      attrs: {</span>
<span style="font-style: italic">        href: &#39;https://enrolmentassist.freshdesk.com/support/tickets/new&#39;,</span>
<span style="font-style: italic">        target: &#39;_blank&#39;,</span>
<span style="font-style: italic">        rel: &#39;noopener&#39;</span>
<span style="font-style: italic">      }</span>
<span style="font-style: italic">    }</span>
<span style="font-style: italic">  ]&quot;</span>
<span style="font-weight: bold">/&gt;</span>
</pre>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <SimpleTableCard
        title="Component properties"
        :headers="cProps.headers"
        :data="cProps.data"
      />
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'is404Page',
            type: 'Boolean',
            desc: '404 error page or Generic error page - default: false'
          },
          {
            name: 'title',
            type: 'String',
            desc: "Main title - default: 'An error has occurred and we are trying to fix the problem.'"
          },
          {
            name: 'subtitle',
            type: 'String',
            desc: 'Subtitle - optional'
          },
          {
            name: 'errorCode',
            type: 'String',
            desc: 'Error code - optional'
          },
          {
            name: 'errorId',
            type: 'String',
            desc: 'Error ID - optional'
          },
          {
            name: 'actions',
            type: 'Array',
            desc: 'Action buttons - see usage above for example attributes'
          },
        ]
      },
    }
  }
}
</script>

<style scoped>
</style>
