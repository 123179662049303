import api from '../../api';

// Vuex Posts module
const modulePosts = {
  namespaced: true,
  state: {
    posts: []
  },
  getters: {
    posts: state => state.posts
  },
  mutations: {
    SET_POSTS(state, posts) {
      state.posts = posts;
    }
  },
  actions: {
    fetchPosts({commit}) {
      if (!this.getters.isLoading) {
        commit('SET_IS_LOADING', true, {root: true});
      }

      // Examples to use the api module:
      api.posts.fetchPosts('/posts')
        .then(response => {
            if (response.data) {
              commit('SET_POSTS', response.data);
            }
          }
        )
        .catch(error => console.log(error)) // eslint-disable-line
        .finally(() => {
          if (this.getters.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        })
    }
  }
};

export default modulePosts;
