<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <ul>
          <li>
            Displayed at 40px x 40px by default.
          </li>
          <li>
            Latest icons viewable via <a href="https://unpkg.com/@nswdoe/app-icons/index.html">Unpkg</a>.
          </li>
        </ul>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/appicons/appicon.png"
            class="screenshot"
            alt="App icon"
          >
        </v-row>
        <h3 class="mt-6">
          Default usage in App Bar
        </h3>
        <ul>
          <li>
            Displayed at the top left before the app name.
          </li>
          <li>
            Is hidden on mobile to give space to more important app bar elements.
          </li>
        </ul>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/appicons/appbar.png"
            class="screenshot"
            alt="App bar usage"
          >
        </v-row>

        <v-card class="code-card px-6 py-3 mt-6 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p>
            Include the following html snippets into index.html to load the icons onto the page for use in the component (included in doe-ui-template by default):
          </p>
<!-- eslint-disable -->
<pre style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Calibri",sans-serif;line-height:13.5pt;'>
<span style="font-size:12px;font-family:Menlo;color:#333333;"></span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">head</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span>
<span style="font-size:12px;font-family:Menlo;color:#777777;">  </span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">script</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;"> </span><em><span style="font-size:12px;font-family:Menlo;color:#8190A0;">src</span></em><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">=</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">https://cdn.jsdelivr.net/npm/svg4everybody@2.1.9/dist/svg4everybody.min.js</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;&lt;/</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">script</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">  </span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">link</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;"> </span><em><span style="font-size:12px;font-family:Menlo;color:#8190A0;">rel</span></em><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">=</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">stylesheet</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;"> </span><em><span style="font-size:12px;font-family:Menlo;color:#8190A0;">href</span></em><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">=</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">https://cdn.jsdelivr.net/npm/@nswdoe/app-icons/essentialColours.css</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#777777;">  </span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">script</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;"> </span><em><span style="font-size:12px;font-family:Menlo;color:#8190A0;">src</span></em><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">=</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">https://cdn.jsdelivr.net/npm/@nswdoe/app-icons/essentialIconsMap.js</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;&lt;/</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">script</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;"></span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;/</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">head</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;"></span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">body</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">  </span><em><span style="font-size:12px;font-family:Menlo;color:#AAAAAA;">&lt;!-- load app icons onto the page --&gt;</span></em>
<span style="font-size:12px;font-family:Menlo;color:#777777;">  </span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">script</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">    </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">var</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">ajax</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">=</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">new</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><strong><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">XMLHttpRequest</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">()</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">    </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">ajax</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">onload</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">=</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">function</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">(</span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">e</span><span style="font-size:12px;font-family:Menlo;color:#777777;">)</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">{</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">      </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">var</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">spriteDiv</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">=</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">document</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">createElement</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">(</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">div</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#333333;">)</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">      </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">spriteDiv</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">setAttribute</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">(</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">id</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;,</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">spriteDiv</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#333333;">)</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">      </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">spriteDiv</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">setAttribute</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">(</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">style</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;,</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">height: 1px; position: fixed;</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#333333;">)</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">      </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">spriteDiv</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">innerHTML</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">=</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">ajax</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">responseText</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#777777;">      </span><em><span style="font-size:12px;font-family:Menlo;color:#AAAAAA;">// sprites must be injected at the end for IE11 &gt;:-(</span></em>
<span style="font-size:12px;font-family:Menlo;color:#333333;">      </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">document</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">body</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">appendChild</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">(</span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">spriteDiv</span><span style="font-size:12px;font-family:Menlo;color:#333333;">)</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;"> </span>
<span style="font-size:12px;font-family:Menlo;color:#777777;">      </span><em><span style="font-size:12px;font-family:Menlo;color:#AAAAAA;">// svg polyfill for IE11</span></em>
<span style="font-size:12px;font-family:Menlo;color:#333333;">      </span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">svg4everybody</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">()</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">    </span><span style="font-size:12px;font-family:Menlo;color:#777777;">};</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">    </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">ajax</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">open</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">(</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">GET</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;,</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;</span><span style="font-size:12px;font-family:Menlo;color:#448C27;">https://cdn.jsdelivr.net/npm/@nswdoe/app-icons/sprite.svg</span><span style="font-size:12px;font-family:Menlo;color:#777777;">&quot;,</span><span style="font-size:12px;font-family:Menlo;color:#333333;"> </span><span style="font-size:12px;font-family:Menlo;color:#9C5D27;">true</span><span style="font-size:12px;font-family:Menlo;color:#333333;">)</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">    </span><span style="font-size:12px;font-family:Menlo;color:#7A3E9D;">ajax</span><span style="font-size:12px;font-family:Menlo;color:#777777;">.</span><strong><span style="font-size:12px;font-family:Menlo;color:#AA3731;">send</span></strong><span style="font-size:12px;font-family:Menlo;color:#333333;">()</span><span style="font-size:12px;font-family:Menlo;color:#777777;">;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">    </span>
<span style="font-size:12px;font-family:Menlo;color:#333333;">  </span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;/</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">script</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
<span style="font-size:12px;font-family:Menlo;color:#333333;"></span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&lt;/</span><span style="font-size:12px;font-family:Menlo;color:#4B69C6;">body</span><span style="font-size:12px;font-family:Menlo;color:#91B3E0;">&gt;</span>
</pre>
          <!-- eslint-enable -->
          <p class="mt-6">
            Component code in Vue component &lt;template&gt;:
          </p>
<!-- eslint-disable -->
<div style="color: #333333; font-family: Menlo, Monaco, 'Courier New', monospace;font-weight: normal;font-size: 12px;line-height: 18px;white-space: pre;"><span style="color: #91b3e0;">&lt;</span><span style="color: #4b69c6;">AppIcon </span><span style="color: #8190a0;font-style: italic;">app-name</span><span style="color: #91b3e0;">=</span><span style="color: #777777;">&quot;</span><span style="color: #448c27;">my-nsw-gov</span><span style="color: #777777;">&quot; </span><span style="color: #91b3e0;">/&gt;</span></div>
          <!-- eslint-enable -->
          <br>
        </v-card>
        
        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'AppIconUsage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'appIcon',
            type: 'String',           
            desc: "Application Icon Name (default 'my-nsw-gov'). <a href='https://unpkg.com/@nswdoe/app-icons/index.html'>Icon reference</a>"
          }, {
            name: 'size',
            type: 'String',            
            desc: 'The icon size (default 40px)'
          }, {
            name: 'transparent',
            type: 'Boolean',
            desc: "Removes icon's coloured background."
          }]
        }
      }
    }
  }
</script>