<template>
  <v-container>
    <v-row>
      <v-col
        md="10"
        class="pt-0"
      >
        <div class="colour-palette-title-container mt-0">
          <h2>Primary colours (new updates Aug 2022)</h2>
          <p>These are the main colours you should be aiming to use in your app 70% of designs. ADS uses Navy for icons, buttons and form labels.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.primaryNew"
            show-dark
          />
        </div>
        <div class="colour-palette-title-container">
          <h2>Primary colours</h2>
          <p>These are the main colours you should be aiming to use in your app 70% of designs. ADS uses Navy for icons, buttons and form labels.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.primary"
            show-dark
          />
        </div>
        <div class="colour-palette-title-container">
          <h2>Secondary colours (new updates Aug 2022)</h2>
          <p>Use sparingly to support the primary palette. 30% of designs.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.secondaryNew"
            show-dark
          />
        </div>
        <div class="colour-palette-title-container">
          <h2>Secondary colours</h2>
          <p>Use sparingly to support the primary palette. 30% of designs.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.secondary"
            show-dark
          />
        </div>
        <div class="colour-palette-title-container">
          <h2>Notification colours</h2>
          <p>Provide contextual or transactional feedback. Used in snackbars.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.notification"
            show-dark
          />
        </div>
        <div class="colour-palette-title-container">
          <h2>Greys (new updates Aug 2022)</h2>
          <p>These can be used for backgrounds, outlines and dividers.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.greysNew"
            show-dark
          />
        </div>
        <div class="colour-palette-title-container">
          <h2>Greys</h2>
          <p>These can be used for backgrounds, outlines and dividers.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.greys"
            show-description
          />
        </div>

        <div class="colour-palette-title-container">
          <h2>Tints for Dark Theme</h2>
          <p>
            ADS has used material’s white overlay transpareny properties to create these tints for use in dark theme. Read more about it here:
            <a href="https://material.io/design/color/dark-theme.html#properties">
              https://material.io/design/color/dark-theme.html#properties
            </a>
          </p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.darkTints"
            background-colour="#121212"
            dark
          />
        </div>

        <div class="colour-palette-title-container">
          <h2>Tints for Navy Background</h2>
          <p>Tints for use on a Navy background that can be used for default, hover and focus states of cards.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.navyTints"
            background-colour="#041E42"
            dark
          />
        </div>

        <div class="colour-palette-title-container">
          <h2>Custom tints</h2>
          <p>Tints of Navy for use in specific components only.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.customTints"
            show-usage
          />
        </div>

        <div class="colour-palette-title-container">
          <h2>New colours</h2>
          <p>Additional colors for the NSW design system.</p>
        </div>
        <div>
          <ColourPalette
            :colours="colours.newColors"
            show-dark
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ColourPalette from '../../components/ColourPalette';
import {ADS_Colors} from '@nswdoe/doe-ui-core';

export default {
  name: 'Colours',
  components: {
    ColourPalette
  },
  data() {
    return {
      ADS_Colors,
      colours: {
        primary: [
          {name: 'Navy', scss: '$ads-navy', colour: ADS_Colors.Navy, dark: ADS_Colors.Navy_Dark},
          {name: 'Red', scss: '$ads-red', colour: ADS_Colors.Red, dark: ADS_Colors.Red_Dark},
          {name: 'Teal', scss: '$ads-light-blue', colour: ADS_Colors.Light_Blue, dark: ADS_Colors.Light_Blue_Dark}
        ],
        primaryNew: [
          {name: 'Primary Blue', scss: '$ads-primary-blue', colour: ADS_Colors.PRIMARY_BLUE, dark: ADS_Colors.PRIMARY_BLUE_DARK},
          {name: 'Primary Red', scss: '$ads-primary-red', colour: ADS_Colors.PRIMARY_RED, dark: ADS_Colors.PRIMARY_RED_DARK},
          {name: 'Primary Teal', scss: '$ads-primary-teal', colour: ADS_Colors.PRIMARY_TEAL, dark: ADS_Colors.PRIMARY_TEAL_DARK}
        ],
        secondary: [
          {name: 'Blue 1', scss: '$ads-blue-1', colour: ADS_Colors.Blue_1, dark: ADS_Colors.Blue_1_Dark},
          {name: 'Secondary Blue', scss: '$ads-blue-2', colour: ADS_Colors.Blue_2, dark: ADS_Colors.Blue_2_Dark},
          {name: 'Tertiary Blue', scss: '$ads-blue-3', colour: ADS_Colors.Blue_3, dark: ADS_Colors.Blue_3_Dark},
          {name: 'Secondary Red', scss: '$ads-red-2', colour: ADS_Colors.Red_2, dark: ADS_Colors.Red_2_Dark},
          {name: 'Red 3', scss: '$ads-red-3', colour: ADS_Colors.Red_3, dark: ADS_Colors.Red_3_Dark}
        ],
        secondaryNew: [
          {name: 'Secondary Blue', scss: '$ads-secondary-blue', colour: ADS_Colors.SECONDARY_BLUE, dark: ADS_Colors.SECONDARY_BLUE_DARK},
          {name: 'Tertiary Blue', scss: '$ads-tertiary-blue', colour: ADS_Colors.TERTIARY_BLUE, dark: ADS_Colors.TERTIARY_BLUE_DARK},
          {name: 'Secondary Red', scss: '$ads-secondary-red', colour: ADS_Colors.SECONDARY_RED, dark: ADS_Colors.SECONDARY_RED_DARK},
          {name: 'Secondary Grey', scss: '$ads-secondary-grey', colour: ADS_Colors.SECONDARY_GREY, dark: ADS_Colors.SECONDARY_GREY_DARK}
        ],
        notification: [
          {name: 'Info Blue', scss: '$ads-info-blue', colour: ADS_Colors.Info_Blue, dark: ADS_Colors.Info_Blue_Dark},
          {name: 'Success', scss: '$ads-success-green', colour: ADS_Colors.Success_Green, dark: ADS_Colors.Success_Green_Dark},
          {name: 'Warning', scss: '$ads-warning-orange', colour: ADS_Colors.Warning_Orange, dark: ADS_Colors.Warning_Orange_Dark},
          {name: 'Error', scss: '$ads-error-red', colour: ADS_Colors.Error_Red, dark: ADS_Colors.Error_Red_Dark}
        ],
        greysNew: [
          {name: 'Grey 1', scss: '$ads-grey-01', colour: ADS_Colors.GREY_01, dark: ADS_Colors.GREY_01_DARK},
          {name: 'Grey 2', scss: '$ads-grey-02', colour: ADS_Colors.GREY_02, dark: ADS_Colors.GREY_02_DARK},
          {name: 'Grey 3', scss: '$ads-grey-03', colour: ADS_Colors.GREY_03, dark: ADS_Colors.GREY_03_DARK},
          {name: 'Grey 4', scss: '$ads-grey-04', colour: ADS_Colors.GREY_03, dark: ADS_Colors.GREY_04_DARK}
        ],
        greys: [
          {
            name: 'Dark',
            colour: ADS_Colors.Dark,
            useInLightTheme: 'Primary text colour',
            useInDarkTheme: 'Background colour',
            scss: '$ads-dark'
          },
          {
            name: 'Dark 80',
            colour: ADS_Colors.Dark_80,
            useInLightTheme: '',
            useInDarkTheme: '',
            scss: '$ads-dark-80'
          },
          {
            name: 'Dark 70',
            colour: ADS_Colors.Dark_70,
            useInLightTheme: '',
            useInDarkTheme: '',
            scss: '$ads-dark-70'
          },
          {
            name: 'Dark 60',
            colour: ADS_Colors.Dark_60,
            useInLightTheme: 'Low emphasis icons',
            useInDarkTheme: 'Dividers',
            scss: '$ads-dark-60'
          },
          {
            name: 'light 40',
            colour: ADS_Colors.Light_40,
            useInLightTheme: 'Dividers',
            useInDarkTheme: 'Low emphasis icons',
            scss: '$ads-light-40'
          },
          {
            name: 'Light 20',
            colour: ADS_Colors.Light_20,
            useInLightTheme: '',
            useInDarkTheme: '',
            scss: '$ads-light-20'
          },
          {
            name: 'Light 10',
            colour: ADS_Colors.Light_10,
            useInLightTheme: 'Background colour',
            useInDarkTheme: 'Primary text colour',
            scss: '$ads-light-10'
          },
          {
            name: 'White',
            colour: ADS_Colors.White,
            useInLightTheme: '',
            useInDarkTheme: '',
            scss: '$ads-white'
          }
        ],
        darkTints: [
          {name: '01dp', colour: ADS_Colors.DP01, scss: 'ads-01dp'},
          {name: '02dp', colour: ADS_Colors.DP02, scss: 'ads-02dp'},
          {name: '03dp', colour: ADS_Colors.DP03, scss: 'ads-03dp'},
          {name: '04dp', colour: ADS_Colors.DP04, scss: 'ads-04dp'},
          {name: '06dp', colour: ADS_Colors.DP06, scss: 'ads-06dp'},
          {name: '08dp', colour: ADS_Colors.DP08, scss: 'ads-08dp'},
          {name: '12dp', colour: ADS_Colors.DP12, scss: 'ads-12dp'},
          {name: '16dp', colour: ADS_Colors.DP16, scss: 'ads-16dp'},
          {name: '24dp', colour: ADS_Colors.DP24, scss: 'ads-24dp'}
        ],
        navyTints: [
          {name: 'Navy Light 1', colour: ADS_Colors.Navy_Light_1, scss: 'navy-light-1'},
          {name: 'Navy Light 2', colour: ADS_Colors.Navy_Light_2, scss: 'navy-light-2'},
          {name: 'Navy Light 3', colour: ADS_Colors.Navy_Light_2, scss: 'navy-light-3'},
          {name: 'Navy Dark 1', colour: ADS_Colors.Navy_Dark_1, scss: 'navy-dark-1'}
        ],
        customTints: [
          {
            name: 'Navy 2',
            colour: ADS_Colors.Custom_Navy_2,
            usage:
              'Background of selected state in the <a href="#">Navigation Drawer</a>',
            scss: 'ads-custom-navy-2'
          },
          {
            name: 'Navy 3',
            colour: ADS_Colors.Custom_Navy_3,
            usage: 'Cards in <a href="/components/pageheader">Page Header</a>',
            scss: 'ads-custom-navy-3'
          }
        ],
        newColors: [
          {name: 'Fuschia 01', scss: '$ads-fuschia-1', colour: ADS_Colors.Fuschia_1, dark: ADS_Colors.Fuschia_1_Dark},
          {name: 'Purple 01', scss: '$ads-purple-1', colour: ADS_Colors.Purple_1, dark: ADS_Colors.Purple_1_Dark},
          {name: 'Teal 01', scss: '$ads-teal-1', colour: ADS_Colors.Teal_1, dark: ADS_Colors.Teal_1_dark}
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.colour-palette-title-container {
  margin-top: 40px;

  p {
    margin-bottom: 15px;
  }
}
</style>
