<template>
  <v-row>
    <v-col cols="9">
      <v-container class="usage">
        <h2 class="my-6">
          About
        </h2>
        <p>
          This component was built using the open-source charting library
          <a
            href="https://apexcharts.com/docs/installation/"
            target="_blank"
          >Apexcharts</a>
          and Vuetify. Apexcharts is integrated into
          <a
            href="https://bitbucket.det.nsw.edu.au/projects/UI/repos/doe-ui-core/browse"
            target="_blank"
          >doe-ui-core</a>
          using the
          <a
            target="_blank"
            href="https://apexcharts.com/docs/vue-charts/"
          >Vue-ApexCharts</a>
          wrapper. Year 6-7 transition in OES have incorperated the
          <a
            href="https://apexcharts.com/vue-chart-demos/bar-charts/stacked/"
            target="_blank"
          >stacked bar chart</a>
          and the
          <a
            href="https://apexcharts.com/vue-chart-demos/column-charts/stacked/"
            target="_blank"
          >stacked column graph</a>
          into their project to display summary reporting. Examples are shown
          below and in the demo tab.
        </p>

        <p>
          Vue specific demos of Apexcharts can be found
          <a herf="https://apexcharts.com/vue-chart-demos/">here.</a>

          For further information on what is possible take a look at the
          <a
            target="_blank"
            href="https://apexcharts.com/docs/options/chart/type/"
          >Options (Reference) Apexcharts documentation.</a>
          You can pass your own options object into your chart and overwrite any
          of the options available on this component.
        </p>
        <h3 class="my-6">
          Chart Types
        </h3>
        <div>
          <v-card>
            <v-col class="mt-4 pa-9 d-flex justify-center">
              <img
                width="100%"
                height="100%"
                src="../../../assets/charts/stacked-bar.png"
              >
            </v-col>
          </v-card>

          <v-card>
            <v-col class="mt-4 pa-9 d-flex justify-center">
              <img
                width="100%"
                height="100%"
                src="../../../assets/charts/stacked-column.png"
              >
            </v-col>
          </v-card>
        </div>

        <v-card class="pl-6 mt-6">
          <v-row style="position: relative; left: -12px">
            <v-card-title class="pb-0">
              Usage
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <!-- todo: link to codepen -->
              <!-- todo: figure out an efficient way to generate formatted code in html -->
              <pre
                style="color: #000000; background: #ffffff"
              ><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> AdsChart <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
              <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AdsChart</span></td>
                </tr>
               
                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">title</span>="<span class="pl-s">AdsChart</span>"</td>
                </tr>
                
                
                <tr>
                  <td
id="file-alerts-html-L8"
class="blob-num js-line-number"
data-line-number="8"
/>
                  <td
id="file-alerts-html-LC8"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">type</span>="<span class="pl-s">Bar</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">width</span>="<span class="pl-s">100%</span>"</td>
                </tr>
                 <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">height</span>="<span class="pl-s">400</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-alerts-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:series</span>="<span class="pl-s">series</span>"</td>
                </tr>
                
                   <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:chartOptions</span>="<span class="pl-s">chartOptions</span>"</td>
                </tr>
                 <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:adsForcedOptions</span>="<span class="pl-s">false</span>"</td>
                </tr>
               
                <tr>
                  <td
id="file-alerts-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-alerts-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">/&gt;</span></td>
                </tr>
               
                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-6">
          <!-- todo: componentise and format this parmas table better -->
          <v-card-title> Component Properties </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Type
                  </th>
                  <th class="text-left">
                    Desc
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>type</td>
                  <td>String</td>
                  <td>Specify the chart type (default: Bar).</td>
                </tr>
                <tr>
                  <td>title</td>
                  <td>String</td>
                  <td>Chart title to appear on top of the v-card.</td>
                </tr>
                <tr>
                  <td>width</td>
                  <td>Number || String</td>
                  <td>Width of the chart (default: 100%)</td>
                </tr>
                <tr>
                  <td>height</td>
                  <td>Number || String</td>
                  <td>Height of the chart (default: 400px)</td>
                </tr>
                <tr>
                  <td>series</td>
                  <td>Array</td>
                  <td>
                    Accepts an array of [name, data] object for axis charts or
                    an array of values for non-axis. For more info check out
                    this:
                    <a href="https://apexcharts.com/docs/series/">
                      https://apexcharts.com/docs/series/</a>
                  </td>
                </tr>
                <tr>
                  <td>showTitle</td>
                  <td>Boolean</td>
                  <td>
                    Display title of the chart at the top of the card (default:
                    true)
                  </td>
                </tr>
                <tr>
                  <td>adsForcedOptions</td>
                  <td>Boolean</td>
                  <td>
                    If set to true you will use the existing ADS computedOptions
                    object - by setting to false you will overide all properties
                    via your own 'chartOptions' object. You can keep as true and
                    build onto the ADS computedOptions object. (default: true)
                  </td>
                </tr>
                <tr>
                  <td>chartOptions</td>
                  <td>Object</td>
                  <td>
                    Accepts an object of all possible customisations of
                    apexcharts:
                    <a href="https://apexcharts.com/docs/options/chart/type/">
                      https://apexcharts.com/docs/options/chart/type/</a>
                  </td>
                </tr>
                <tr>
                  <td>stacked</td>
                  <td>Boolean</td>
                  <td>
                    Enables stacked option for axis charts (default: true).
                  </td>
                </tr>
                <tr>
                  <td>horizontalBar</td>
                  <td>Boolean</td>
                  <td>
                    This option will turn a column chart into a horiontal bar
                    chart (default: true).
                  </td>
                </tr>
                <tr>
                  <td>reversedY</td>
                  <td>Boolean</td>
                  <td>
                    Flip the chart upside down making it inversed and draw the
                    y-axis from bigger to smaller numbers (default: true).
                  </td>
                </tr>
                <tr>
                  <td>segColours</td>
                  <td>Array</td>
                  <td>
                    Colors for the chart's series. When all colors of the array
                    are used, it starts from the beginning (default: [
                    '#555555', '#E4E4E6', '#F3B8B6','#407EC9', '#041E42',
                    '#00A908']).
                  </td>
                </tr>
                <tr>
                  <td>segLabels</td>
                  <td>Boolean</td>
                  <td>
                    To determine whether to show dataLabels or not (default:
                    true).
                  </td>
                </tr>
                <tr>
                  <td>segLabelColours</td>
                  <td>Array</td>
                  <td>
                    ForeColors for the dataLabels. Accepts an array of string
                    colors (default: [ '#fff'])
                  </td>
                </tr>
                <tr>
                  <td>xlabelShow</td>
                  <td>Boolean</td>
                  <td>Show labels on x-axis.</td>
                </tr>
                <tr>
                  <td>xLabelCat</td>
                  <td>Array</td>
                  <td>
                    Categories are labels which are displayed on the x-axis.
                  </td>
                </tr>
                <tr>
                  <td>yaxisLabel</td>
                  <td>Boolean</td>
                  <td>
                    Draw a vertical border on the y-axis (default: false).
                  </td>
                </tr>
                <tr>
                  <td>yaxisBorder</td>
                  <td>Boolean</td>
                  <td>Show labels on y-axis (default: false).</td>
                </tr>
                <tr>
                  <td>yaxisTicks</td>
                  <td>Boolean</td>
                  <td>
                    Draw ticks on the y-axis to specify intervals (default:
                    false).
                  </td>
                </tr>
                <tr>
                  <td>grid</td>
                  <td>Boolean</td>
                  <td>
                    To show or hide grid area (including xaxis / yaxis)
                    (default: false).
                  </td>
                </tr>
                <tr>
                  <td>axisBorder</td>
                  <td>Boolean</td>
                  <td>
                    Draw a horizontal border on the x-axis (default: false).
                  </td>
                </tr>
                <tr>
                  <td>axisTicks</td>
                  <td>Boolean</td>
                  <td>
                    Draw ticks on the x-axis to specify intervals (default:
                    false).
                  </td>
                </tr>
                <tr>
                  <td>targetText</td>
                  <td>String</td>
                  <td>Text for tha annotation label (default: null).</td>
                </tr>
                <tr>
                  <td>targetPosition</td>
                  <td>Number</td>
                  <td>
                    Value on which the annotation will be drawn (default:
                    undefined).
                  </td>
                </tr>
                <tr>
                  <td>labelOffsetX</td>
                  <td>Number</td>
                  <td>
                    Sets the left offset for annotation label (default: 5).
                  </td>
                </tr>
                <tr>
                  <td>targetOffsetX</td>
                  <td>Number</td>
                  <td>
                    Sets the left offset for annotation line (default: null).
                  </td>
                </tr>
                <tr>
                  <td>legendPosition</td>
                  <td>String</td>
                  <td>Position the legend (default: left).</td>
                </tr>
                <tr>
                  <td>inverseLegend</td>
                  <td>Boolean</td>
                  <td>
                    Inverse the placement ordering of the legend items (default:
                    false).
                  </td>
                </tr>
                <tr>
                  <td>breakpoint</td>
                  <td>Number</td>
                  <td>
                    The breakpoint is the max screen width at which the original
                    config object will be overrided by the responsive config
                    object (default: 740).
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Usage'
};
</script>

<style lang="scss" scoped>
.usage {
  font-family: Arial;
  font-style: normal;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }
  p {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
  }
}
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
