<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card>
          <v-row
            align="center"
            justify="center"
            class="py-6"
          >
            <v-col
              cols="9"
            >
              <AdsFormatContactField
                label="Telephone number"
                v-model="value"
                hide-details="true"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { AdsFormatContactField } from '@nswdoe/doe-ui-core';

export default {
    name: 'Demo',
    components: {
    AdsFormatContactField,
  },
  data() {
    return{
      value: ''
    }
  },
}

</script>

<style>

</style>