<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Session Countdown
        </h2>
        <ul>
          <li>
            The Session Countdown component can automatically log users out of an application
          </li>
          <li>
            The Session Countdown component can be configured to show an alert (in page) when your session is about to expire
          </li>
          <li>
            Or just show the alert (overlay) when your session has already expired (see prop <code>minutesWarning</code>)
          </li>
        </ul>
        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-8">
          <v-img
            src="@/assets/sessioncountdown/image.png"
            class="pr-1 pb-1"
          />
        </v-card>
      </v-container>

      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <p>In the main.js, setup the <code>ActivityMonitor</code> plugin like this.</p>
            <!-- HTML generated using hilite.me -->
            <div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%">import {ActivityMonitor} from &#39;@nswdoe/doe-ui-core&#39;

// Trigger inactivity callback &#39;warning minutes&#39; before &#39;timeout minutes&#39;.
// e.g. 5 minutes (warning duration) before the 60 minutes (timeout duration)
// of inactivity (i.e. after 55 minutes of inactivity).
Vue.use(ActivityMonitor, {
  events: [&#39;keydown&#39;, &#39;mousedown&#39;],
  inactivityTimeout:
    (process.env.VUE_APP_ACTIVITY_TIMEOUT_MINUTES -
      process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES) *
    60000,
  throttlerTimeout: 1000,
  onInactiveUser: () =&gt; {
    store.dispatch(&#39;beginFinalInactivityTimer&#39;)
  }
})
</pre>
            </div>

            <br>
            <p>Add a method <code>beginFinalInactivityTimer</code> in the Vuex action (eg. actions.js):</p>
            <!-- HTML generated using hilite.me -->
            <div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%">beginFinalInactivityTimer({ commit }) {
  this._vm.$activityMonitor.deactivate()
  commit(&#39;set&#39;, [&#39;showTimeoutBanner&#39;, true])
}
</pre>
            </div>

            <br>
            <p>Add the following code in <code>App.vue</code></p>
            <!-- HTML generated using hilite.me -->
            <div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%"><span style="color: #008000; font-weight: bold">&lt;SessionCountdown</span>
  <span style="color: #7D9029">:showCountdown=</span><span style="color: #BA2121">&quot;showCountdown&quot;</span>
  <span style="color: #7D9029">:minutesWarning=</span><span style="color: #BA2121">&quot;minutesTimeoutWarning&quot;</span>
  <span style="color: #7D9029">sessionTimeoutDialogCssClass=</span><span style="color: #BA2121">&quot;session-timeout&quot;</span>
  <span style="color: #7D9029">sessionCountdownAlertCssClass=</span><span style="color: #BA2121">&quot;session-countdown&quot;</span>
  <span style="border: 1px solid #FF0000">@</span><span style="color: #7D9029">continue=</span><span style="color: #BA2121">&quot;continueSession&quot;</span>
  <span style="border: 1px solid #FF0000">@</span><span style="color: #7D9029">login=</span><span style="color: #BA2121">&quot;login&quot;</span>
  <span style="border: 1px solid #FF0000">@</span><span style="color: #7D9029">expired=</span><span style="color: #BA2121">&quot;removeTokens&quot;</span>
<span style="color: #008000; font-weight: bold">/&gt;</span>


<span style="color: #008000; font-weight: bold">&lt;script&gt;</span>
<span style="color: #008000; font-weight: bold">import</span> {SessionCountdown} from <span style="color: #BA2121">&#39;@nswdoe/doe-ui-core&#39;</span>

<span style="color: #008000; font-weight: bold">export</span> <span style="color: #008000; font-weight: bold">default</span> {
  beforeMount() {
    <span style="color: #008000; font-weight: bold">this</span>.$activityMonitor.activate()
  },

  beforeDestroy() {
    <span style="color: #008000; font-weight: bold">this</span>.$activityMonitor.deactivate()
  },

  methods<span style="color: #666666">:</span> {
    continueSession() {
      <span style="color: #008000; font-weight: bold">this</span>.showCountdown <span style="color: #666666">=</span> <span style="color: #008000; font-weight: bold">false</span>
    },
    async login() {
      <span style="color: #008000; font-weight: bold">this</span>.showCountdown <span style="color: #666666">=</span> <span style="color: #008000; font-weight: bold">false</span>
      await <span style="color: #008000; font-weight: bold">this</span>.$auth.DET.startAuth()
    },
    removeTokens() {
      API.clearTokens()
    }
  },

  computed<span style="color: #666666">:</span> {
    showCountdown<span style="color: #666666">:</span> {
      get() {
        <span style="color: #008000; font-weight: bold">return</span> <span style="color: #008000; font-weight: bold">this</span>.$store.state.showTimeoutBanner
      },
      set(value) {
        <span style="color: #008000; font-weight: bold">this</span>.$store.dispatch(<span style="color: #BA2121">&#39;set&#39;</span>, [<span style="color: #BA2121">&#39;showTimeoutBanner&#39;</span>, value])
      }
    }
  }
}
<span style="color: #008000; font-weight: bold">&lt;/script&gt;</span>


<span style="color: #008000; font-weight: bold">&lt;style&gt;</span>
<span style="color: #0000FF; font-weight: bold">.session-countdown</span> {
  <span style="color: #008000; font-weight: bold">margin-top</span><span style="color: #666666">:</span> <span style="color: #666666">6</span>rem;
  <span style="color: #008000; font-weight: bold">margin-bottom</span><span style="color: #666666">:</span> <span style="color: #666666">-3</span>rem;
  <span style="color: #008000; font-weight: bold">margin-left</span><span style="color: #666666">:</span> <span style="color: #666666">3</span>rem;
  <span style="color: #008000; font-weight: bold">margin-right</span><span style="color: #666666">:</span> <span style="color: #666666">3</span>rem;
}
<span style="color: #0000FF; font-weight: bold">.session-timeout</span> {
  <span style="color: #008000; font-weight: bold">top</span><span style="color: #666666">:</span> <span style="color: #666666">4</span>rem;
}
<span style="color: #008000; font-weight: bold">&lt;/style&gt;</span>
</pre>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <SimpleTableCard
        title="Component Properties"
        :headers="cProps.headers"
        :data="cProps.data"
      />
      <SimpleTableCard
        title="Component Events"
        :headers="cEvents.headers"
        :data="cEvents.data"
      />
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'showCountdown',
            type: 'Boolean',
            desc: 'Show the session countdown alert - default: false'
          },
          {
            name: 'minutesWarning',
            type: 'Number',
            desc: 'Warning period in minutes before session times out - default: 5 (if set to 0 then skip the countdown alert)'
          },
          {
            name: 'sessionCountdownAlertCssClass',
            type: 'String',
            desc: 'CSS class for the session countdown alert - this can be used to position the alert (in page)'
          },
          {
            name: 'sessionTimeoutDialogCssClass',
            type: 'String',
            desc: 'CSS class for the session timeout dialog - this can be used to position the dialog (overlay)'
          }
        ]
      },
      cEvents: {
        headers: ['Event', 'Desc'],
        data: [
          {
            event: 'continue',
            desc: "Emitted when the 'CONTINUE SESSION' link is clicked in the session countdown alert (eg. hide the alert)"
          },
          {
            event: 'login',
            desc: "Emitted when the 'LOGIN TO CONTINUE' link is clicked in the session timedout dialog (eg. hide the alert and re-activate the ActivityMonitor)"
          },
          {
            event: 'expired',
            desc: 'Emitted when the session has timedout (eg. remove local storage tokens)'
          }
        ]
      },
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
