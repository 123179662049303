<template>
  <v-container fluid>
    <Banner
      heading="Guidelines"
      subtitle="General guidelines around designing apps for DOE."
      content-class="pl-0"
    />
    <v-row>
      <v-col class="pt-0 pb-0">
        <v-tabs>
          <v-container class="d-flex">
            <v-tab href="#tab-1">
              GENERAL
            </v-tab>
            <v-tab href="#tab-2">
              ERROR HANDLING
            </v-tab>
          </v-container>
          <v-tab-item
            value="tab-1"
            class="content pr-6"
          >
            <GridSystem />
            <SpacingSystem />
            <StylingGuidelines />
          </v-tab-item>
          <v-tab-item
            value="tab-2"
            class="content pr-6"
          >
            <AlertsErrorHandling />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Banner from '../../components/Banner';
  import GridSystem from './GridSystem';
  import SpacingSystem from './SpacingSystem';
  import StylingGuidelines from './StylingGuidelines';
  import AlertsErrorHandling from './AlertsErrorHandling';

  export default {
    name: 'Guidelines',
    components: {
      Banner,
      GridSystem,
      SpacingSystem,
      StylingGuidelines,
      AlertsErrorHandling
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-slide-group__wrapper,
.v-slide-group__wrapper .v-card {

  .v-card__text ,.v-tab,
    .v-tab--active {
    color: #121212;
  }
  .v-tabs-slider-wrapper {
    border-bottom: 4px solid #ce0037;
  }
  .v-tab--active,.v-tab--active .v-card .v-card__text {
    font-weight: bold;
  }
}
.content{
  padding-top:60px;
  padding-bottom: 30px;
}
</style>
