<template>
  <v-container>
    <v-row class="ma-0">
      <v-switch
        v-model="tabsInAction"
        label="Toggle this switch on to see the Tab Navigation component in action on this page."
      />
    </v-row>

    <v-row v-if="tabsInAction">
      <TabNavigation
        :items="items"
        class="make-wide pb-10"
      >
        <template slot="content1">
          <v-card class="pa-6">
            <h2>
              About
            </h2>
            <p class="pt-5">
              Tabs are a form of secondary in-page navigation, helping to group different types of content in a limited space.
            </p>
            <p>
              Use tabs to split long content into manageable chunks to avoid overwhelming the user. By default the first tab is selected.
            </p>
            <v-col class="ml-0 pt-8 px-10 pb-10 imgBackground d-flex justify-center">
              <img
                src="@/assets/tabnavigation/tabnavigation-toolbar.png"
                width="100%"
                height="100%"
                alt="display of tab navigation toolbar"
              >
            </v-col>
            <v-col class="ml-0 pt-8 px-10 pb-10 d-flex justify-center">
              <img
                src="@/assets/tabnavigation/tabnavigation-vertical.png"
                width="60%"
                height="60%"
                alt="display of tab navigation toolbar"
              >
            </v-col>
          </v-card>
        </template>
      </TabNavigation>
      <br>
    </v-row>

    <v-row v-if="!tabsInAction">
      <v-col cols="9">
        <v-container>
          <h2>
            About
          </h2>
          <p class="pt-5">
            Tabs are a form of secondary in-page navigation, helping to group different types of content in a limited space.
          </p>
          <p>
            Use tabs to split long content into manageable chunks to avoid overwhelming the user. By default the first tab is selected.
          </p>

          <v-card>
            <v-col class="ml-0 mt-3 pt-8 px-10 pb-10 imgBackground d-flex justify-center">
              <img
                src="@/assets/tabnavigation/tabnavigation-toolbar.png"
                width="100%"
                height="100%"
                alt="display of tab navigation toolbar"
              >
            </v-col>
          </v-card>
          <v-card>
            <v-col class="ml-0 mt-6 pt-8 px-10 pb-10 d-flex justify-center">
              <img
                src="@/assets/tabnavigation/tabnavigation-vertical.png"
                width="60%"
                height="60%"
                alt="display of tab navigation toolbar"
              >
            </v-col>
          </v-card>
        </v-container>

        <v-card class="pl-6 mt-6">
          <v-row
            style="position: relative; left: -12px;"
          >
            <v-card-title class="pb-0">
              Usage
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <!-- todo: figure out an efficient way to generate formatted code in html -->

              <p class="pt-5">
                To see the full list of Props in the API go to <a
                  href="https://vuetifyjs.com/en/api/v-tabs/"
                  target="_blank"
                >https://vuetifyjs.com/en/api/v-tabs/</a>
              </p>
              <p>
                To implement Tab Navigation in a page, ensure the following is present:
              </p>
              <code>
                <span>import</span><span><span>&nbsp;{&nbsp;</span><span>TabNavigation</span><span>&nbsp;}&nbsp;</span><span>from</span><span style="color: #000000;">&nbsp;</span><span style="color: #0000FF">&#39;@nswdoe/doe-ui-core&#39;</span><span style="color: #000000;">;</span></span>
              </code>
              <v-row>
                <v-col>
                  Template:
                  <!-- HTML generated using hilite.me --><div style=" overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
                    <pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;TabNavigation</span>
    <span style="color: #0000CC">v-model=</span><span>&quot;tab&quot;</span>
    <span style="color: #0000CC">:items=</span><span>&quot;items&quot;</span>
    <span style="color: #0000CC">:centre-active=</span><span>&quot;centerActive&quot;</span>
    <span style="color: #0000CC">:fixed-tabs=</span><span>&quot;fixedActive&quot;</span>
    <span style="color: #0000CC">:icons-and-text=</span><span>&quot;iconsAndText&quot;</span>
    <span style="color: #0000CC">:icons-only=</span><span>&quot;iconsOnly&quot;</span>
    <span style="color: #0000CC">:next-icon=</span><span>&quot;nextIcon&quot;</span>
    <span style="color: #0000CC">:prev-icon=</span><span>&quot;prevIcon&quot;</span>
    <span style="color: #0000CC">:right=</span><span>&quot;right&quot;</span>
  <span style="color: #007700">/&gt;</span>
</pre>
                  </div>
                </v-col>
              </v-row>
              <!-- <pre>
                  <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                    <tbody><tr>
                      <td
id="tabnavigation-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                      <td
id="tabnavigation-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                      <td
id="tabnavigation-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">TabNavigation</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                      <td
id="tabnavigation-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">v-model</span>="<span class="pl-s">tab</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                      <td
id="tabnavigation-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:items</span>="<span class="pl-s">items</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                      <td
id="tabnavigation-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:center-active</span>="<span class="pl-s">centreActive</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                      <td
id="tabnavigation-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:fixed-tabs</span>="<span class="pl-s">fixedActive</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                      <td
id="tabnavigation-html-LC7"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:icons-and-text</span>="<span class="pl-s">iconsAndText</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L8"
class="blob-num js-line-number"
data-line-number="8"
/>
                      <td
id="tabnavigation-html-LC8"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:icons-only</span>="<span class="pl-s">iconsOnly</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                      <td
id="tabnavigation-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:next-icon</span>="<span class="pl-s">nextIcon</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L10"
class="blob-num js-line-number"
data-line-number="10"
/>
                      <td
id="tabnavigation-html-LC10"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:prev-icon</span>="<span class="pl-s">prevIcon</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                      <td
id="tabnavigation-html-LC11"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:right</span>="<span class="pl-s">right</span>"</td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                      <td
id="tabnavigation-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                      <td
id="tabnavigation-html-LC13"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">TabNavigation</span><span class="pl-kos">&gt;</span></td>
                    </tr>
                    <tr>
                      <td
id="tabnavigation-html-L14"
class="blob-num js-line-number"
data-line-number="14"
/>
                      <td
id="tabnavigation-html-LC14"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span>v-container</span><span class="pl-kos">&gt;</span></td>
                    </tr>
                   </tbody>
                  </table>
                </pre> -->
            </v-col>
          </v-row>
        </v-card>

        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
        <SimpleTableCard
          title="Slot properties"
          :headers="sProps.headers"
          :data="sProps.data"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { TabNavigation } from '@nswdoe/doe-ui-core';
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'TabNavigationUsage',
    components: {
      SimpleTableCard,
      TabNavigation
    },
    data() {
      return {
        tabsInAction: false,
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [
            {
              name: 'centerActive',
              type: 'Boolean',
              desc: 'If used, centers the active tab within the tab navigation (defaults to <span class="code">false</span>; only effective when there are sufficient tabs to allow scrolling and <span class="code">not implemented in vertical tabs yet</span>)'
            }, {
              name: 'fixedTabs',
              type: 'Boolean',
              desc: 'If used, fills out tabs up to a maximum width of 300px. (defaults to <span class="code">false</span> and <span class="code">not implemented in vertical tabs yet</span>)'
            }, {
              name: 'iconsAndText',
              type: 'Boolean',
              desc: 'If used, increases height of tab navigation to accomodate icons listed within the <span class="code">items</span> object. (defaults to <span class="code">false</span>)'
            }, {
              name: 'iconsOnly',
              type: 'Boolean',
              desc: 'If used, only displays icons within the tab navigation instead of text.<br />(defaults to <span class="code">false</span>; this will override the <span class="code">:icons-and-text</span> setting)'
            }, {
              name: 'items',
              type: 'Object',
              desc: 'The object containing the ID for the specific tab (<span class="code">id</span>), the tab name (<span class="code">tab</span>), content used with respect to the tab (<span class="code">content</span>), and Materials Design icon associated with the tab (<span class="code">icon</span>) (Mandatory). See the Demo page for an example of the format for the <span class="code">items</span> object.'
            }, {
              name: 'nextIcon',
              type: 'String',
              desc: 'The Materials Design icon name for the tab navigation\'s next icon. (defaults to <span class="code">keyboard_arrow_right</span> and <span class="code">not implemented in vertical tabs yet</span>)'
            }, {
              name: 'prevIcon',
              type: 'String',
              desc: 'The Materials Design icon name for the tab navigation\'s previous icon. (defaults to <span class="code">keyboard_arrow_left</span> and <span class="code">not implemented in vertical tabs yet</span>)'
            }, {
              name: 'right',
              type: 'Boolean',
              desc: 'If used, aligns tabs within the tab navigation to the right.<br />(defaults to <span class="code">false</span>; only effective when there are sufficient tabs to allow a gap within the tab navigation and <span class="code">not implemented in vertical tabs yet</span>)'
            }, {
              name: 'textTransform',
              type: 'String',
              desc: 'If used, tab labels can be in "uppercase", "lowercase" or "none" (default is <span class="code">uppercase</span>)'
            }
          ]
        },
        sProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'content{n}',
            type: 'Slot',
            desc: 'Override contents for item. {n} is <span class="code">id</span> property of item object or <span class="code">index</span> (when <span class="code">id</span> not available).<br /><br />Please Note: This slot is best used if images are to be used within content.'
          }]
        },
        items: [
          {
            id: 1,
            tab: 'About',
            content: '' // replaced by the "content1" content slot located near the top of the page
           },
          {
            id: 2,
            tab: 'Usage',
            content:
              `<pre>
                  <table class="highlight tab-size js-file-line-container"data-tab-size="8">
                    <tbody>
                      <tr>
                        <td id="tabnavigation-html-L1"class="blob-num js-line-number"data-line-number="1"></td>
                        <td id="tabnavigation-html-LC1"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span>
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L2"class="blob-num js-line-number"data-line-number="2"></td>
                        <td id="tabnavigation-html-LC2"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-kos">&nbsp;&nbsp;&lt;</span><span class="pl-ent">TabNavigation</span>
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L3"class="blob-num js-line-number"data-line-number="3"></td>
                        <td id="tabnavigation-html-LC3"class="blob-code blob-code-inner js-file-line"
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;v-model</span>="<span class="pl-s">tab</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L4"class="blob-num js-line-number"data-line-number="4"></td>
                        <td id="tabnavigation-html-LC4"class="blob-code blob-code-inner js-file-line"
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:items</span>="<span class="pl-s">items</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L5"class="blob-num js-line-number"data-line-number="5"></td>
                        <td id="tabnavigation-html-LC5"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:center-active</span>="<span class="pl-s">centreActive</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L6"class="blob-num js-line-number"data-line-number="6"></td>
                        <td id="tabnavigation-html-LC6"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:fixed-tabs</span>="<span class="pl-s">fixedActive</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L7"class="blob-num js-line-number"data-line-number="7"></td>
                        <td id="tabnavigation-html-LC7"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:icons-and-text</span>="<span class="pl-s">iconsAndText</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L8"class="blob-num js-line-number"data-line-number="8"></td>
                        <td id="tabnavigation-html-LC8"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:icons-only</span>="<span class="pl-s">iconsOnly</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L9"class="blob-num js-line-number"data-line-number="9"></td>
                        <td id="tabnavigation-html-LC9"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:next-icon</span>="<span class="pl-s">nextIcon</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L10"class="blob-num js-line-number"data-line-number="10"></td>
                        <td id="tabnavigation-html-LC10"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:prev-icon</span>="<span class="pl-s">prevIcon</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L11"class="blob-num js-line-number"data-line-number="11"></td>
                        <td id="tabnavigation-html-LC11"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-c1">&nbsp;&nbsp;&nbsp;&nbsp;:right</span>="<span class="pl-s">right</span>"
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L12"class="blob-num js-line-number"data-line-number="12"></td>
                        <td id="tabnavigation-html-LC12"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-kos">&nbsp;&nbsp;&gt;</span>
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L13"class="blob-num js-line-number"data-line-number="13"></td>
                        <td id="tabnavigation-html-LC13"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-kos">&nbsp;&nbsp;&lt;/</span><span class="pl-ent">TabNavigation</span><span class="pl-kos">&gt;</span>
                        </td>
                      </tr>
                      <tr>
                        <td id="tabnavigation-html-L14"class="blob-num js-line-number"data-line-number="14"></td>
                        <td id="tabnavigation-html-LC14"class="blob-code blob-code-inner js-file-line">
                          <span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </pre>

<br/>
<br/>
For Vertical Tab Navigation
<br/>
<br/>
<!-- HTML generated using hilite.me -->
<div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%">import { TabNavigation } from &quot;@nswdoe/doe-ui-core&quot;;

<span style="color: #008000; font-weight: bold">&lt;v</span><span style="color: #7D9029">-container</span><span style="color: #008000; font-weight: bold">&gt;</span>
  <span style="color: #008000; font-weight: bold">&lt;TabNavigation</span>
    <span style="color: #7D9029">v-model=</span><span style="color: #BA2121">&quot;tab&quot;</span>
    <span style="color: #7D9029">:vertical=</span><span style="color: #BA2121">&quot;true&quot;</span>
    <span style="color: #7D9029">:items=</span><span style="color: #BA2121">&quot;items&quot;</span>
    <span style="color: #7D9029">text-transform=</span><span style="color: #BA2121">&quot;none&quot;</span>
    <span style="color: #7D9029">:hide-slider=</span><span style="color: #BA2121">&quot;true&quot;</span>
  <span style="color: #008000; font-weight: bold">&gt;</span>
  <span style="color: #008000; font-weight: bold">&lt;/TabNavigation&gt;</span>
<span style="border: 1px solid #FF0000">&lt;</span>/v-container&gt;
</pre></div>
                `
          },
          {
            id: 3,
            tab: 'Component Properties',
            content:
              `<div data-v-74f90328="" data-v-7111fac8="" class="my-6 mr-3 v-card v-sheet theme--light">
                  <div data-v-74f90328="" class="v-card__title"> Component properties </div>
                  <div data-v-74f90328="" class="v-card__subtitle">
                      <div data-v-74f90328=""></div>
                  </div>
                  <div data-v-74f90328="" class="v-data-table theme--light">
                      <div class="v-data-table__wrapper">
                          <table>
                              <thead data-v-74f90328="">
                                  <tr data-v-74f90328="">
                                      <th data-v-74f90328="" class="text-left">Name</th>
                                      <th data-v-74f90328="" class="text-left">Type</th>
                                      <th data-v-74f90328="" class="text-left">Desc</th>
                                  </tr>
                              </thead>
                              <tbody data-v-74f90328="">
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">centerActive</td>
                                      <td data-v-74f90328="">Boolean</td>
                                      <td data-v-74f90328="">If used, centers the active tab within the tab navigation (defaults to <span class="code">false</span>; only effective when there are sufficient tabs to allow scrolling and <span class="code">not implemented in vertical tabs yet</span>)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">fixedTabs</td>
                                      <td data-v-74f90328="">Boolean</td>
                                      <td data-v-74f90328="">If used, fills out tabs up to a maximum width of 300px. (defaults to <span class="code">false</span> and <span class="code">not implemented in vertical tabs yet</span>)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">iconsAndText</td>
                                      <td data-v-74f90328="">Boolean</td>
                                      <td data-v-74f90328="">If used, increases height of tab navigation to accomodate icons listed within the <span class="code">items</span> object. (defaults to <span class="code">false</span>)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">iconsOnly</td>
                                      <td data-v-74f90328="">Boolean</td>
                                      <td data-v-74f90328="">If used, only displays icons within the tab navigation instead of text.<br>(defaults to <span class="code">false</span>; this will override the <span class="code">:icons-and-text</span> setting)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">items</td>
                                      <td data-v-74f90328="">Object</td>
                                      <td data-v-74f90328="">The object containing the tab name (<span class="code">tab</span>), content used with respect to the tab (<span class="code">content</span>), and Materials Design icon associated with the tab (<span class="code">icon</span>) (Mandatory). See the Demo page for an example of the format for the <span class="code">items</span> object.</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">nextIcon</td>
                                      <td data-v-74f90328="">String</td>
                                      <td data-v-74f90328="">The Materials Design icon name for the tab navigation's next icon. (defaults to <span class="code">keyboard_arrow_right</span> and <span class="code">not implemented in vertical tabs yet</span>)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">prevIcon</td>
                                      <td data-v-74f90328="">String</td>
                                      <td data-v-74f90328="">The Materials Design icon name for the tab navigation's previous icon. (defaults to <span class="code">keyboard_arrow_left</span> and <span class="code">not implemented in vertical tabs yet</span>)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">right</td>
                                      <td data-v-74f90328="">Boolean</td>
                                      <td data-v-74f90328="">If used, aligns tabs within the tab navigation to the right.<br>(defaults to <span class="code">false</span>; only effective when there are sufficient tabs to allow a gap within the tab navigation and <span class="code">not implemented in vertical tabs yet</span>)</td>
                                  </tr>
                                  <tr data-v-74f90328="">
                                      <td data-v-74f90328="">textTransform</td>
                                      <td data-v-74f90328="">String</td>
                                      <td data-v-74f90328="">If used, tab labels can be in "uppercase", "lowercase" or "none" (default is <span class="code">uppercase</span>)</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div data-v-74f90328="" data-v-7111fac8="" class="my-6 mr-3 v-card v-sheet theme--light">
                <div data-v-74f90328="" class="v-card__title"> Slot properties </div>
                <div data-v-74f90328="" class="v-card__subtitle">
                  <div data-v-74f90328=""></div>
                </div>
                <div data-v-74f90328="" class="v-data-table theme--light">
                  <div class="v-data-table__wrapper">
                    <table>
                      <thead data-v-74f90328="">
                        <tr data-v-74f90328="">
                          <th data-v-74f90328="" class="text-left">Name</th>
                          <th data-v-74f90328="" class="text-left">Type</th>
                          <th data-v-74f90328="" class="text-left">Desc</th>
                        </tr>
                      </thead>
                      <tbody data-v-74f90328="">
                        <tr data-v-74f90328="">
                          <td data-v-74f90328="">content{n}</td>
                          <td data-v-74f90328="">Slot</td>
                          <td data-v-74f90328="">Override contents for item. {n} is <span class="code">id</span> property of item object or <span class="code">index</span> (when <span class="code">id</span> not available).<br><br>Please Note: This slot is best used if images are to be used within cntent.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>`
          }
        ]
      }
    }
  }
</script>

<style scoped>
import
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}

.make-wide {
  width: 75vw !important;
}
code {
  color: #333333;
  background: #ebe8e8;
  padding: .5rem 1.5rem;
}
.v-tab-navigation .v-card__text > pre {
  white-space: normal !important;
}
</style>

