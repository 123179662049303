<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Accordion style expansion panels
        </h2>
        <p class="pt-5">
          Sections of content can be expanded and collapsed like an accordion. We use the 'accordion' and 'focusable' settings by default.
        </p>

        <v-card>
          <v-col class="ml-0 mt-4 pa-5 imgBackground d-flex justify-center">
            <img
              src="../../../assets/expansionpanel/collapsed.png"
              width="100%"
              height="100%"
              alt="expansion panel collapsed"
            >
          </v-col>
          <v-col class="ml-0 mb-6 pa-5 imgBackground d-flex justify-center">
            <img
              src="../../../assets/expansionpanel/expanded.png"
              width="100%"
              height="100%"
              alt="expansion panel expanded"
            >
          </v-col>
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> AdsExpansionPanel <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-expansion-html-L1"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L2"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">ads-expansion-panel</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L3"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC3"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-c1">:items</span>="<span class="pl-s">items</span>"</td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L4"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC4"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L2"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">template slot="content1"&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L3"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">This content will replace content for first item.</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L2"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">/template&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html-L5"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html-LC5"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">ads-expansion-panel</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-expansion-html"
class="blob-num js-line-number"
/>
                  <td
id="file-expansion-html"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <SimpleTableCard
        title="Component properties"
        :headers="cProps.headers"
        :data="cProps.data"
      />
      <SimpleTableCard
        title="Slot properties"
        :headers="sPros.headers"
        :data="sPros.data"
      />
    </v-col>
  </v-row>
</template>
<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'Usage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'items',
            type: 'Array',           
            desc: 'Array of expansion panels objects : [{<strong>id</strong>:\'expansion_id\',<strong>icon</strong>:\'ICON_NAME\',<strong>title</strong>:"TITLE FOR EXPANSION",<strong>content</strong>:"CONTENT TO DISPLAY"}]'
          }]
        },
        sPros: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'content{n}',
            type: 'Slot',           
            desc: 'Override contents for item. {n} is <strong>id</strong> property of item object or <strong>index</strong> (when <strong>id</strong> not available).'
          }]
        }
      }
    }
  }
</script>

<style scoped>
import 
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>