<template>
  <v-row class="mx-2">
    <v-col 
      cols="12"
      md="9">
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <ul>
          <li>
            The data table component allows users to display tabular data. Features include sorting, searching, pagination, and row selection.
          </li>
          <li>
            It is based on the <a href="https://vuetifyjs.com/en/components/data-tables/#data-tables">Vuetify data tables component</a>
          </li>
        </ul>

        <v-card>
          <v-col class="mt-4 pa-9 d-flex justify-center">
            <img
              src="../../../assets/datatable/ADSDataTable.png"
              width="100%"
              height="100%"
            >
          </v-col>
        </v-card>

        <v-card class="code-card px-6 py-3 mt-12 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p class="mt-3">
            Component code in Vue component &lt;template&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
<!-- eslint-disable -->
<div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
<pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;AdsDataTable</span>
  <span style="color: #0000CC">:headers=</span><span>&quot;headers&quot;</span>
  <span style="color: #0000CC">:items=</span><span>&quot;items&quot;</span>
  <span style="color: #0000CC">sort-by=</span><span>&quot;name&quot;</span>
  <span style="color: #0000CC">search-label=</span><span>&quot;Find dessert&quot;</span>
  <span style="color: #0000CC">sort-desc</span>
  <span style="color: #0000CC">@click:row=</span><span>&quot;rowAction&quot;</span>
<span style="color: #007700">&gt;</span>
  <span style="color: #007700">&lt;template</span> <span style="color: #0000CC">v-slot:item.calories=</span><span>&quot;{ item }&quot;</span><span style="color: #007700">&gt;</span>
    <span style="color: #007700">&lt;v-chip color='orange'&gt;</span><span v-html="'{{ item.calories }}'"></span><span style="color: #007700">&lt;/v-chip&gt;</span>
  <span style="color: #007700">&lt;/template&gt;</span>
<span style="color: #007700">&lt;/AdsDataTable&gt;</span>
</pre>
</div>
          <!-- eslint-enable -->
          <p class="mt-6">
            Component code in Vue component &lt;script&gt;:
          </p>
          <!-- HTML generated using hilite.me -->
<!-- eslint-disable -->
<div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;"><pre style="margin: 0; line-height: 125%"><span style="color: #008000; font-weight: bold">import</span> { AdsDataTable } from <span style="color: #BA2121">&quot;@nswdoe/doe-ui-core&quot;</span>;

<span style="color: #008000; font-weight: bold">export</span> <span style="color: #008000; font-weight: bold">default</span> {
  name<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;AdsDataTableDemo&quot;</span>,
  components<span style="color: #666666">:</span> {
    AdsDataTable
  },
  data() {
    <span style="color: #008000; font-weight: bold">return</span> {
      headers<span style="color: #666666">:</span> [{
        text<span style="color: #666666">:</span> <span style="color: #666666">'Dessert (100g serving)'</span>,
        value<span style="color: #666666">:</span> <span style="color: #666666">'name'</span>,
      },
      {
        text<span style="color: #666666">:</span> <span style="color: #666666">'Calories'</span>,
        value<span style="color: #666666">:</span> <span style="color: #666666">'calories'</span>,
      },
      {
        text<span style="color: #666666">:</span> <span style="color: #666666">'Fat (g)'</span>,
        value<span style="color: #666666">:</span> <span style="color: #666666">'fat'</span>,
      },
      {
        text<span style="color: #666666">:</span> <span style="color: #666666">'Carbs (g)'</span>,
        value<span style="color: #666666">:</span> <span style="color: #666666">'carbs'</span>,
      },
      {
        text<span style="color: #666666">:</span> <span style="color: #666666">'Protein (g)'</span>,
        value<span style="color: #666666">:</span> <span style="color: #666666">'protein'</span>,
      },
      {
        text<span style="color: #666666">:</span> <span style="color: #666666">'Iron (%)'</span>,
        value<span style="color: #666666">:</span> <span style="color: #666666">'iron'</span>,
      }],
      items<span style="color: #666666">:</span> [{
        name<span style="color: #666666">:</span> <span style="color: #666666">'KitKat'</span>,
        calories<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;518&quot;</span>
        fat<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;26.0&quot;</span>
        carbs<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;65&quot;</span>
        protein<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;7&quot;</span>
        iron<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;6%&quot;</span>
      }, {
        name<span style="color: #666666">:</span> <span style="color: #666666">'Jelly bean'</span>,
        calories<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;518&quot;</span>
        fat<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;26.0&quot;</span>
        carbs<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;65&quot;</span>
        protein<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;7&quot;</span>
        iron<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;6%&quot;</span>
      }, {
        name<span style="color: #666666">:</span> <span style="color: #666666">'Cupcake'</span>,
        calories<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;518&quot;</span>
        fat<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;26.0&quot;</span>
        carbs<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;65&quot;</span>
        protein<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;7&quot;</span>
        iron<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;6%&quot;</span>
      }, {
        name<span style="color: #666666">:</span> <span style="color: #666666">'Gingerbread'</span>,
        calories<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;518&quot;</span>
        fat<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;26.0&quot;</span>
        carbs<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;65&quot;</span>
        protein<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;7&quot;</span>
        iron<span style="color: #666666">:</span> <span style="color: #BA2121">&quot;6%&quot;</span>
      }]
    };
  }
};
</pre></div>
          <!-- eslint-enable -->
        </v-card>

        <SimpleTableCard
          title="Component properties"
          subtitle="All props, slots, and events from <a href=&quot;https://vuetifyjs.com/en/api/v-data-table/&quot;>vuetify's v-data-table</a> are available."
          :headers="cProps.headers"
          :data="cProps.data"
        />

        <SimpleTableCard
          title="Old Datatable Component"
          subtitle="<a href=&quot;/components/datatable&quot;>Data table</a>"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'DataSwitcherUsage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: [],
          data: []
        }
      }
    }
  }
</script>
