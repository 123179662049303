<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          ADS Focus trap guidelines:
        </h2>
        <ul
          class="mt-2"
          type="1"
        >
          <li>
            The FocusTrap component traps the focus within a wrapped container element or component.
          </li>
          <li>
            This component is used for accessibility to ensure the user can't tab out of a particular area like a modal dialog box.  
          </li>
          <li>
            The tab focus wraps back to the first tabbable element.
          </li>
        </ul>

        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-8">
          <v-img
            src="../../../assets/focustrap/focustrap.png"
            class="pr-1 pb-1"
          />
        </v-card>

        <v-card class="code-card pl-6 mt-6">
          <v-row
            style="position: relative; left: -12px;"
          >
            <v-card-title class="pb-0">
              Usage
            </v-card-title>
          </v-row>
          <v-row>
            <v-col>
              <!-- todo: link to codepen -->
              <!-- todo: figure out an efficient way to generate formatted code in html -->
              <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> FocusTrap <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
              <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-trap-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-trap-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-trap-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">FocusTrap</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-trap-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:isActive</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L4"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-trap-html-LC4"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L5"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC5"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">a href="#"&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L6"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">Some</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L7"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-trap-html-LC7"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L8"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC8"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">a href="#"&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L9"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">Focusable</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L10"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-trap-html-LC10"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L11"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">a href="#"&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L12"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC12"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">Parts</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L13"
class="blob-num js-line-number"
/>
                  <td
id="file-trap-html-LC13"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">/a&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L14"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-trap-html-LC14"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">FocusTrap</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-trap-html-L15"
class="blob-num js-line-number"
data-line-number="14"
/>
                  <td
id="file-trap-html-LC15"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
            </v-col>
          </v-row>
        </v-card>
        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'Usage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'isActive',
            type: 'Boolean',           
            desc: 'default: false <br/>An active focus trap ensures that the focus never leaves the wrapped element/component. Deactivate when opening a nested modal.'
          }, {
            name: 'elementToFocusOnStop',
            type: 'Object',            
            desc: 'default: null <br/>Specifying a DOM object will focus on the specified element when the focus trap is deactivated.'
          }, {
            name: 'returnFocusOnStop',
            type: 'Boolean',
            desc: 'default: true<br/>When this property is set to true, the previously focused element (if there is one) will be focused when deactivating a focus trap. This feature will be overridden if <b>elementToFocusOnStop</b> is specified.'
          }]
        }
      }
    }
  }
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
