<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
  >
    <v-card
      tile
      color="#E5E5E5"
      :class="{ 'mobile': $vuetify.breakpoint.mdAndDown }"
    >
      <div class="d-flex align-center dialog-header mb-10">
        <v-btn
          icon
          class="ml-3"
          dark
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="white--text title">
          ADS Case Study - {{ name }}
        </div>
      </div>
      <div class="d-flex align-top justify-center flex-wrap-reverse">
        <div :class="{'mr-6': $vuetify.breakpoint.mdAndUp}">
          <div
            v-for="(s, i) in screenshots"
            :key="i"
            class="pb-10 d-flex"
            :class="{
              'justify-end': $vuetify.breakpoint.mdAndUp,
              'justify-center': $vuetify.breakpoint.smAndDown}"
          >
            <img
              :src="require(`@/assets/casestudies/${assetFolder}/${s}`)"
            >
          </div>
        </div>
        <div class="aboutPanel">
          <v-card class="pa-6 pb-10 mb-4">
            <h3 class="pb-4">
              About this product
            </h3>
            <p 
              v-for="(p, i) in paragraphs"
              :key="i"
            >
              {{ p }}
            </p>
            <div v-if="components.length">
              <h3 class="pt-8 pb-4">
                ADS components shown
              </h3>
              <PropsList :items="components" />
            </div>
            <div v-if="platforms.length">
              <h3 class="pt-8 pb-4">
                Platforms
              </h3>
              <PropsList :items="platforms" />
            </div>
            <div v-if="moreInformation.length">
              <h3 class="pt-8 pb-4">
                More information
              </h3>
              <PropsList :items="moreInformation" />
            </div>
            <p
              v-if="suffix"
              class="pt-8"
            >
              {{ suffix }}
            </p>
          </v-card>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PropsList from './PropsList';

export default {
  name: 'AppDialog',
  components: {
    PropsList
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    assetFolder: {
      type: String,
      default: null
    },
    screenshots: {
      type: Array,
      default: () => []
    },
    paragraphs: {
      type: Array,
      default: () => []
    },
    components: {
      type: Array,
      default: () => []
    },
    platforms: {
      type: Array,
      default: () => []
    },
    moreInformation: {
      type: Array,
      default: () => []
    },
    suffix: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showDialog: this.value
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.showDialog = value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-header {
    background-color: $ads-navy;
    height: 64px;
  }

  .aboutPanel {
    min-width: 380px;
    max-width: 392px;
  }
  .mobile {
    .aboutPanel {
      max-width: 90%
    }
  }
</style>
