<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card
          outlined
          :dark="transparent"
          class="mr-3"
        >
          <div class="mt-6 ml-6">
            <div
              v-if="!showAll"
              class="d-flex justify-center"
            >
              <AppIcon
                :app-name="icon"
                :transparent="transparent"
                :size="size"
              />
            </div>
            <div
              v-if="showAll"
              class="d-flex flex-wrap"
            >
              <div
                v-for="(i, index) in icons"
                :key="index"
                class="mx-3 my-2"
              >
                <div>
                  <AppIcon
                    :app-name="i"
                    :size="size"
                  />
                </div>
                <div class="icon-container">
                  <span class="wrap-text caption">{{ i }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-center ma-12">
            <div class="pr-6">
              <v-select
                v-model="icon"
                label="Icon"
                outlined
                placeholder=" "
                :items="icons"
                :disabled="showAll"
                hide-details
              />
              <div class="d-flex justify-center">
                <v-switch
                  v-model="showAll"
                  label="Show all"
                  @change="toggleShowAll"
                />
              </div>
            </div>
            <div class="pr-6">
              <v-select
                v-model="size"
                label="Size"
                outlined
                placeholder=" "
                :items="sizes"
              />
            </div>
            <div class="pr-6">
              <v-switch
                v-model="transparent"
                label="Transparent"
                :disabled="showAll"
              />
            </div>
          </div>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { AppIcon } from '@nswdoe/doe-ui-core';

export default {
  name: 'AppIconDemo',
  components: {
    AppIcon
  },
  data() {
    let icons;
    if (window.iconToClass) {
      icons = Object.keys(window.iconToClass).sort();
    } else {
      icons = ['my-alan', 'my-ed-connect', 'my-nsw-gov', 'my-mma'];
    }

    return {
      transparent: false,
      size: '40px',
      sizes: ['16px', '24px', '32px', '40px', '48px', '56px', '62px'],
      icon: 'my-nsw-gov',
      icons: icons,
      showAll: false
    };
  },
  methods: {
    toggleShowAll(showAll) {
      if (showAll) {
        this.transparent = false;
        this.size = '56px';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.theme--dark.v-card {
  background-color: $ads-dark-70;
}

.icon-container {
  width: 80px;
}
.wrap-text {
  word-wrap: break-word;
}
</style>
