<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col>
            <v-row justify="end">
              <p>Last updated: 30 Oct 2020</p>
            </v-row>
          </v-col>
        </v-row>
        <div>
          <h2>
            About
          </h2>
          <p>
            Steppers are important for breaking up long complex forms into easily digestible sections. Steps are always editable and can be completed in a linear or non-linear progression.
          </p>
        </div>
        <div>
          <h2>
            States
          </h2>
          <ul>
            <li>By default, the first step is ‘active’ and the other states are ‘incomplete’</li>
            <li>Once a step hEas all fields completed with no errors, the indicator at the top changes to ‘complete’. </li>
            <li>If a step has been viewed but the user has not filled out any fields, the indicator remains as ‘incomplete’.</li>
            <li>If a step has fields filled but there are errors and the user navigates to another step, the indicator at the top changes to ‘error’</li>
          </ul>
        </div>
        <div class="my-3">
          <img
            class="mb-5"
            src="../../../assets/stepper/step-states.png"
          >
        </div>
        <div>
          <h2>
            Desktop - Horizontal
          </h2>
          <p>Use the horizontal stepper if there are 4 steps or less.</p>
        </div>
        <v-card class="pa-12 mt-3 mb-12">
          <div class="mb-3">
            <HStepper
              :steps="[{name: 'Setup'}, {name: 'Message Content'}, {name: 'Audience and Scheduling'}, {name: 'Review'}]"
            />
          </div>
          <div>
            <HStepper
              value="3"
              :steps="[{name: 'Setup', status: 'complete'}, {name: 'Message Content', status: 'invalid'}, {name: 'Audience and Scheduling'}, {name: 'Review'}]"
            />
          </div>
        </v-card>

        <div>
          <h2>
            Desktop - Vertical
          </h2>
          <p>Use the vertical stepper if there are 5 steps or more. This stepper to be placed to the left of the page content and fixed so that it is always accessible even if the user scrolls down the page.</p>
        </div>
        <v-card class="pa-12 mt-3">
          <v-row>
            <v-col>
              <Stepper
                :steps="[{name: 'About the author'}, {name: 'About the project'}, {name: 'Story content'}, {name: 'Additional resources'}, {name: 'Summary'}]"
              />
            </v-col>
            <v-col>
              <Stepper
                :current-step="3"
                :steps="[{name: 'About the author', status: 'complete'}, {name: 'About the project', status: 'invalid'}, {name: 'Story content'}, {name: 'Additional resources'}, {name: 'Summary'}]"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-6">
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
          <v-container
            class="pa-6"
            style="overflow: auto"
          >
            <!-- HTML generated using hilite.me -->
            <div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%"><span style="color: #008800; font-weight: bold">import</span> { HStepper, Stepper } from <span>&quot;@nswdoe/doe-ui-core&quot;</span>
                </pre>
              <pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;HStepper</span>
  <span style="color: #0000CC">v-model=</span><span>&quot;currentStep&quot;</span>
  <span style="color: #0000CC">:steps=</span><span>&quot;[{name: &#39;Setup&#39;}, {name: &#39;Message Content&#39;}, {name: &#39;Audience and Scheduling&#39;}, {name: &#39;Review&#39;}]&quot;</span>
  <span style="color: #0000CC">@</span><span style="color: #0000CC">changeStep=</span><span>&quot;changeStep&quot;</span>
<span style="color: #007700">/&gt;</span>

</pre>

              <pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;Stepper</span>
  <span style="color: #0000CC">:steps=</span><span>&quot;[{name: &#39;About the author&#39;}, {name: &#39;About the project&#39;}, {name: &#39;Story content&#39;}, {name: &#39;Additional resources&#39;}, {name: &#39;Summary&#39;}]&quot;</span>
  <span style="color: #0000CC">:currentStep=</span><span>&quot;currentStep&quot;</span>
  <span style="color: #0000CC">@</span><span style="color: #0000CC">select=</span><span>&quot;onSelect&quot;</span>
<span style="color: #007700">/&gt;</span>

</pre>
            </div>
          </v-container>
        </v-card>

        <div class="mt-8">
          <h2>
            Horizontal Stepper API
          </h2>
        </div>
        <SimpleTableCard
          title="Properties"
          :headers="hStepperProps.headers"
          :data="hStepperProps.data"
        />

        <SimpleTableCard
          title="Step properties"
          :headers="hStepperStepProps.headers"
          :data="hStepperStepProps.data"
        />

        <SimpleTableCard
          title="Step properties"
          :headers="hStepperEvents.headers"
          :data="hStepperEvents.data"
        />

        <div class="mt-8">
          <h2>
            Vertical Stepper API
          </h2>
        </div>

        <SimpleTableCard
          title="Properties"
          :headers="stepperProps.headers"
          :data="stepperProps.data"
        />

        <SimpleTableCard
          title="Step properties"
          :headers="stepperStepProps.headers"
          :data="stepperStepProps.data"
        />

        <SimpleTableCard
          title="Step properties"
          :headers="stepperEvents.headers"
          :data="stepperEvents.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { Stepper, HStepper } from '@nswdoe/doe-ui-core';
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'Usage',
  components: {
    HStepper,
    Stepper,
    SimpleTableCard
  },
  data() {
    return {
      hStepperProps: {
        headers: ['Name', 'Type', 'Description'],
        data: [
          {
            name: 'value',
            type: '[String, Number]',
            description: 'Number of the currently selected step (starting at 1)'
          },
          {
            name: 'steps',
            type: 'Array',
            description: 'Array of json steps (see <strong>Step properties</strong> below)'
          }
        ]
      },
      hStepperStepProps: {
        headers: ['Name', 'Type', 'Description'],
        data: [
          {
            name: 'name',
            type: 'String',
            description: 'Text to display for the step.'
          },
          {
            name: 'status',
            type: 'String',
            description: `(Optional) Sets a special step status:
              <ul>
                <li>
                  <strong>"complete"</strong> - Shows a tick inside green step circle
                </li>
                <li>
                  <strong>"invalid"</strong> - Shows a red exclamation mark inside white step circle
                </li>
                <li>
                  <strong>"disabled"</strong> - Prevents step being selectable
                </li>
              </ul>`
          }
        ]
      },
      hStepperEvents: {
        headers: ['Name', 'Parameters', 'Description'],
        data: [{
          name: '@changeStep',
          parameters: 'selectedStep, currentStep',
          description: 'Fires when user activates a step on the stepper. Will not fire if step is disabled.'
        }]
      },

      stepperProps: {
        headers: ['Name', 'Type', 'Description'],
        data: [
          {
            name: 'currentStep',
            type: 'Number',
            description: 'Number of the currently selected step (starting at 1)'
          },
          {
            name: 'steps',
            type: 'Array',
            description: 'Array of json steps (see <strong>Step properties</strong> below)'
          },
          {
            name: 'stepSpacing',
            type: 'String',
            description: '(Optional) Sets vertical spacing between steps. Value must include CSS unit, e.g. "1.5em". Default is "1em"'
          }
        ]
      },
      stepperStepProps: {
        headers: ['Name', 'Type', 'Description'],
        data: [
          {
            name: 'name',
            type: 'String',
            description: 'Text to display for the step.'
          },
          {
            name: 'status',
            type: 'String',
            description: `(Optional) Sets a special step status:
              <ul>
                <li>
                  <strong>"complete"</strong> - Shows a tick inside green step circle
                </li>
                <li>
                  <strong>"invalid"</strong> - Shows a red exclamation mark inside white step circle
                </li>
                <li>
                  <strong>"disabled"</strong> - Prevents step being selectable
                </li>
              </ul>`
          }
        ]
      },
      stepperEvents: {
        headers: ['Name', 'Parameters', 'Description'],
        data: [{
          name: '@select',
          parameters: 'selectedStep',
          description: `Fires when a step is selected. The parent component should then update
            <strong>currentStep</strong> with
            <strong>stepNumber</strong> to make the current step change. If no
            <strong>@select</strong> event is bound, steps will not be selectable.`
        }]
      }
    }
  }
};
</script>

<style scoped>
.v-data-table td {
  padding: 1em;
  font-size: 1rem;
}
.v-data-table th {
  font-size: 0.875rem;
}
</style>