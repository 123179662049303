<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col class="mt-8">
            <v-row justify="end">
              <p>Last updated: 30 June 2022</p>
            </v-row>
          </v-col>
        </v-row>

        <h2 class="mb-6">
          About
        </h2>

        <p>
          The purpose of this component is to offer the user the option to sign in to an application using their Service NSW account. 
        </p>

        <p>
          Once they check the component's checkbox, the action button below is enabled. Note that a method for redirecting the user once they click the button must be provided, as the button click emits an event to Parent ID Login's parent component.  
        </p>

        <v-card>
          <v-col class="pa-5 imgBackground d-flex justify-center">
            <img
              src="../../../assets/parentidlogin/ParentIdLogin.png"
            >
          </v-col>
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> ParentIdLogin <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-accountmenu-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-accountmenu-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-accountmenu-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">ParentIdLogin</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-accountmenu-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">title</span>="<span class="pl-s">Custom title</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-accountmenu-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">description</span>="<span class="pl-s">This content has been passed through the description property.</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-accountmenu-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">checkboxDescription</span>="<span class="pl-s">This is a custom label for the checkbox.</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-accountmenu-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">buttonTextContent</span>="<span class="pl-s">Custom button content</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                  <td
id="file-accountmenu-html-LC7"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:isActionPositionLeft</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">/&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-accountmenu-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>title</td>
                <td>String</td>
                <td>Card title</td>
              </tr>
              <tr>
                <td>description</td>
                <td>String</td>
                <td>Card body text</td>
              </tr>
              <tr>
                <td>checkboxDescription</td>
                <td>String</td>
                <td>Checkbox label</td>
              </tr>
              <tr>
                <td>buttonTextContent</td>
                <td>String</td>
                <td>Text to be displayed in the button</td>
              </tr>
              <tr>
                <td>isActionPositionLeft</td>
                <td>Boolean</td>
                <td>If true, aligns button to the left</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card class="my-6 mr-3">
        <v-card-title>
          Slots
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Slot
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>description</td>
                <td>Slot between title and button, body text area. Overrides default content.</td>
              </tr>
              <tr>
                <td>actions</td>
                <td>Button slot. A completely new button can be provided in place of the default one.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card class="my-6 mr-3">
        <v-card-title>
          Events
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Event
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>handleAction</td>
                <td>Emitted when default button is clicked.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ParentIdLoginUsage'
  }
</script>

