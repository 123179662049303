<template>
  <v-card
    :width="$vuetify.breakpoint.smAndUp ? '275px' : '80%'"
    @click="$emit('click')"
  >
    <v-img
      :src="require(`@/assets/casestudies/${thumbnailSrc}`)"
      height="170px"
      width="100%"
      contain
    />
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    thumbnailSrc: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card__title {
  font-weight: bold;
  word-break: unset;
  line-height: 1.56rem;
  margin-bottom: 8px;
}
.v-card__subtitle {
  font-size: 0.875rem;
  line-height: 1rem;
}
</style>
