<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <p class="pt-5">
          Telephone numbers are easier to read when their digits are chunked,
          rather than written in one large group. This component formats
          provided telephone numbers by inserting spaces between their digits
          according to their type when user leaves the field:
        </p>
        <AdsDataTable
          :headers="headers"
          :items="items"
          :hide-default-footer="true"
        />
      </v-container>

      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span
              style="color:#800080; "
>{</span> AdsFormatContactField <span style="color:#800080; ">}</span> from <span
              style="color:#800000; "
>"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody>
                <tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AdsFormatContactField</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">v-model</span>=<span class="pl-s">"value"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">/</span><span class="pl-ent" /><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>


                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-6">
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-card-text>
          <p style="color: #121212;">
            Component properties are the same as the native Vuetify component:
          </p>
          <p>
            <a
              href="https://vuetifyjs.com/en/api/v-text-field/"
              target="_blank"
            >Vuetify TextField Properties
            </a>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { AdsDataTable } from '@nswdoe/doe-ui-core';

export default {
  components: {
    AdsDataTable
  },
  data() {
    return {
      headers: 
      [
        {text: 'Phone number type', value: 'type'},
        {text: 'Entered number', value: 'input'},
        {text: 'Result', value: 'output'}
      ],
      items: 
      [
        {
          type: 'Landline including area code',
          input: '0212344321',
          output: '02 1234 4321'
        },
        {
          type: 'Landline without area code',
          input: '12344321',
          output: '1234 4321'
        },
        {
          type: 'Australia-wide landline',
          input: '1300975707',
          output: '1300 975 707'
        },
        {
          type: 'Alternative Australia-wide local-rate',
          input: '130000',
          output: '13 00 00'
        },
        {
          type: 'Mobile',
          input: '0400000000',
          output: '0400 294 738'
        },
      ],
    }
  }
};
</script>

<style></style>
