<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col>
            <v-row justify="end">
              <p>Last updated: 20 Jan 2019</p>
            </v-row>
          </v-col>
        </v-row>
        <h2 class="mt-6">
          Default app bar (64px height)
        </h2>
        <p>
          The default app bar comes with:
        </p>
        <ol type="1">
          <li>
            Hamburger menu - shows or hides the side navigation 
          </li>
          <li>
            App icon - takes the user back to home page when clicked on 
          </li>
          <li>
            Name of app - takes the user back to home page when clicked on 
          </li>
          <li>
            App switcher: Opens an app drawer with the user’s 9 most recent Essentials 
          </li>
          <li>
            Profile avatar: Opens a dialog for the user to log out
          </li>
        </ol>
        <v-row class="ml-0 mt-4 mb-6">
          <img src="../../../assets/appbar/appbar.png">
        </v-row>
        <h2 class="mt-6">
          Additions
        </h2>
        <p>
          Extra functions that can be added to the app bar:
        </p>
        <ol
          type="1"
          start="6"
        >
          <li>
            Search button - global search, searches the whole app
          </li>
          <li>
            Data switcher - changes the context of the information on the page e.g. a specific school
          </li>
          <li>
            Notifications
          </li>
        </ol>
        <v-row class="ml-0 mt-6 mb-6">
          <img src="../../../assets/appbar/appbar-alt.png">
        </v-row>
        <v-row>
          <v-col>
            <h2 class="ml-0 mt-6 mb-3">
              Responsive design
            </h2>
            <h3>Tablet (56px height)</h3>
            <v-row class="ml-0 mt-4 mb-9">
              <img src="../../../assets/appbar/appbar-tablet.png">
            </v-row>
            <h3>Mobile (56px height)</h3>
            <v-row class="ml-0 mt-4 mb-3">
              <img src="../../../assets/appbar/appbar-mobile.png">
            </v-row>
            <p class="caption">
              On mobile, the app icon is hidden and app switcher is disabled.
            </p>
          </v-col>
        </v-row>

        <v-card
          style="overflow:auto;"
          class="pl-6 mt-4"
        >
          <v-card-title class="pb-0 pl-0">
            Usage
          </v-card-title>
          <v-row>
            <v-col>
              <!-- todo: link to codepen -->
              <!-- todo: figure out an efficient way to generate formatted code in html -->
              <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import </span><span style="color:#800080; ">{</span> AppBar <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; "> @nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
              <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-appbar-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-appbar-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-appbar-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">AppBar</span></td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-appbar-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">notApp</span></td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-appbar-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">color</span>="<span class="pl-s">primary</span>"</td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-appbar-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">appName</span>="<span class="pl-s">my-ads-demo</span>"</td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-appbar-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">title</span>="<span class="pl-s">application design system demo</span>"</td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                  <td
id="file-appbar-html-LC7"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:searchFilterMutator</span>="<span class="pl-s">searchFilterMutator</span>"</td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L8"
class="blob-num js-line-number"
data-line-number="8"
/>
                  <td
id="file-appbar-html-LC8"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:showFullScreen</span>="<span class="pl-s">showFullScreen</span>"</td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-appbar-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:showNavIcon</span>="<span class="pl-s">showNavIcon</span>"</td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L10"
class="blob-num js-line-number"
data-line-number="10"
/>
                  <td
id="file-appbar-html-LC10"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-appbar-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">AppBar</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-appbar-html-L16"
class="blob-num js-line-number"
data-line-number="16"
/>
                  <td
id="file-appbar-html-LC16"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
            </v-col>
          </v-row>
        </v-card>

        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />

        <SimpleTableCard
          title="Slots"
          :headers="cSlots.headers"
          :data="cSlots.data"
        />

        <br>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'Usage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [
            {
              name: 'color',
              type: 'String',           
              desc: 'The title displayed in the app bar'
            },
            {
              name: 'enableHomeLink',
              type: 'Boolean',            
              desc: 'Show/Hide the link for accessing the home page, (defaults to <span class="code">true</span>)'
            },
            {
              name: 'extended',
              type: 'Boolean',            
              desc: 'Show/Hide an extension slot within the component, (defaults to <span class="code">false</span>)'
            },
            {
              name: 'homeLink',
              type: 'String',            
              desc: 'The URL for the home link, (defaults to <span class="code">/</span>)'
            },
            {
              name: 'searchURL',
              type: 'String',            
              desc: 'The URL for the search API to be used, (defaults to an empty string)'
            },
            {
              name: 'showBackButton',
              type: 'Boolean',            
              desc: 'Show/Hide the back button, (defaults to <span class="code">false</span>)'
            },
            {
              name: 'showFullScreen',
              type: 'Boolean',            
              desc: 'Show/Hide the full screen toggle, (defaults to <span class="code">false</span>)'
            }, 
            {
              name: 'showNavIcon',
              type: 'Boolean',            
              desc: 'Show/Hide the navigation hamburger toggle, (defaults to <span class="code">true</span>)'
            },
            {
              name: 'showNotification',
              type: 'Boolean',            
              desc: 'Show/Hide the notification icon, (defaults to <span class="code">false</span>)'
            },
            {
              name: 'showSearch',
              type: 'Boolean',            
              desc: 'Show/Hide search functionality, (defaults to <span class="code">false</span>)'
            },
            {
              name: 'title',
              type: 'String',           
              desc: 'The title displayed in the app bar'
            }
          ]
        },
        cSlots: {
          headers: ['Name', 'Desc'],
          data: [
            {
              name: 'appIcon',
              desc: 'The application icon'
            }, {
              name: 'title',
              desc: 'The title text'
            }, {
              name: 'dataSwitcher',
              desc: 'The search bar'
            }, {
              name: 'myEssentials',
              desc: 'The icon to launch other myEssentials applications'
            }, {
              name: 'profile',
              desc: 'The right profile icon'
            }
          ]
        }
      }
    }
  }
</script>