<template>
  <v-row>
    <v-col cols="9">
      <h2 class="mt-6">
        Demo
      </h2>

      <v-card
        outlined
        class="pa-6"
      >
        <v-container>
          <v-container>
            <v-row
              align="center"
              justify="center"
            >
              <v-col>
                <v-row justify="center">
                  <FeatureIcon
                    :size="size"
                    :icon-size="computedIconSize"
                    :color="selectedIconColour"
                    :background-color="selectedIconBackgroundColour"
                    :icon="selectedIconType"
                  >
                    <template
                      v-if="isUseIconContent"
                      v-slot:icon
                    >
                      {{ iconContent }}
                    </template>
                  </FeatureIcon>
                </v-row>
              </v-col>
              <v-col>
                <h3>Icon</h3>
                <v-autocomplete
                  v-model="selectedIconType"
                  :items="iconTypes"
                />

                <h3 class="mt-3">
                  Size ({{ size }} x {{ size }})
                </h3>
                <v-slider
                  v-model="size"
                  class="align-center"
                  :max="80"
                  :min="20"
                  :step="1"
                  hide-details
                />

                <v-switch
                  v-model="isUseIconSize"
                  label="Use icon size"
                  hide-details
                >
                  <template slot="label">
                    <h3>Icon Size ({{ iconSize }} x {{ iconSize }})</h3>
                  </template>
                </v-switch>
                <v-slider
                  v-model="iconSize"
                  class="align-center"
                  :max="50"
                  :min="12"
                  :step="1"
                  hide-details
                />

                <v-autocomplete
                  v-model="selectedIconColour"
                  class="mt-6"
                  label="Colour"
                  placeholder=" "
                  outlined
                  :items="iconColours"
                />

                <v-autocomplete
                  v-model="selectedIconBackgroundColour"
                  label="Background colour"
                  placeholder=" "
                  outlined
                  :items="iconColours"
                />

                <v-switch
                  v-model="isUseIconContent"
                  label="Use text content"
                  class="mt-0"
                />
                <v-text-field
                  v-model="iconContent"
                  label="Icon text content"
                  placeholder=" "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { FeatureIcon } from '@nswdoe/doe-ui-core';
export default {
  name: 'Demo',
  components: {
    FeatureIcon,
  },
  data() {
    return {
      size: 48,
      isUseIconSize: false,
      iconSize: 32,
      iconColours: [
        '#041E42',
        'red',
        'blue',
        'orange',
        'white',
        'black',
      ],
      iconBackgroundColours: [
        'red',
        'blue',
        'orange',
        'black',
        '#C8DCF0',
      ],
      iconTypes: [
        'mdi-account-hard-hat',
        'mdi-alert-octagram-outline',
        'mdi-alert-rhombus',
        'mdi-alpha-d-box-outline',
        'mdi-alpha-f-circle',
        'mdi-alpha-o-box-outline',
        'mdi-arrange-send-to-back',
        'mdi-arrow-bottom-left-thin-circle-outline',
        'mdi-arrow-left-box',
        'mdi-arrow-up-bold-circle',
        'mdi-axis',
        'mdi-baby-buggy',
        'mdi-book-play-outline',
        'mdi-calendar-account',
        'mdi-calendar-remove-outline',
        'mdi-case-sensitive-alt',
        'mdi-chevron-right-box',
        'mdi-clipboard-play-multiple',
        'mdi-clipboard-plus',
        'mdi-clock-time-nine',
        'mdi-clock-time-two-outline',
        'mdi-cloud-lock',
        'mdi-coach-lamp',
        'mdi-contacts',
        'mdi-contain-start',
        'mdi-cookie-plus-outline',
        'mdi-cup',
        'mdi-currency-usd-circle',
        'mdi-dishwasher-off',
        'mdi-division-box',
        'mdi-dock-right',
        'mdi-dresser-outline',
        'mdi-email-open-outline',
        'mdi-fan-alert',
        'mdi-file-excel-outline',
        'mdi-folder-settings-outline',
        'mdi-format-font-size-increase',
        'mdi-format-list-numbered-rtl',
        'mdi-gesture-pinch',
        'mdi-google-nearby',
        'mdi-hail',
        'mdi-head-dots-horizontal',
        'mdi-head-question-outline',
        'mdi-help-network-outline',
        'mdi-hinduism',
        'mdi-home-circle-outline',
        'mdi-home-floor-a',
        'mdi-import',
        'mdi-inbox-full-outline',
        'mdi-lamp',
        'mdi-lava-lamp',
        'mdi-lock-alert-outline',
        'mdi-lock-open-variant',
        'mdi-lock-remove',
        'mdi-lotion-outline',
        'mdi-map-search',
        'mdi-metronome',
        'mdi-microphone-off',
        'mdi-movie-off-outline',
        'mdi-odnoklassniki',
        'mdi-pail-plus-outline',
        'mdi-pen-minus',
        'mdi-phone-minus-outline',
        'mdi-plus-box-multiple',
        'mdi-radio',
        'mdi-radiology-box-outline',
        'mdi-ray-start-end',
        'mdi-relation-many-to-zero-or-one',
        'mdi-relation-zero-or-many-to-one-or-many',
        'mdi-reload',
        'mdi-sausage',
        'mdi-server-remove',
        'mdi-shield-star-outline',
        'mdi-shoe-print',
        'mdi-sign-direction',
        'mdi-silverware-clean',
        'mdi-sofa-single',
        'mdi-sort-numeric-descending',
        'mdi-star-plus-outline',
        'mdi-sticker-remove',
        'mdi-stomach',
        'mdi-tooltip-outline',
        'mdi-transmission-tower',
        'mdi-tune',
        'mdi-upload-network-outline',
        'mdi-video-off-outline',
        'mdi-view-agenda',
        'mdi-wallet-outline',
        'mdi-weather-sunny',
        'mdi-wifi-cancel',
        'mdi-xamarin',
        'mdi-security',
      ],
      selectedIconColour: '',
      selectedIconBackgroundColour: '',
      selectedIconType: 'mdi-security',
      isUseIconContent: false,
      iconContent: '1',
    };
  },
  computed: {
    computedIconSize() {
      return this.isUseIconSize ? this.iconSize : null;
    },
  },
};
</script>

<style>
.theme--light.v-label {
  color: black;
}
</style>