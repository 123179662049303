<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <p class="pt-5">
          Ordered lists are divided rows of selectable items. It is typically used when users are required to select the
          priority order of various content items. The property values of each item determines how it will displayed in
          the list and how users can interact with them.
        </p>
        <p class="font-weight-bold">
          Properties:
        </p>
        <ul class="propertyList">
          <li><span class="font-weight-bold">Code:</span> A short string to identify the item.</li>
          <li><span class="font-weight-bold">Value:</span> The text for each item.</li>
          <li><span class="font-weight-bold">Locked:</span> The item is disabled and cannot be moved or selected.</li>
          <li><span class="font-weight-bold">Selected:</span> Whether or not the item is ticked.</li>
        </ul>
        <br>
        <p class="textLine">
          In ordered lists, unselected items are moved beneath all selected items.
        </p>
        <p class="textLine">
          If an unselected item is selected, it will be added to the bottom of the selected list.
        </p>
        <p class="textLine">
          List items can be reordered via the arrow icon on the right hand side of each row.
        </p>
        <p class="textLine">
          Locked items cannot be reordered or disabled, and will typically be at the top of the list.
        </p>
        <v-card class="ml-0 mt-4 mb-6 mr-3">
          <div
            class="d-flex justify-center"
            style="background-color: #ffffff"
          >
            <img
              src="@/assets/orderedlist/ordered-list.png"
              class="screenshot"
              alt="Ordered list"
            >
          </div>
        </v-card>

        <v-card class="px-6 py-3 mt-6 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p class="mt-6">
            Component code in Vue component &lt;template&gt;:
          </p>
          <!-- eslint-disable -->
          <!-- HTML generated using hilite.me -->
          <!-- eslint-disable -->
          <div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
<pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;OrderedList</span>
  <span style="color: #0000CC">:items=</span><span>&quot;items&quot;</span>
  <span style="color: #0000CC">:showCheckBoxes</span>
<span style="color: #007700">/&gt;</span>
</pre>
</div>
          <!-- eslint-enable -->
          <p class="mt-6">
            Component code in Vue component &lt;script&gt;:
          </p>
          <!--eslint-disable -->
          <!-- HTML generated using hilite.me -->
<div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
  
<pre>
import { OrderList } from <span style="color: #0000FF">&#39;@nswdoe/doe-ui-core&#39;</span>

export default {
  name: "OrderedListDemo",
  components: {
    OrderedList,
  },
  data() {
    return {
      items: [
        {
          code: 'SIB',
          value: 'Siblings already enrolled at the school',
          locked:  true,
          selected:  null
        },
        {
          code: 'PRX',
          value: 'Proximity and access to the school',
          locked:  false,
          selected: null
        },
        {
          code: 'SSX',
          value: 'Access to single-sex education',
          locked: false,
          selected: null
        },
        {
          code: 'MED',
          value: 'Medical reasons',
          locked: false,
          selected: null
        },
        {
          code: 'SFT',
          value: 'Safety and supervision of the student before and after school',
          locked: true,
          selected: null
        },
        {
          code: 'AVL',
          value: 'Availability of subjects or combinations of subjects',
          locked: false,
          selected: null
        },
        {
          code: 'CMP',
          value: 'Compassionate circumstances',
          locked: false,
          selected: null
        },
        {
          code: 'ORG',
          value: 'Structure and organisation of the school',
          locked: false,
          selected: null
        },
        {
          code: 'INT',
          value: 'Recent change in the local intake area boundaries',
          locked: false,
          selected: null
        }
      ]
    }
  }
}
    </pre>
 
</div>
          <!--eslint-disable -->
</v-card>
        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';

export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [{
          name: 'items',
          type: 'Array',
          desc: 'An array of orderable objects. </br> Default structure if \'showCheckboxes\' is true: {code: string, value: string, locked: boolean, selected: boolean}.</br> Default structure if \'showCheckboxes\' is false: {code: string, value: string, locked: boolean}. ',
        },
          {
            name: 'showCheckboxes',
            type: 'Boolean',
            desc: 'Whether items are selectable and re-orderable or only re-orderable',
          }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.textLine {
  margin-bottom: 0px !important;
}

.propertyList {
  list-style: none;
  padding-left: 0;
}

code {
  display: block;
  color: #333333;
  background: #ebe8e8;
  padding: .5rem 1.5rem;

  pre{
     color: #333333!important;
  }
}
</style>
