<template>
  <v-container fluid>
    <Banner
      heading="Resources"
      subtitle="Everything you need to start building a project with ADS"
      content-class="pl-0"
    />
    <v-container fluid>
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-tabs>
            <v-container class="d-flex">
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
                :href="`#tab-${tab.key}`"
              >
                {{ tab.title }}
              </v-tab>
            </v-container>

            <v-tab-item
              value="tab-designers"
              class="content pl-0 pa-6 pt-12 pb-12"
            >
              <Designers />
            </v-tab-item>
            <v-tab-item
              value="tab-developers"
              class="content pl-0 pa-6 pt-12 pb-12"
            >
              <Developers />
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner';
import Designers from './Designers';
import Developers from './Developers';

export default {
  name: 'Styles',
  components: {
    Banner,
    Designers,
    Developers
  },
  data() {
    return {
      tabs: [
        {
          title: 'Designers',
          key: 'designers'
         },
        {
          title: 'Developers',
          key: 'developers'
        }
      ]
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__wrapper,
.v-slide-group__wrapper .v-card {
  .v-card__text,.v-tab,
    .v-tab--active {
    color: #121212;
  }
  .v-tabs-slider-wrapper {
    border-bottom: 4px solid #ce0037;
  }
  .v-tab--active,.v-tab--active .v-card .v-card__text {
    font-weight: bold;
  }
  
}
</style>
