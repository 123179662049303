<template>
  <div>
    <v-row>
      <v-col cols="9">
        <v-container>
          <h2 class="mt-6 mb-2">
            Demo
          </h2>
          <v-card
            outlined
            class="pa-5"
          >
            <v-row class="pa-5 d-flex justify-center">
              <v-switch
                v-model="multiple"
                label="Display one expansion-panel at a time"
              />
            </v-row>
            <v-row class="pa-5 d-flex justify-center">
              <AdsExpansionPanel
                v-if="multiple"
                :items="items"
                multiple
              />
              <AdsExpansionPanel
                v-else
                :items="items"
              />
            </v-row>
          </v-card>
          <h2 class="mt-6 mb-2">
            Demo for Slot replacement for slot 1
          </h2>
          <v-card
            outlined
            class="pa-5"
          >
            <v-row class="pa-5 d-flex justify-center">
              <v-switch
                v-model="multiple"
                label="Display one expansion-panel at a time"
              />
            </v-row>
            <v-row class="pa-5 d-flex justify-center">
              <AdsExpansionPanel
                v-if="multiple"
                :items="items"
                multiple
              >
                <template slot="content12345">
                  <v-text-field
                    placeholder="input field"
                    class="ma-5 mb-0 pb-0"
                    outlined
                  />
                  <v-btn class="primary ma-5 mt-0">
                    Button
                  </v-btn>
                </template>
              </AdsExpansionPanel>
              <AdsExpansionPanel
                v-else
                :items="items"
              >
                <template slot="content12345">
                  <v-text-field
                    placeholder="input field"
                    class="ma-5 mb-0 pb-0"
                    outlined
                  />
                  <v-btn class="primary ma-5 mt-0">
                    Button
                  </v-btn>
                </template>
              </AdsExpansionPanel>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
 import { AdsExpansionPanel } from '@nswdoe/doe-ui-core';
 export default {
  name: 'Demo',
  components: {
    AdsExpansionPanel
  },
  data() {
    return {
      multiple: true,
      items:[
        {id:12345, title:'Section1', icon:'account_circle', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras euismod, nunc sit amet tincidunt fermentum, augue mi volutpat lacus, vel lacinia nunc tortor eget urna. Fusce nec diam leo. Duis nec ipsum id sapien faucibus vestibulum.' },
        {id:12346, title:'Section2', icon:'help_outline', content:'Contents for section 2' },
        {id:12347, title:'Section3', icon:'assignment_returned', content:'Contents for section 3' },
        {id:12348, title:'Section4', icon:'book', content:'Contents for section 4' },
        {id:12349, title:'Section5', icon:'', content:'Contents for section 5' }
      ]
    }
  }
};
</script>

