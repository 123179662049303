import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {ADS_Colors} from '@nswdoe/doe-ui-core';  // use pre-defined ADS colors constants

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: ADS_Colors.Navy,
        secondary: ADS_Colors.Light_Blue,
        accent: ADS_Colors.Blue_1,
        success: ADS_Colors.Blue_2,
        error: ADS_Colors.Error_Red
      }
    }
  }
});
