<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <p class="pt-5">
          A standard tab navigation can be called by using the <code>&lt;TabNavigation&gt;</code> tag, with a reference to the tab items and content to be displayed.
        </p>

        <v-row class="pa-5 d-flex justify-center">
          <TabNavigation
            :vertical="vertical"
            :items="toggleItems?items:items.slice(0,6)"
            :center-active="centerActive"
            :fixed-tabs="fixedTabs"
            :icons-and-text="iconsAndText"
            :icons-only="iconsOnly"
            :next-icon="nextIcon"
            :prev-icon="prevIcon"
            :right="right"
            :text-transform="textTransform"
            :hide-slider="hideSlider"
            class="make-900px"
          />
        </v-row>

        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="vertical"
            label="Show tab navigation in vertical format"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="toggleItems"
            label="Toggle between six and twelve items (Twelve items displays arrows)"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="centerActive"
            label="Make the active tab always centered ('center-active') - not implemented in vertical tabs yet"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="fixedTabs"
            label="Fill out tabs to 300px max. ('fixed-tabs') - not implemented in vertical tabs yet"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="iconsAndText"
            label="Display icons with text ('icons-and-text') - not implemented in vertical tabs yet"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="iconsOnly"
            label="Display icons only ('icons-only', overrides 'icons-and-text')"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="right"
            label="Right align tabs ('right', works best in six items display) - not implemented in vertical tabs yet"
          />
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-switch
            v-model="hideSlider"
            label="Hide's the generated v-tabs-slider ('hide-slider')"
          />
        </v-row>
        <v-row class="pt-3 pa-0 ma-0">
          <AdsSelect
            v-model="defaultArrowType"
            item-value="arrowType"
            :items="['Default','Arrows','Triangles']"
            label="Previous and Next Icon Style ('next-icon' and 'prev-icon')"
            @change="changeArrows"
          />
        </v-row>
        <v-row class="pt-3 pa-0 ma-0">
          <AdsSelect
            v-model="textTransform"
            :items="['none','uppercase','lowercase']"
            label="Tab label case"
          />
        </v-row>

        <v-card
          style="overflow:auto;"
          class="pl-6 mt-6"
        >
          <v-row style="position: relative; left: -12px;">
            <v-col>
              <b>Code</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <pre>
                <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                  <tbody><tr>
                    <td
id="tabnavigation-script-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                    <td
id="tabnavigation-script-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">script</span><span class="pl-kos">&gt;</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                    <td
id="tabnavigation-script-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-ent">export default</span> {</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                    <td
id="tabnavigation-script-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">name</span>: "<span class="pl-s">Demo</span>",</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                    <td
id="tabnavigation-script-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">data</span>() {</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                    <td
id="tabnavigation-script-LC5"
class="blob-code blob-code-inner js-file-line"
>      <span class="pl-c1">return</span> {</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                    <td
id="tabnavigation-script-LC6"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">centerActive</span>: <span class="pl-s">{{ centerActive }},</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                    <td
id="tabnavigation-script-LC7"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">fixedTabs</span>: <span class="pl-s">{{ fixedTabs }},</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L8"
class="blob-num js-line-number"
data-line-number="8"
/>
                    <td
id="tabnavigation-script-LC8"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">iconsAndText</span>: <span class="pl-s">{{ iconsAndText }},</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                    <td
id="tabnavigation-script-LC9"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">iconsOnly</span>: <span class="pl-s">{{ iconsOnly }},</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L10"
class="blob-num js-line-number"
data-line-number="10"
/>
                    <td
id="tabnavigation-script-LC10"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">nextIcon</span>: <span class="pl-s">"{{ nextIcon }}",</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                    <td
id="tabnavigation-script-LC11"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">prevIcon</span>: <span class="pl-s">"{{ prevIcon }}",</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                    <td
id="tabnavigation-script-LC12"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">right</span>: <span class="pl-s">{{ right }},</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                    <td
id="tabnavigation-script-LC13"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-c1">items</span>: <span class="pl-s">[</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L14"
class="blob-num js-line-number"
data-line-number="14"
/>
                    <td
id="tabnavigation-script-LC14"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-kos">{</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L15"
class="blob-num js-line-number"
data-line-number="15"
/>
                    <td
id="tabnavigation-script-LC15"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">tab</span>: '<span class="pl-s">Favourites</span>',</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L16"
class="blob-num js-line-number"
data-line-number="16"
/>
                    <td
id="tabnavigation-script-LC16"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">content</span>: '<span class="pl-s">&lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit.&lt;/p&gt;</span>',</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L17"
class="blob-num js-line-number"
data-line-number="17"
/>
                    <td
id="tabnavigation-script-LC17"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">icon</span>: '<span class="pl-s">favorite</span>'</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L18"
class="blob-num js-line-number"
data-line-number="18"
/>
                    <td
id="tabnavigation-script-LC18"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-kos">},</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L19"
class="blob-num js-line-number"
data-line-number="19"
/>
                    <td
id="tabnavigation-script-LC19"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-kos">{</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L20"
class="blob-num js-line-number"
data-line-number="20"
/>
                    <td
id="tabnavigation-script-LC20"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">tab</span>: '<span class="pl-s">Contacts</span>',</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L21"
class="blob-num js-line-number"
data-line-number="21"
/>
                    <td
id="tabnavigation-script-LC21"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">content</span>: '<span class="pl-s">&lt;p&gt;Nobis aliter videtur, recte secusne, postea.&lt;/p&gt;</span>',</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L22"
class="blob-num js-line-number"
data-line-number="22"
/>
                    <td
id="tabnavigation-script-LC22"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">icon</span>: '<span class="pl-s">phone</span>'</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L23"
class="blob-num js-line-number"
data-line-number="23"
/>
                    <td
id="tabnavigation-script-LC23"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-kos">},</span></td>
                  </tr>

                  <tr>
                    <td
id="tabnavigation-script-L24"
class="blob-num js-line-number"
data-line-number="24"
/>
                    <td
id="tabnavigation-script-LC24"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-kos">{</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L25"
class="blob-num js-line-number"
data-line-number="25"
/>
                    <td
id="tabnavigation-script-LC25"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">tab</span>: '<span class="pl-s">Users</span>',</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L26"
class="blob-num js-line-number"
data-line-number="26"
/>
                    <td
id="tabnavigation-script-LC26"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">content</span>: '<span class="pl-s">&lt;p&gt;Quae sequuntur igitur? Ita nemo beato beatior.&lt;/p&gt;</span>',</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L27"
class="blob-num js-line-number"
data-line-number="27"
/>
                    <td
id="tabnavigation-script-LC27"
class="blob-code blob-code-inner js-file-line"
>            <span class="pl-c1">icon</span>: '<span class="pl-s">account_box</span>'</td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L28"
class="blob-num js-line-number"
data-line-number="28"
/>
                    <td
id="tabnavigation-script-LC28"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-kos">}</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L29"
class="blob-num js-line-number"
data-line-number="29"
/>
                    <td
id="tabnavigation-script-LC29"
class="blob-code blob-code-inner js-file-line"
>          <span class="pl-s">// ...<i>Remaining items</i></span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L30"
class="blob-num js-line-number"
data-line-number="30"
/>
                    <td
id="tabnavigation-script-LC30"
class="blob-code blob-code-inner js-file-line"
>        <span class="pl-s">]</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L31"
class="blob-num js-line-number"
data-line-number="31"
/>
                    <td
id="tabnavigation-script-LC31"
class="blob-code blob-code-inner js-file-line"
>      <span class="pl-kos">}</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L32"
class="blob-num js-line-number"
data-line-number="32"
/>
                    <td
id="tabnavigation-script-LC32"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-kos">}</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L33"
class="blob-num js-line-number"
data-line-number="33"
/>
                    <td
id="tabnavigation-script-LC33"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">}</span></td>
                  </tr>
                  <tr>
                    <td
id="tabnavigation-script-L34"
class="blob-num js-line-number"
data-line-number="34"
/>
                    <td
id="tabnavigation-script-LC34"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">script</span><span class="pl-kos">&gt;</span></td>
                  </tr>
                 </tbody>
                </table>
              </pre>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
  import { AdsSelect, TabNavigation } from '@nswdoe/doe-ui-core';

  export default {
    name: 'Demo',
    components: {
      TabNavigation,
      AdsSelect
    },
    data() {
      return {
        vertical: false,
        toggleItems: false,
        centerActive: false,
        fixedTabs: false,
        iconsAndText: false,
        iconsOnly: false,
        arrowStyle: 'Default',
        nextIcon: 'keyboard_arrow_right',
        prevIcon: 'keyboard_arrow_left',
        right: false,
        hideSlider: false,
        textTransform: 'uppercase',
        defaultArrowType: {
          arrowType: 'Default'
        },
        items: [
          {
            tab: 'Favourites',
            content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>',
            icon: 'favorite'
          },
          {
            tab: 'Contacts',
            content: '<p>Nobis aliter videtur, recte secusne, postea.</p>',
            icon: 'phone'
          },
          {
            tab: 'Users',
            content: '<p>Quae sequuntur igitur? Ita nemo beato beatior.</p>',
            icon: 'account_box'
          },
          {
            tab: 'History',
            content: '<p>Nosti, credo, illud: Nemo pius est, qui pietatem.</p>',
            icon: 'history'
          },
          {
            tab: 'File',
            content: '<p>Multoque hoc melius nos veriusque quam Stoici.</p>',
            icon: 'description'
          },
          {
            tab: 'Search',
            content: '<p>Sin laboramus, quis est, qui alienae modum statuat industriae?</p>',
            icon: 'search'
          },
          {
            tab: 'Bluetooth',
            content: '<p>Nam et complectitur verbis, quod vult, et dicit plane, quod intellegam.</p>',
            icon: 'bluetooth'
          },
          {
            tab: 'Alarm',
            content: '<p>Hosne igitur laudas et hanc eorum, inquam, sententiam sequinos censes oportere?</p>',
            icon: 'alarm'
          },
          {
            tab: 'Calendar',
            content: '<p>Quid ergo attinet gloriose loqui, nisi constanter loquare?</p>',
            icon: 'today'
          },
          {
            tab: 'Feedback',
            content: '<p>Nam quid possumus facere melius?</p>',
            icon: 'feedback'
          },
          {
            tab: 'Delete',
            content: '<p>Ut aliquid scire se gaudeant?</p>',
            icon: 'delete'
          },
          {
            tab: 'Home',
            content: '<p>Qui autem esse poteris, nisi te amor ipse ceperit?</p>',
            icon: 'home'
          }
        ],
        items2: [
          {
            tab: 'One',
            content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>'
          },
          {
            tab: 'Two',
            content: '<p><i>Nobis aliter videtur, recte secusne, postea.</i></p>'
          },
          {
            tab: 'Three',
            content: '<p>Quae sequuntur igitur? <b>Ita nemo beato beatior.</b></p>'
          },
          {
            tab: 'Four',
            content: '<p>Nosti, credo, illud: Nemo pius est, qui pietatem.</p>'
          },
          {
            tab: 'Five',
            content: '<p>Multoque hoc melius nos veriusque quam Stoici.</p>',
          },
          {
            tab: 'Six',
            content: '<p>Sin laboramus, quis est, qui alienae modum statuat industriae?</p>',
          }
        ]
      }
    },
    methods:{
      changeArrows: function(value) {
        // alert(value);
        switch(value) {
          case 'Default':
            this.nextIcon = 'keyboard_arrow_right';
            this.prevIcon = 'keyboard_arrow_left';
            break;
          case 'Arrows':
            this.nextIcon = 'arrow_forward';
            this.prevIcon = 'arrow_back';
            break;
          case 'Triangles':
            this.nextIcon = 'arrow_right';
            this.prevIcon = 'arrow_left';
            break;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .v-tabs.v-tab-navigation {
    width: 100% !important;
  }

  .make-900px {
    width: 900px !important;
  }

  .pl-ent {
    color: #22863a;
  }

  .pl-c1,
  .pl-s .pl-v {
    color: #005cc5;
  }

  .pl-pds,
  .pl-s,
  .pl-s .pl-pse .pl-s1,
  .pl-sr,
  .pl-sr .pl-cce,
  .pl-sr .pl-sra,
  .pl-sr .pl-sre {
    color: #032f62;
  }

  ::v-deep .v-tab-navigation-vertical .v-window__container {
    padding-left: 0;
  }
</style>
