<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo In-Page Alert
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <Alert
                :show-alert="showAlertMessage"
                :type="type"
                :allow-dismiss="allowDismiss"
                text="This is the content in the alert."
                :subtext="showOptional ? 'This is optional text': null"
                :html-content="showOptional ? 'This is the html content for in page...<a href=\'google.com\'>google</a>': null"
                :action-text="showOptional ? 'Optional Action' : null"
                :action-handler="showOptional ? actionHandler: null"
                in-page
                :border="border"
                :backgroundColour="backgroundColour"
              />
            </v-row>
            <v-row
              class="pa-0"
              justify="space-around"
            >
              <v-col cols="4">
                <v-select
                  dense
                  solo
                  :items="['Right','Bottom','Top','Left']"
                  label="Border Position"
                  @change="changeBorder"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  dense
                  solo
                  :items="alertTypes"
                  label="Type"
                  @change="changeAlert"
                />
              </v-col>
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
            <v-col cols="4">
              <v-switch
                v-model="showOptional"
                label="Show/Hide Optional"
              />
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="backgroundColour"
                label="Background Colour"
              />
            </v-col>
            </v-row>
            <v-row 
              class="pa-5"
              justify="space-around">
              <v-col cols="4">
              <v-switch
                v-model="allowDismiss"
                label="Allow Dismiss"
              />
              </v-col>
              <v-col cols="4">
              <v-switch
                v-model="showAlertMessage"
                label="Show/Hide message"
              />
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <h2 class="mt-6">
          Demo Snackbar Alert
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <Alert
                :show-alert="showAlertSnackbar"
                :type="typeSnackbar"
                :allow-dismiss="allowSnackbarDismiss"
                text="This is the content in the alert."
                :subtext="showOptionalSnackbar ? 'This is optional text': null"
                :action-text="showOptionalSnackbar ? 'Optional Action' : null"
                :action-handler="showOptionalSnackbar ? actionHandler: null"
                :persist-between-routes="persistBetweenRoutes"
                :position="position"
              />
            </v-row>
            <v-row
              class="pa-0"
              justify="space-around"
            >
              <v-col cols="4">
                <v-select
                  dense
                  solo
                  :items="['Right','Bottom','Top','Left','Top-Right','Top-Left','Bottom-Right','Bottom-Left']"
                  label="Position"
                  @change="changePosition"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  dense
                  solo
                  :items="alertTypes"
                  label="Type"
                  @change="changeAlertSnackbar"
                />
              </v-col>
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="allowSnackbarDismiss"
                label="Allow Dismiss"
              />
              <v-switch
                v-model="showOptionalSnackbar"
                label="Show/Hide Optional"
              />
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="showAlertSnackbar"
                label="Show/Hide message"
              />
              <v-switch
                v-model="persistBetweenRoutes"
                :disabled="persistBetweenRoutes"
                label="Persistancy"
              />
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { Alert } from '@nswdoe/doe-ui-core';
 export default {
  name: 'Demo',
  components: {
    Alert
  },
  data() {
    return {
      inPage: true,
      showOptional: true,
      showOptionalSnackbar: true,
      persistBetweenRoutes: false,
      allowDismiss: true,
      allowSnackbarDismiss:true,
      showAlertMessage: true,
      showAlertSnackbar: true,
      backgroundColour: false,
      border:'left',
      position:'bottom-right',
      alertTypes:[{text:'Info', value:'info'},{text:'Success',value:'success'},{text:'Error',value:'error'},{text:'Warning',value:'warning'}],
      type:'info',
      typeSnackbar:'info'
    }
  },
  methods:{
    actionHandler: function() {
      alert('optional action triggered');
    },
    changeAlert: function(value) {
      this.type = value;
    },
    changeAlertSnackbar: function(value) {

      this.typeSnackbar = value;
    },
    changeBorder: function(value) {
       let border = value.toLowerCase();
       this.border = border;
    },
    changePosition: function(value) {
       let position = value.toLowerCase();
       this.position = position;
    },
    showAlert() {
      this.show = true;
    }
  }
};
</script>
