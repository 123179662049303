<template>
  <v-container fluid>
    <Banner
      heading="ADS Component Design Patterns"
      subtitle="ADS Developer's Guide"
      contentClass="pl-0"
    />
    <v-container fluid>
      <div class="d-flex flex-wrap pa-12">
        <Markdown />
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner';
import Markdown from './ADS-component-design-patterns.md';

export default {
  name: 'AdsDesignPatterns',
  components: {
    Banner,
    Markdown
  },
};
</script>

<style lang="scss" scoped>

</style>
