import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {IeBlocker} from '@nswdoe/doe-ui-core';
import api from './api';
import { ActivityMonitor } from '@nswdoe/doe-ui-core';
import VueYouTubeEmbed from 'vue-youtube-embed';

// Enhance the event system on Vue.prototype
import {useEventHub, useUpwardBroadcast, useDownwardBroadcast} from './hooks/events';
useEventHub(Vue);
useUpwardBroadcast(Vue);
useDownwardBroadcast(Vue);

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

let renderComponent, renderProps;

if (IeBlocker.isIe()) { // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'DoE UI Template'
    }
  };
} else { // render App, config the App root instance (oAuth plugin, etc.
  renderComponent = App
  renderProps = {};

  Vue.config.productionTip = false;

  /**
   * Unified interface for API requests
   * Added onto Vue.prototype object, so all Vue component instances can access it via `this.$api`
   */
  Vue.prototype.$api = api;

  Vue.use(VueYouTubeEmbed);

  Vue.use(ActivityMonitor, {
    events: ['keydown', 'mousedown'],
    inactivityTimeout: 30000,
    throttlerTimeout: 1000,
    onInactiveUser: () => {
      store.dispatch('beginFinalInactivityTimer')
    }
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(renderComponent, renderProps)
}).$mount('#app');
