<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <div class="mt-2">
          <p>
            The skeleton loader is a temporary animated visual indicator for users. It is designed to show that content
            is in the process of loading, and it disappears as soon as the content is available.
          </p>
          <p>
            The loader uses light grey shapes to create an abstract indication of the content that will load - the
            shapes animate in a loop via a gradient “shine” effect until the content is ready to be displayed.
          </p>
          <p>
            Skeleton loaders can be used in multiple configurations to represent different types of dynamic data. Two
            main examples are shown below - for row-based content or grid based content
          </p>
        </div>

        <h2 class="mt-6">
          Examples
        </h2>
        <p class="font-weight-bold">
          Skeleton loader rows (for lists & data tables)
        </p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center">
            <img src="../../../assets/skeletonloader/skeleton-data-table.png">
          </v-col>
        </v-card>
        <p class="font-weight-bold">
          Skeleton loader cards (for grid layouts)
        </p>
        <v-card>
          <v-row no-gutters>
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center"
              md="4"
            >
              <img src="../../../assets/skeletonloader/skeleton-card.png">
            </v-col>
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center"
              md="4"
            >
              <img src="../../../assets/skeletonloader/skeleton-card.png">
            </v-col>
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center"
              md="4"
            >
              <img src="../../../assets/skeletonloader/skeleton-card.png">
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center"
              md="4"
            >
              <img src="../../../assets/skeletonloader/skeleton-card.png">
            </v-col>
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center"
              md="4"
            >
              <img src="../../../assets/skeletonloader/skeleton-card.png">
            </v-col>
            <v-col
              class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center"
              md="4"
            >
              <img src="../../../assets/skeletonloader/skeleton-card.png">
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <!--            <pre style='color:#000000;background:#ffffff;'><span style='color:#800000; font-weight:bold; '>import</span> <span-->
            <!--              style='color:#800080; '>{</span> AdsTextField, AdsSelect, AdsFileInput <span style='color:#800080; '>}</span> from <span-->
            <!--              style='color:#800000; '>"</span><span style='color:#0000e6; '>@nswdoe/doe-ui-core</span><span style='color:#800000; '>";</span></pre>-->
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody>
                <tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span
                    class="pl-kos"
>&lt;</span><span class="pl-ent">v-container</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span
                    class="pl-ent"
>v-skeleton-loader</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">class</span>=<span
                    class="pl-s"
>"mx-auto"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">max-width</span>=<span
                    class="pl-s"
>"300"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L5"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">type</span>=<span
                    class="pl-s"
>"card"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span
                    class="pl-ent"
>v-skeleton-loader</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span
                    class="pl-ent"
>v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-card-text>
          <p style="color: #121212;">
            For component properties please refer to the Vuetify Skeleton Loader document:
          </p>
          <p>
            <a
              href="https://vuetifyjs.com/en/components/skeleton-loaders/"
              target="_blank"
            >Vuetify Skeleton Loader
              Properties</a>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Usage'
}
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}

.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
