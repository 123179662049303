<template>
  <v-card class="my-6">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-subtitle>
      <slot name="subtitle">
        <div v-html="subtitle" />
      </slot>
    </v-card-subtitle>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th 
              v-for="(h, i) in headers"
              :key="i"
              class="text-left"
            >
              {{ h }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(d, i) in data"
            :key="i"
          >
            <td
              v-for="(k, i) in objKeys"
              :key="i"
              v-html="d[k]"
            />
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
String.prototype.toCamelCase = function () {
  return this.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

String.prototype.stripNonAlphaOrSpace = function() {
  return this.replace(/[^a-z ]/gi, '');
}

export default {
  name: 'SimpleTableCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    keys: {
      type: Array,
      default: null
    }
  },
  data() {
    // use keys prop or convert headers to keys
    const objKeys = this.keys || this.headers.map(h => h.stripNonAlphaOrSpace().toCamelCase());
    return {
      objKeys
    }
  }
}
</script>

<style scoped>
</style>