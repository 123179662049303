<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <ul>
          <li>
            Skeleton loader (Vuetify Skeleton loaders wrapper)
          </li>
        </ul>
        <v-row class="ml-0 mt-4 mb-6">
          <img
            src="@/assets/loader/loader.png"
            class="screenshot"
            alt="Loader"
          >
        </v-row>

        <v-card class="code-card px-6 py-3 mt-6 mr-3">
          <v-card-title class="pl-0">
            Usage
          </v-card-title>
          <p class="mt-6">
            Component code in Vue component &lt;template&gt;:
          </p>
<!-- eslint-disable -->
<div style="color: #333333; font-family: Menlo, Monaco, 'Courier New', monospace;font-weight: normal;font-size: 12px;line-height: 18px;white-space: pre;"><span style="color: #91b3e0;">&lt;</span><span style="color: #4b69c6;">Loader </span><span style="color: #8190a0;font-style: italic;">type</span><span style="color: #91b3e0;">=</span><span style="color: #777777;">&quot;</span><span style="color: #448c27;">list-item-two-line</span>&quot; <span style="color: #8190a0;font-style: italic;">tile</span><span style="color: #91b3e0;">=</span><span style="color: #777777;">&quot;</span><span style="color: #448c27;">true</span>&quot; <span style="color: #8190a0;font-style: italic;">boilerplate</span><span style="color: #91b3e0;">=</span><span style="color: #777777;">&quot;</span><span style="color: #448c27;">true</span><span style="color: #777777;">&quot; </span><span style="color: #91b3e0;">/&gt;</span></div>
          <!-- eslint-enable -->
          <br>
        </v-card>
        
        <SimpleTableCard
          title="Component properties"
          :headers="cProps.headers"
          :data="cProps.data"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
  import SimpleTableCard from '@/components/SimpleTableCard';
  export default {
    name: 'AppIconUsage',
    components: {
      SimpleTableCard
    },
    data() {
      return {
        cProps: {
          headers: ['Name', 'Type', 'Desc'],
          data: [{
            name: 'type',
            type: 'String',           
            desc: 'A string delimited list of skeleton components to create such as type="text@3" or type="card, list-item"'
          }, {
            name: 'types',
            type: 'Object',           
            desc: 'A custom types object that will be combined with the pre-defined options'
          }, {
            name: 'tile',
            type: 'Boolean',            
            desc: 'Tile or rounded border UI'
          }, {
            name: 'boilerplate',
            type: 'Boolean',
            desc: 'Remove loading animation from the skeleton'
          }, {
            name: 'elevation',
            type: 'Number | Boolean',
            desc: 'Designates an elevation applied to the component between 0 and 24'
          }, {
          }, {
            name: 'width',
            type: 'Number | String',
            desc: 'Sets the width'
          }, {
            name: 'height',
            type: 'Number | String',
            desc: 'Sets the height'
          }, {
            name: 'dark',
            type: 'Boolean',
            desc: 'Applies the dark theme'
          }, {
            name: 'light',
            type: 'Boolean',
            desc: 'Applies the light theme'
          }, {
            name: 'loading',
            type: 'Boolean',
            desc: 'Applies a loading animation with a on-hover loading cursor'
          }]
        }
      }
    }
  }
</script>