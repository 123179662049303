const apiData = [
  {
    'id': 65880999901,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-amu',
    'url': 'https://education.nsw.gov.au/curriculum/multicultural-education/interpreting-and-translations',
    'description': 'amu',
    'title': 'amu'
  },
  {
    'id': 65880899902,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-hns-esafety',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'Esafety',
    'title': 'Esafety'
  },
  {
    'id': 65880899903,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-learning-systems-support',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'Support',
    'title': 'Support'
  },
  {
    'id': 65880899904,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-az-policy',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'AZ Policy',
    'title': 'AZ Policy'
  },
  {
    'id': 65880899905,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-bi',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'BI',
    'title': 'BI'
  },
  {
    'id': 65880899906,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-plasst',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'Plasst',
    'title': 'Plasst'
  },
  {
    'id': 65880899907,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-bloged',
    'url': 'https://online.det.nsw.edu.au/bloged',
    'description': 'Bloged',
    'title': 'Bloged'
  },
  {
    'id': 65880899908,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-rmu',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'AMU',
    'title': 'AMU'
  },
  {
    'id': 65880899909,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-sap',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'SAP',
    'title': 'SAP'
  },
  {
    'id': 65880899910,
    'category': {
      'ids': ['244617', '4336540', '6397522'],
      'titles': ['webifier', 'my applications', 'my essentials']
    },
    'dates': {
      'created': 1518741815555,
      'display': 978267600000,
      'expiration': 1518741815555,
      'modified': 1521006362108,
      'published': 978267600000
    },
    'contact': {
      'email': 'noone@example.com',
      'phone': '0112345678',
      'name': 'test employee'
    },
    'icon': 'my-buzz',
    'url': 'https://online.det.nsw.edu.au/amu',
    'description': 'Buzz',
    'title': 'Buzz'
  },
  // {
  //   "id": 65880899911,
  //   "category": {
  //     "ids": ["244617", "4336540", "6397522"],
  //     "titles": ["webifier", "my applications", "my essentials"]
  //   },
  //   "dates": {
  //     "created": 1518741815555,
  //     "display": 978267600000,
  //     "expiration": 1518741815555,
  //     "modified": 1521006362108,
  //     "published": 978267600000
  //   },
  //   "contact": {
  //     "email": "noone@example.com",
  //     "phone": "0112345678",
  //     "name": "test employee"
  //   },
  //   "icon": "my-oasis",
  //   "url": "https://online.det.nsw.edu.au/amu",
  //   "description": "Oasis",
  //   "title": "Oasis"
  // },
  // {
  //   "id": 65880899912,
  //   "category": {
  //     "ids": ["244617", "4336540", "6397522"],
  //     "titles": ["webifier", "my applications", "my essentials"]
  //   },
  //   "dates": {
  //     "created": 1518741815555,
  //     "display": 978267600000,
  //     "expiration": 1518741815555,
  //     "modified": 1521006362108,
  //     "published": 978267600000
  //   },
  //   "contact": {
  //     "email": "noone@example.com",
  //     "phone": "0112345678",
  //     "name": "test employee"
  //   },
  //   "icon": "my-password",
  //   "url": "https://online.det.nsw.edu.au/amu",
  //   "description": "Password",
  //   "title": "Password"
  // },
];

export default apiData;
