<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-container>
        <h2 class="mt-6">
          Demo single type without loading animation
        </h2>
        <v-card
          outlined
          :dark="transparent"
          class="mr-3"
        >
          <div class="mt-6 ml-6">
            <div class="d-flex justify-center">
              <Loader
                type="list-item-three-line"
                :tile="true"
                :boilerplate="boilerplate"
                width="600"
              />
            </div>
          </div>
        </v-card>
        <h2 class="mt-6">
          Demo multiple types with loading animation
        </h2>
        <v-btn
          class="my-4"
          @click="toggleLoading"
        >
          Toggle loading
        </v-btn>
        <v-card
          outlined
          :dark="transparent"
          class="mr-3"
        >
          <div class="mt-6 ml-6">
            <div class="d-flex justify-center">
              <Loader
                :type="type"
                :types="types"
                :tile="true"
                width="600"
                :loading="loading"
              >
                <Alert
                  :in-page="true"
                  :type="alertType"
                  text="Success"
                  :subtext="showOptional ? 'Content loads up successfully.': null"
                />
              </Loader>
            </div>
          </div>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { Loader, Alert } from '@nswdoe/doe-ui-core';

export default {
  name: 'LoaderDemo',
  components: {
    Loader,
    Alert
  },
  data() {
    return {
      boilerplate: true,
      type: 'skeleton',
      loading: true,
      types: { skeleton: 'article, actions' },
      inPage: true,
      showOptional: true,
      alertType:'success',
    };
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
