<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>

        <p class="mt-6">
          In the demo below, you'll be able to tell that a focus trap is active because it will turn pink. 
          You should also be able to tell because it will trap your focus.
        </p>
        
        <p class="mt-10">
          You can activate/deactivate the trap by toggling the switch by the side.
        </p>

        <v-container>
          <v-row
            class="pa-5"
            justify="space-around"
          >
            <v-card
              outlined
              :class="activated ? 'is-active' : ''"
            >
              <FocusTrap
                class="mt-4"
                :is-active="activated"
                :element-to-focus-on-stop="refs.elementToFocusOn"
                :return-focus-on-stop="true"
              >
                <p class="px-6 pt-1">
                  Here is a focus trap
                  <a href="#">with</a>&nbsp;
                  <a href="#">some</a>&nbsp;
                  <a href="#">focusable</a>&nbsp;
                  parts.
                </p>
              </FocusTrap>
            </v-card>
              
            <v-switch
              v-model="activated"
              label="Focus Trap OFF/ON"
            />
          </v-row>

          <p class="mt-10">
            Toggle the swtich below to enable/disable the element to focus on stop.
          </p>

          <v-row
            justify="space-around"
            align="center"
          >
            <v-card
              outlined
              class="pa-5 mb-10 mt-6"
              :class="focusOnStopActivated ? 'is-enabled' : 'is-disabled'"
            >
              <a
                id="elementToFocusOn"
                ref="elementToFocusOn"
                href="#"
                class="white--text"
              >
                Focus on me when trap is deactivated
              </a>
            </v-card>

            <v-switch
              v-model="focusOnStopActivated"
              label="Element OFF/ON"
            />
          </v-row>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { FocusTrap } from '@nswdoe/doe-ui-core';
 export default {
  name: 'Demo',
  components: {
    FocusTrap
  },
  data() {
    return {
      activated: false,
      focusOnStopActivated: false,
    }
  },
  computed: {
    refs: function() {
      return this.focusOnStopActivated ? this.$refs : {};
    }
  }
};
</script>

<style scoped>
  a {
    text-decoration: none;
  }

  a:focus {
    outline: 3px solid #CE0037;
  }

  .v-card.v-sheet.is-active {
    background: #F3B8B5;
  }

  .v-card.v-sheet.is-enabled {
    background: #69B32D;
  }

  .v-card.v-sheet.is-disabled {
    background: #E4E4E6;
  }
</style>

