export default {
  expansionPanels: {
    text: 'Expansion panels',
    href: '/components/expansionpanel'
  },
  appBar: {
    text: 'App bar',
    href: '/components/appbars'
  },
  cards: {
    text: 'Cards',
    href: ''
  },
  dataSwitcher: {
    text: 'Data switcher',
    href: '/components/dataswitcher'
  },
  dataTable: {
    text: 'Data table',
    href: '/components/datatable'
  },
  dialogs: {
    text: 'Dialog',
    href: '/components/dialogs'
  },
  filterChips: {
    text: 'Filter chips',
    href: '/components/chips'
  },
  formElements: {
    text: 'Form elements',
    href: ''
  },
  ieBlocker: {
    text: 'IE Blocker',
    href: '/components/ieblocker'
  },
  inPageAlerts: {
    text: 'In page alerts',
    href: '/components/alerts'
  },
  inputsAndControls: {
    text: 'Inputs & controls',
    href: ''
  },
  navigationDrawer: {
    text: 'Navigation drawer',
    href: '/components/navigationdrawer'
  },
  pageHeader: {
    text: 'Page header',
    href: '/components/pageheader'
  },
  progressStepper: {
    text: 'Progress stepper',
    href: '/components/stepper'
  },
  tabNavigation: {
    text: 'Tab navigation',
    href: ''
  }
};
