<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <AdsButton
                :disabled="disabled || loading"
                :icon="icon"
                :small="compact"
                button-text="Button click"
                :tertiary="tertiary"
                :ripple="ripple"
                :secondary="secondary"
                :loading="loading"
                @click="clickButton"
              />
            </v-row>
            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="secondary"
                label="Secondary"
                @change="secondaryButton"
              />
              <v-switch
                v-model="tertiary"
                label="Tertiary"
                @change="tertiaryButton"
              />
              <v-switch
                v-model="showIcon"
                label="Icon"
                @change="showIconButton"
              />
            </v-row>

            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="disabled"
                label="Disabled"
              />
              <v-switch
                v-model="compact"
                label="Compact"
              />
              <v-switch
                v-model="ripple"
                label="Ripple Effect"
              />
            </v-row>

            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="loading"
                label="Loading"
              />
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
 import { AdsButton } from '@nswdoe/doe-ui-core';
 export default {
  name: 'Demo',
  components: {
    AdsButton
  },
  data() {
    return {
      showIcon: false,
      secondary: false,
      tertiary: false,
      disabled: false,
      compact: false,
      ripple: true,
      loading: false,
      icon: null
    }
  },
  methods:{
    secondaryButton() {
      this.tertiary = false;
      return this.secondary;
    },
    tertiaryButton() {
      this.secondary = false;
      return this.tertiary;
    },
    clickButton() {
      alert('button clicked');
    },
    showIconButton() {
      this.icon = null;
      if (this.showIcon){
        this.icon = 'add_circle_outline';
      }
    }
  }
};
</script>
