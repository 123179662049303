<template>
  <v-container>
    <div>
      <h2>Grid system</h2>
      <p>Use a 12 column grid for all content below the app bar. Gutter should always be 24px.</p>
    </div>
    <div style="background-color: #FFFFFF; padding: 40px 40px 25px 40px; max-width: 750px;">
      <img
        src="../../assets/guidelines/gridSystem.png"
        width="100%"
        height="100%"
      >
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'GridSystem'
  }
</script>

<style lang="scss" scoped>
</style>
