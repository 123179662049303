<template>
  <div>
    <v-row>
      <v-col cols="9">
        <v-container>
          <h2 class="mt-6 mb-2">
            Demo
          </h2>
          <p class="pt-5">
            If your browser is Internet Explorer, the default version of the IE Blocker will be displayed below. In this example, the <span class="code">is-demo</span> property is set to <span class="code">true</span>, allowing a demo version of the default IE blocker to be displayed if the browser is not Internet Explorer. When the <span class="code">is-demo</span> property is set to <span class="code">true</span>, a <span class="no-break">"[DEMO]"</span> heading will be displayed.
          </p>
          <!-- v-card outlined class="pa-5" -->
          <v-row class="pa-5 d-flex justify-center">
            <IeBlocker
              is-demo
              block
            />
          </v-row>
          <!-- /v-card -->
          <p class="pt-5">
            If your browser is Internet Explorer, the default version of the IE Blocker in-page alert will be displayed below. In this example, the <span class="code">is-demo</span> property is set to <span class="code">true</span>, allowing a demo version of the default IE blocker to be displayed if the browser is not Internet Explorer. When the <span class="code">is-demo</span> property is set to <span class="code">true</span>, a <span class="no-break">"[DEMO]"</span> heading will be displayed.
          </p>
          <!-- <v-card outlined class="pa-5"> -->
          <v-row class="pa-5 d-flex justify-center">
            <IeBlocker
              is-demo
              inline
            />
          </v-row>
        <!-- </v-card> -->
        </v-container>
      </v-col>
    </v-row>
  </div>
</template> 
<script>
import { IeBlocker } from '@nswdoe/doe-ui-core';
export default {
  name: 'Demo',
  components: {
    IeBlocker
  },
    data() {
      return {
        isDemo: true,
        block: true,
        inline: true
      }
    }
};
</script>

<style>
.code {
  font-weight: 700;
  font-family: "Courier New";
  font-size: inherit;
  white-space: nowrap;
}
.no-break {
  white-space: nowrap;
}
.ie-blocker--card {
    max-width: unset !important;
}
.ie-blocker--container {
  width: auto !important;
  height: auto !important;
  position: static !important;
  padding: 24px !important;
}
.ie-blocker--container > .v-application--wrap {
  min-height: auto;
}
</style>