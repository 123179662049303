<template>
  <v-container
    fluid
  >
    <Banner
      heading="Data switcher"
      subtitle="Switch between different views of the same or similar information within an app."
      content-class="pl-0"
    />
    <v-container fluid>
      <v-row justify="center">
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-tabs>
            <v-container class="d-flex">
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
                :href="`#tab-${tab.key}`"
              >
                {{ tab.title }}
              </v-tab>
            </v-container>
            
            <v-tab-item value="tab-usage">
              <Usage />
            </v-tab-item>
            <v-tab-item value="tab-demo">
              <Demo />
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import Banner from '../../../components/Banner';
  import Usage from './Usage';
  import Demo from './Demo';

  export default {
    name: 'AppIcons',
    components: {
      Banner,
      Usage,
      Demo
    },
    data() {
      return {
        tabs: [
          {
            title: 'Usage',
            key: 'usage'
          },
          {
            title: 'Demo',
            key: 'demo'
          }
        ]
      }
    },
    methods: {
    }
  }
</script>
