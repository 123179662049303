<template>
  <v-container>
    <h2>Figma required</h2>
    <div class="d-flex align-center mb-6">
      <p class="mb-0">
        ADS uses Figma as its design, prototyping and development handover tool.
      </p>
      <v-spacer />
      <v-btn
        class="primary mr-5"
        href="https://www.figma.com/downloads/"
        target="_blank"
      >
        Get Figma
      </v-btn>
    </div>
    <hr>
    <ResourceCard
      title="ADS - Web Apps"
      href="https://www.figma.com/community/file/1008913654223541218"
    >
      <img
        slot="image"
        src="@/assets/resources/ads-web-apps.png"
        alt="ads web apps icon"
      >
    </ResourceCard>

    <ResourceCard
      title="Material Icons (Outlined)"
      href="https://www.figma.com/file/y7n4UmFNmjO5E6K2lgKbPk/Material-Icons-(Outlined)"
    >
      <img
        slot="image"
        src="@/assets/resources/material-icons.png"
        alt="material icons"
        style="width: 152px"
      >
    </ResourceCard>

    <ResourceCard
      title="ADS - iOS Apps"
      href="https://www.figma.com/file/zDBjirThuz0k2GJ8P5ypqo/ADS---IOS-UI-Kit?node-id=29%3A0"
    >
      <img
        slot="image"
        src="@/assets/resources/ads-ios-apps.png"
        alt="ads ios apps icon"
      >
    </ResourceCard>

    <ResourceCard
      title="Vuetify"
      href="https://vuetifyjs.com/"
    >
      <img
        slot="image"
        src="@/assets/resources/vuetify.png"
        alt="Vuetify icon"
      >
    </ResourceCard>

    <ResourceCard
      title="Material Design"
      href="https://material.io"
    >
      <img
        slot="image"
        src="@/assets/resources/material-dark.png"
        alt="Material.io logo"
      >
    </ResourceCard>

    <ResourceCard
      title="Figma"
      href="https://www.figma.com/"
    >
      <img
        slot="image"
        src="@/assets/resources/figma.png"
        alt="Figma logo"
        style="width: 152px"
      >
    </ResourceCard>
  </v-container>
</template>

<script>
import ResourceCard from '@/components/ResourceCard';
export default {
  name: 'Designers',
  components: {
    ResourceCard
  },
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style scoped>
</style>
