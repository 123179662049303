<template>
  <v-row>
    <v-col cols="9">
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span
              style="color:#800080; "
>{</span> Weather <span style="color:#800080; ">}</span> from <span
              style="color:#800000; "
>"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody>
                <tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">Weather</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">appId</span>=<span class="pl-s">"caed0cabe816a8810fcf0d37e4ba311a"</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent" /><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>


                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>appId</td>
                <td>String</td>
                <td>
                  Specify the appId for the API call to the Weather API (<a
                    href="https://api.openweathermap.org"
                    target="_blank"
                  >https://api.openweathermap.org</a>) in the Weather component
                </td>
              </tr>
              <tr>
                <td>isWhite</td>
                <td>Boolean</td>
                <td>Specify the weather text to be white (default) or black</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Usage'
}
</script>

<style lang="scss" scoped>
.usage {
  font-family: Arial;
  font-style: normal;

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }

  p {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
  }
}

.pl-ent {
  color: #22863a;
}

.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
