<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          ADS dialogs guidelines:
        </h2>
        <ul
          class="mt-2"
          type="1"
        >
          <li>
            The core components of a dialog are a title, body content (whether that is a message or form), a close button and an action button. The icon in front of the title in optional.
          </li>
          <li>
            Dialogs are interruptive, so use them sparingly. They should only be used for critical messages to the user or ones that require a decision.
          </li>
          <li>
            The button to the left of the main action button is to be used for alternative actions. To cancel or close the dialog, use the ‘x’ button in the top right.
          </li>
          <li>
            There are currently two dialog components: <i>Dialog</i> and the newer <i>DialogGeneric</i>. They function the same, the difference is DialogGeneric supports better usage of v-model and the
            Downward
            broadcast component communication pattern (for details, please refer to <a
              href="https://ads.education.nsw.gov.au/developerguide/adsdesignpatterns"
              target="_blank"
            >ADS Component Design
              Patterns</a>.
          </li>
        </ul>

        <v-card class="d-flex mt-4 flex-wrap imgBackground pa-8">
          <v-img
            src="../../../assets/dialogs/group.png"
            class="pr-1 pb-1"
          />
        </v-card>
      </v-container>
      <v-card class="code-card pl-6 mt-6">
        <v-row style="position: relative; left: -12px;">
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            Template:
            <!-- HTML generated using hilite.me --><div style=" overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;Dialog</span>
  <span style="color: #0000CC">:openDialog=</span><span>&quot;openDialog&quot;</span>
  <span style="color: #0000CC">title=</span><span>&quot;Title of modal&quot;</span>
  <span style="color: #0000CC">maxWidth=</span><span>&quot;520px&quot;</span>
  <span style="color: #0000CC">icon=</span><span>&quot;security&quot;</span>
  <span style="color: #0000CC">:displayCloseBtn=</span><span>&quot;true&quot;</span>
  <span style="color: #0000CC">message=</span><span>&quot;This is the content of the message for the dialog.&quot;</span>
  <span style="color: #0000CC">:actions=</span><span>&quot;actions&quot;</span>
  <span style="color: #0000CC">@close=</span><span>&quot;openDialog=false&quot;</span>
  <span style="color: #0000CC">:return-handler=</span><span>&quot;handleDialogReturn&quot;</span>
<span style="color: #007700">/&gt;</span>
</pre>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            Script:
            <!-- HTML generated using hilite.me --><div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;script&gt;</span>
 <span style="color: #008800; font-weight: bold">import</span> { Dialog } from <span style="color: #0000FF">&#39;@nswdoe/doe-ui-core&#39;</span>;

 <span style="color: #008800; font-weight: bold">export</span> <span style="color: #008800; font-weight: bold">default</span> {
  name<span style="color: #333333">:</span> <span>&quot;DialogDemo&quot;</span>,
  components<span style="color: #333333">:</span> {
    Dialog
  },
  data() {
    <span style="color: #008800; font-weight: bold">return</span> {
      actions<span style="color: #333333">:</span> [
        { name<span style="color: #333333">:</span> <span>&quot;Cancel&quot;</span>, color<span style="color: #333333">:</span><span>&quot;#041E42&quot;</span>, size<span style="color: #333333">:</span> <span>&quot;large&quot;</span>, outlined<span style="color: #333333">:</span> <span style="color: #008800; font-weight: bold">true</span> },
        { name<span style="color: #333333">:</span> <span>&quot;Ok&quot;</span>, color<span style="color: #333333">:</span><span>&quot;#041E42&quot;</span>, size<span style="color: #333333">:</span> <span>&quot;large&quot;</span>, outlined<span style="color: #333333">:</span> <span style="color: #008800; font-weight: bold">false</span> }
      ],
      openDialog<span style="color: #333333">:</span> <span style="color: #008800; font-weight: bold">false</span>
    }
  },
  methods<span style="color: #333333">:</span> {
    handleDialogReturn(result) {
      <span style="color: #888888">// result = action.name e.g. &quot;Ok&quot; or &quot;Cancel&quot;</span>
      <span style="color: #008800; font-weight: bold">if</span> (result <span style="color: #333333">===</span> <span>&quot;Ok&quot;</span>) {
        <span style="color: #007020">window</span>.alert(<span>&quot;You clicked Ok&quot;</span>)
      }
      <span style="color: #008800; font-weight: bold">this</span>.openDialog <span style="color: #333333">=</span> <span style="color: #008800; font-weight: bold">false</span>
    }
  }
};
<span style="color: #007700">&lt;/script&gt;</span>
</pre>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <SimpleTableCard
        title="Component properties"
        :headers="cProps.headers"
        :data="cProps.data"
      />

      <SimpleTableCard
        title="Component events"
        :headers="cEvents.headers"
        :data="cEvents.data"
      />

      <SimpleTableCard
        title="Component slots"
        :headers="cSlots.headers"
        :data="cSlots.data"
      />
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';
export default {
  name: 'Usage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      cProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'openDialog',
            type: 'Boolean',
            desc: 'Show/Hide dialog'
          },
          {
            name: 'maxWidth',
            type: 'String',
            desc: 'Maximum width of the dialog. Default "675px"'
          },
          {
            name: 'title',
            type: 'String',
            desc: 'Title of the dialog'
          },
          {
            name: 'icon',
            type: 'String',
            desc: 'Icon prepended to title'
          },
          {
            name: 'displayCloseBtn',
            type: 'Boolean',
            desc: 'Show/Hide close button'
          },
          {
            name: 'message',
            type: 'String',
            desc: 'Message text for the dialog'
          },
          {
            name: 'actions',
            type: 'Array',
            desc: 'Action buttons to render. e.g: [{name: "Ok", color: "white", darkModeColor: "navy", size: "large", outlined: "true"}]'
          },
          {
            name: 'btnClass',
            type: 'String',
            desc: 'Class to append to action buttons'
          },
          {
            name: 'btnHeight',
            type: 'String',
            desc: 'Button height. Default: "50"'
          },
          {
            name: 'returnHandler',
            type: 'Function',
            desc: 'Function that handles action button click. Receives button name as parameter.'
          }
        ]
      },
      cEvents: {
        headers: ['Event', 'Desc'],
        data: [
          {
            event: 'close',
            desc: 'Emitted when close button is clicked on the dialog'
          }
        ]
      },
      cSlots: {
          headers: ['Name', 'Desc'],
          data: [
            {
              name: 'title',
              desc: 'The dialog title'
            },
            {
              name: 'text',
              desc: 'The main dialog text/description'
            },
            {
              name: 'actions',
              desc: 'The action buttons container'
            }
          ]
      }
    }
  }
}
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
