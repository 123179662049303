<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <AdsTextField
                v-model="title"
                :disabled="disabled"
                :dense="dense"
                :readonly="readonly"
                :rules="rules"
                counter="20"
                hint="This field uses counter prop"
                label="Regular"
                :error="error"
                placeholder="Placeholder"
              />
            </v-row>

            <v-row class="pa-5 d-flex justify-center">
              <AdsTextArea
                v-model="description"
                :disabled="disabled"
                :dense="dense"
                :readonly="readonly"
                :rules="rules"
                label="Regular"
                rows="4"
                row-height="20"
                counter="20"
                hint="This field uses counter prop"
                :error="error"
                placeholder="Placeholder"
              />
            </v-row>

            <v-row class="pa-5 d-flex justify-center">
              <AdsSelect
                :disabled="disabled"
                :dense="dense"
                :readonly="readonly"
                :items="items"
                label="Standard"
                :error="error"
                placeholder="Selection"
              />
            </v-row>

            <v-row class="pt-5 mb-n5 px-5 d-flex justify-center">
              <AdsSelect
                multiple
                :chips="chips"
                :deletable-chips="deletableChips"
                :disabled="disabled"
                :dense="dense"
                :readonly="readonly"
                :items="items"
                label="Multiple"
                :error="error"
                placeholder="Selection"
              />
            </v-row>

            <v-row
              class="mt-n5 pb-5 px-5"
              justify="space-around"
            >
              <v-switch
                v-model="chips"
                label="Changes display of selections to chips"
              />
              <v-switch
                v-model="deletableChips"
                label="Adds a remove icon to selected chips"
              />
            </v-row>

            <v-row class="pa-5 d-flex justify-center">
              <AdsFileInput
                :disabled="disabled"
                :dense="dense"
                :error="error"
                label="Attachments"
                placeholder="Select your files"
              />
            </v-row>

            <v-row
              class="pa-5"
              justify="space-around"
            >
              <v-switch
                v-model="disabled"
                label="Disabled"
              />
              <v-switch
                v-model="dense"
                label="Dense"
              />
              <v-switch
                v-model="readonly"
                label="Readonly"
              />
              <v-switch
                v-model="error"
                label="Error"
              />
            </v-row>

            <v-card-title>
              <p class="body-1">
                Also, the <strong>Date Picker</strong> component can be viewed
                <router-link to="/components/datepickers">
                  here
                </router-link>
                .
              </p>
            </v-card-title>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {
  AdsTextArea,
  AdsTextField,
  AdsSelect,
  AdsFileInput
} from '@nswdoe/doe-ui-core';

export default {
  name: 'Demo',
  components: {
    AdsTextArea,
    AdsTextField,
    AdsSelect,
    AdsFileInput
  },
  data() {
    return {
      disabled: false,
      dense: false,
      readonly: false,
      error: false,

      title: 'Preliminary report',
      description: 'Lorem ipsum dolor si',
      rules: [v => v.length <= 20 || 'Max 20 characters'],

      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],

      chips: false,
      deletableChips: false
    };
  }
};
</script>
