<template>
  <v-row class="py-5">
    <v-col
      cols="12"
      md="10"
    >
      <h3 class="mb-3">
        Props
      </h3>
      <v-switch
        v-model="showCheckboxes"
        class="mt-0"
        label="showCheckboxes"
      />
      <OrderedList
        v-if="showCheckboxes"
        :key="componentKey"
        :items="selectableItems"
        show-checkboxes
      />
      <OrderedList
        v-else
        :key="componentKey"
        :items="items"
      />
    </v-col>
  </v-row>
</template>

<script>
import { OrderedList } from '@nswdoe/doe-ui-core';

function clone(object) {
  if (!object) {
    return object;
  } else if (Array.isArray(object)) {
    return object.map(item => clone(item));
  } else if (typeof object === 'object') {
    const result = {};
    Object.entries(object).forEach(([key, value]) => {
      result[key] = clone(value);
    })
    return result;
  }
  return object;
}

const selectableItems = [
  {
    code: 'SIB',
    value: 'Siblings already enrolled at the school',
    locked: true,
    selected: null
  },
  {
    code: 'PRX',
    value: 'Proximity and access to the school',
    locked: false,
    selected: null
  },
  {
    code: 'SSX',
    value: 'Access to single-sex education',
    locked: false,
    selected: null
  },
  {
    code: 'MED',
    value: 'Medical reasons',
    locked: false,
    selected: null
  },
  {
    code: 'SFT',
    value: 'Safety and supervision of the student before and after school',
    locked: true,
    selected: null
  },
  {
    code: 'AVL',
    value: 'Availability of subjects or combinations of subjects',
    locked: false,
    selected: null
  },
  {
    code: 'CMP',
    value: 'Compassionate circumstances',
    locked: false,
    selected: null
  },
  {
    code: 'ORG',
    value: 'Structure and organisation of the school',
    locked: false,
    selected: null
  },
  {
    code: 'INT',
    value: 'Recent change in the local intake area boundaries',
    locked: false,
    selected: null
  }
]

const items = [
  {
    code: 'SIB',
    value: 'Siblings already enrolled at the school',
    locked: true,
  },
  {
    code: 'PRX',
    value: 'Proximity and access to the school',
    locked: false,
  },
  {
    code: 'SSX',
    value: 'Access to single-sex education',
    locked: false,
  },
  {
    code: 'MED',
    value: 'Medical reasons',
    locked: false,
  },
  {
    code: 'SFT',
    value: 'Safety and supervision of the student before and after school',
    locked: true,
  },
  {
    code: 'AVL',
    value: 'Availability of subjects or combinations of subjects',
    locked: false,
  },
  {
    code: 'CMP',
    value: 'Compassionate circumstances',
    locked: false,
  },
  {
    code: 'ORG',
    value: 'Structure and organisation of the school',
    locked: false,
  },
  {
    code: 'INT',
    value: 'Recent change in the local intake area boundaries',
    locked: false,
  }
]

export default {
  name: 'Demo',
  components: {
    OrderedList,
  },
  data() {
    return {
      componentKey: 0,
      showCheckboxes: true,
      selectableItems: clone(selectableItems),
      items: clone(items)
    }
  },
  watch: {
    showCheckboxes: function () {
      this.selectableItems = clone(selectableItems)
      this.items = clone(items)
      this.forceRerender()
    }
  },
  methods: {
    forceRerender: function () {
      this.componentKey += 1;
    }
    }
  };
</script>

<style scoped lang="scss">
</style>
