<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-container>
        <h2 class="mt-6">
          Date Picker Demo
        </h2>
        <v-card
          outlined
          class="mr-3"
        >
          <div class="mt-6 ml-6">
            <div class="mx-auto">
              <div style="width: 300px">
                <DatePicker
                  v-model="singleDate"
                  label="Select Date"
                  :date-format="dateFormat"
                  :output-date-format="outputFormat"
                  :allow-future="allowFuture"
                  :allow-past="allowPast"
                  :allow-today="allowToday"
                  :year-picker="yearPicker"
                  @date-picker-activated="v => singleDatePickerActive = v"
                />
              </div>
              <div class="mb-3">
                Output value: {{ singleDate }}
              </div>
              <div class="mb-3">
                Picker active: {{ singleDatePickerActive }}
              </div>
            </div>
            <v-divider />
            
            <div class="d-flex mb-3">
              <v-switch
                v-model="allowFuture"
                label="Allow future dates"
                class="mr-6"
              />
              <v-switch
                v-model="allowPast"
                label="Allow past dates"
                class="mr-6"
              />
              <v-switch
                v-model="allowToday"
                label="Allow today"
                class="mr-6"
              />
              <v-switch
                v-model="yearPicker"
                label="Year picker"
                class="mr-6"
              />
            </div>
            <v-row>
              <v-col
                cols="8"
                md="6"
                lg="5"
                xl="4"
              >
                <div class="mb-3">
                  <v-select
                    v-model="dateFormat"
                    :items="formats"
                    label="Display Date Format"
                    outlined
                    placeholder=" "
                    hint="Change the date to see the format applied"
                    persistent-hint
                  />
                </div>
                <div class="mb-3">
                  <v-select
                    v-model="outputFormat"
                    :items="formats"
                    label="Output Date Format"
                    outlined
                    placeholder=" "
                    hint="Change the date to see the format applied"
                    persistent-hint
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <h2 class="mt-6">
          Date Range Demo
        </h2>
        <v-card
          outlined
          class="mr-3"
        >
          <div class="mt-6 ml-6">
            <div class="mx-auto">
              <div style="width: 350px">
                <DatePickerRange
                  v-model="rangeDate"
                  :vertical="rangeVertical"
                  @date-picker-activated="v => rangeDatePickerActive = v"
                />
              </div>
              <div class="mb-3">
                <v-btn
                  @click="rangeDate = []"
                >
                  Clear range
                </v-btn>
              </div>
              <div class="mb-3">
                Output value: {{ rangeDate }}
              </div>
              <div class="mb-3">
                Picker active: {{ rangeDatePickerActive }}
              </div>
            </div>
            <v-divider />
            
            <div class="d-flex mb-3">
              <v-switch
                v-model="rangeVertical"
                label="Vertical"
                class="mr-6"
              />
            </div>
          </div>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { DatePicker, DatePickerRange } from '@nswdoe/doe-ui-core';

export default {
  name: 'DatePickerDemo',
  components: {
    DatePicker,
    DatePickerRange
  },
  data() {
    return {
      singleDate: '2020-10-10',
      singleDatePickerActive: false,
      dateFormat: 'DD/MM/YYYY',
      outputFormat: 'YYYY-MM-DD',
      yearPicker: false,
      allowFuture: true,
      allowPast: true,
      allowToday: true,
      formats: [
        'YYYY-MM-DD',
        'DD/MM/YYYY',
        'DD-MM-YYYY',
        'DD MMM YYYY'
      ],
      rangeDate: ['2020-01-01', '2020-02-01'],
      rangeVertical: false,
      rangeDatePickerActive: false
    }
  }
};
</script>

<style scoped lang="scss">
</style>
