<template>
  <v-row>
    <v-col cols="9">
      <h3 class="mt-6">
        Status chip
      </h3>
      <p>
        They are primarily used in data tables to convey the status of an object
        but can they also be used in lists or cards if appropriate. Status chips
        are not actionable and not to be used as buttons.
      </p>
      <v-card>
        <v-col class="pa-5 imgBackground d-flex justify-center">
          <img src="../../../assets/chips/Chips.png">
        </v-col>
      </v-card>
      <h3 class="mt-6">
        Filter chip
      </h3>
      <p>
        These are dropdowns styled to look like chips. The functionality is the
        same as that on
        <a
          style="text-decoration:none;"
          href="http://flights.google.com"
          target="_blank"
        >Google Flights.</a>
      </p>
      <v-card>
        <v-col class="pa-9 imgBackground d-flex justify-center">
          <img src="../../../assets/chips/Filter.png">
        </v-col>
      </v-card>
      <h3 class="mt-6">
        In selects
      </h3>
      <p>Selects can use chips to display the selected data.</p>
      <v-card>
        <v-col class="pa-5 imgBackground d-flex justify-center">
          <img src="../../../assets/chips/Select.png">
        </v-col>
      </v-card>
      <h3 class="mt-6">
        Tags
      </h3>
      <p>Tags help to categorise content and can be clickable or read-only.</p>
      <v-card>
        <v-col class="pa-9 imagesList d-flex justify-center">
          <div class="mr-5">
            <img src="../../../assets/chips/Tag.png">
          </div>
          <div>
            <img src="../../../assets/chips/Story.png">
          </div>
        </v-col>
      </v-card>
      <h3 class="mt-6">
        Chip
      </h3>
      <v-card class="px-6 mt-3">
        <v-row>
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- HTML generated using hilite.me --><div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%">import { Chip } from &quot;@nswdoe/doe-ui-core&quot;;

<span style="color: #007700">&lt;Chip</span>
  <span style="color: #0000CC">text=</span><span>&quot;Chip Text&quot;</span>
  <span style="color: #0000CC">color=</span><span>&quot;primary&quot;</span>
  <span style="color: #0000CC">close</span>
<span style="color: #007700">/&gt;</span>

</pre>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <SimpleTableCard
        title="Chip component properties"
        subtitle="Attributes and event listeners are passed through to the underlying <a href=&quot;https://vuetifyjs.com/en/components/chips/&quot;>v-chip</a> component."
        :headers="chipProps.headers"
        :data="chipProps.data"
      />
      <h3 class="mt-6">
        Chip Filter
      </h3>
      <v-card class="px-6 mt-3">
        <v-row>
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            Template:
            <!-- HTML generated using hilite.me --><div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%">
<span style="color: #007700">&lt;ChipFilter</span>
  <span style="color: #0000CC">v-model=</span><span>&quot;selectedOperatingSystems&quot;</span>
  <span style="color: #0000CC">name=</span><span>&quot;Operating system&quot;</span>
  <span style="color: #0000CC">type=</span><span>&quot;checkbox&quot;</span>
  <span style="color: #0000CC">:items=</span><span>&quot;operatingSystems&quot;</span>
<span style="color: #007700">/&gt;</span>

</pre>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Script:
            <!-- HTML generated using hilite.me --><div style="overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;">
              <pre style="margin: 0; line-height: 125%"><span style="color: #008800; font-weight: bold">import</span> { ChipFilter } from <span>&#39;@nswdoe/doe-ui-core&#39;</span>;

<span style="color: #008800; font-weight: bold">export</span> <span style="color: #008800; font-weight: bold">default</span> {
  name<span style="color: #333333">:</span> <span>&quot;ChipsDemo&quot;</span>,
  components<span style="color: #333333">:</span> {
    ChipFilter
  },
  data() {
    <span style="color: #008800; font-weight: bold">return</span> {
      operatingSystems<span style="color: #333333">:</span> [
        {
          id<span style="color: #333333">:</span> <span>&quot;MS&quot;</span>,
          name<span style="color: #333333">:</span> <span>&quot;Microsoft Windows&quot;</span>
        },
        {
          id<span style="color: #333333">:</span> <span>&quot;LIN&quot;</span>,
          name<span style="color: #333333">:</span> <span>&quot;Linux&quot;</span>
        },
        {
          id<span style="color: #333333">:</span> <span>&quot;MAC&quot;</span>,
          name<span style="color: #333333">:</span> <span>&quot;Mac Os&quot;</span>
        },
        {
          id<span style="color: #333333">:</span> <span>&quot;AND&quot;</span>,
          name<span style="color: #333333">:</span> <span>&quot;Android&quot;</span>
        },
        {
          id<span style="color: #333333">:</span> <span>&quot;UNI&quot;</span>,
          name<span style="color: #333333">:</span> <span>&quot;Unix&quot;</span>
        }
      ],
      selectedOperatingSystems<span style="color: #333333">:</span> []
    };
  }
}
</pre>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <SimpleTableCard
        title="Chip Filter component properties"
        subtitle="Attributes and event listeners are passed through to the underlying <a href=&quot;https://vuetifyjs.com/en/components/chips/&quot;>v-chip</a> menu activator."
        :headers="chipFilterProps.headers"
        :data="chipFilterProps.data"
      />
    </v-col>
  </v-row>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard'
export default {
  name: 'ChipsUsage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      chipProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'chipClass',
            type: 'String',
            desc: 'Custom class for styling chip.'
          },
          {
            name: 'color',
            type: 'String',
            desc: 'Colour for the chip background.'
          },
          {
            name: 'label',
            type: 'Boolean',
            desc: 'For removing the rounded corners. (Default to False)'
          },
          {
            name: 'text',
            type: 'String',
            desc: 'Text to dispay in Chip.'
          },
          {
            name: 'outlined',
            type: 'Boolean',
            desc: 'Outlined chip with border. (Default to True)'
          },
          {
            name: 'small',
            type: 'Boolean',
            desc: 'Small size for the chip. (Default to false)'
          },
          {
            name: 'close',
            type: 'Boolean',
            desc: 'Close icon at the end of chip. (Default to false)'
          },
          {
            name: 'disabled',
            type: 'Boolean',
            desc: 'Disable a Chip for interactions. (Default to false)'
          },
          {
            name: 'filter',
            type: 'Boolean',
            desc: 'Show icons for filters. (Default to false)'
          },
          {
            name: 'filterIcon',
            type: 'String',
            desc: 'Filter icon for Chip.'
          },
          {
            name: 'large',
            type: 'Boolean',
            desc: 'Set large size for Chip. (Default to false)'
          },
          {
            name: 'ripple',
            type: 'Boolean',
            desc: 'Enable/Disable ripple for Chip. (Default to false)'
          },
          {
            name: 'textColor',
            type: 'String',
            desc: 'Custom text color for Chip.'
          },
          {
            name: 'closeIcon',
            type: 'String',
            desc: 'Custom close icon for Chip.'
          },
          {
            name: 'avatar',
            type: 'Boolean',
            desc: 'Circular icon before chip text.'
          },
          {
            name: 'avatarIcon',
            type: 'String',
            desc: 'Icon to show for avatar.'
          }
        ]
      },
      chipFilterProps: {
        headers: ['Name', 'Type', 'Desc'],
        data: [
          {
            name: 'value',
            type: 'Array, String, Number',
            desc: 'The selected filter value(s).'
          },
          {
            name: 'name',
            type: 'String',
            desc: 'Text to display on the filter chip.'
          },
          {
            name: 'heading',
            type: 'String',
            desc: 'Title to display in filter menu. Default "Show only".'
          },
          {
            name: 'items',
            type: 'Array',
            desc: 'The filter options. Each option object must contain "id" and "name" properties. If multi-level prop is active, it can also contain "children" property.'
          },
          {
            name: 'type',
            type: 'String',
            desc: 'Filter type. Accepts "checkbox" and "radio". Default "checkbox".'
          },
          {
            name: 'disabled',
            type: 'Boolean',
            desc: 'Disable the filter chip.'
          },
          {
            name: 'clearLink',
            type: 'Boolean',
            desc: 'Add clear link button at the bottom of the chip filter menu.'
          },
          {
            name: 'clearLinkText',
            type: 'String',
            desc: "The text displayed in the chip filter menu's clear button. Default \"Clear filter\"."
          },
          {
            name: 'alignRight',
            type: 'Boolean',
            desc: 'Align menu towards the right.'
          },
          {
            name: 'minWidth',
            type: 'Number',
            desc: 'Filter menu minimum width. Default "280".'
          },
          {
            name: 'maxHeight',
            type: 'Number',
            desc: 'Filter menu maximum height. Default "480".'
          },
          {
            name: 'multiLevel',
            type: 'Boolean',
            desc: 'Allows items to contain children. It must be associated with type "checkbox".'
          },
          {
            name: 'selectableParent',
            type: 'Boolean',
            desc: 'When multi-level property is active, makes the parent items selectable. Default "true".'
          },
          {
            name: 'hideArrows',
            type: 'Boolean',
            desc: 'When multi-level property is active, hides the arrow indicator of expandable content.'
          },
          {
            name: 'mandatory',
            type: 'Boolean',
            desc: 'Forces a value to always be selected (if available). It must be associated with type "radio".'
          },
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
::v-deep .imgBackground {
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .imagesList {
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
