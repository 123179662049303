<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-container>
        <h2 class="mt-6">
          About ADS navigation drawer
        </h2>
        <ul
          class="mt-2"
          type="1"
        >
          <li>The drawer can be shown and hidden by clicking on the hamburger menu icon in the app bar.</li>
          <li>Each top level menu item has an icon and a label.</li>
          <li>The mobile version has the account settings menu at the top.</li>
          <li>Menu items containing sub pages have a chevron to the right to expand the sub menu.</li>
          <li>Pages in a sub menu do not have icons.</li>
        </ul>

        <div class="mt-10">
          <div class="headline mb-3">
            Desktop
          </div>
          <img
            src="@/assets/navigationdrawer/desktop.png"
            class="screenshot"
            alt="navigation menus on desktop"
          >

          <div class="headline mt-10 mb-3">
            Mobile
          </div>
          <img
            src="@/assets/navigationdrawer/mobile.png"
            class="screenshot"
            alt="navigation menus on mobile"
          >

          <div class="headline mt-10 mb-3">
            States
          </div>
          <img
            src="@/assets/navigationdrawer/states.png"
            class="screenshot"
            alt="navigation menu states"
          >
        </div>
      </v-container>
      <v-card class="pl-6 mt-6 mr-3">
        <v-card-title class="pl-0">
          Usage
        </v-card-title>

        <v-card-subtitle class="pl-0">
          The Navigation Drawer is built on top of <a href="https://vuetifyjs.com/en/components/navigation-drawers/#navigation-drawers">vuetify's navigation drawer</a>. All properties are passed through to the underlying v-navigation-drawer component.
        </v-card-subtitle>

        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- generated via https://wordtohtml.net/ -->
            <code>
              <span>import</span><span><span>&nbsp;{&nbsp;</span><span>NavigationDrawer</span><span>&nbsp;}&nbsp;</span><span>from</span><span style="color: #000000;">&nbsp;</span><span style="color: #0000FF">&#39;@nswdoe/doe-ui-core&#39;</span><span style="color: #000000;">;</span></span>
            </code>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <pre style="margin: 0; line-height: 125%"><span style="color: #007700">&lt;NavigationDrawer</span>
    <span style="color: #0000CC">:items=</span><span>&quot;items&quot;</span>
    <span style="color: #0000CC">:showProfileMobile=</span><span>&quot;true&quot;</span>
    <span style="color: #0000CC">profile-name=</span><span>&quot;Joe Black&quot;</span>
    <span style="color: #0000CC">profile-job-title=</span><span>&quot;Teacher&quot;</span>
    <span style="color: #0000CC">@logout=</span><span>&quot;handleLogout&quot;</span>
  <span style="color: #007700">/&gt;</span>
            </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6 mr-3">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>items</td>
                <td>Array</td>
                <td>Array of menu items.</td>
              </tr>
              <tr>
                <td>showProfileMobile</td>
                <td>Boolean</td>
                <td>Show the profile menu on mobile viewports.</td>
              </tr>
              <tr>
                <td>profileName</td>
                <td>String</td>
                <td>The display name of the logged in user.</td>
              </tr>
              <tr>
                <td>profileJobTitle</td>
                <td>String</td>
                <td>The job title of the logged in user.</td>
              </tr>
              <tr>
                <td>profileMenuItems</td>
                <td>Array</td>
                <td>Array of menu items for the mobile profile menu. Note: Logout option is always added by default.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card class="mt-6 mr-3">
        <v-card-title>
          Menu Item Properties (items and profileMenuItems)
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>id</td>
                <td>String</td>
                <td>Id of menu item.</td>
              </tr>
              <tr>
                <td>title</td>
                <td>String</td>
                <td>Display title.</td>
              </tr>
              <tr>
                <td>icon</td>
                <td>String</td>
                <td>Material design icon to display on the left (top level items only).</td>
              </tr>
              <tr>
                <td>iconOutlined</td>
                <td>Boolean</td>
                <td>Applies &quot;material-icons-outlined&quot; class to icon.</td>
              </tr>
              <tr>
                <td>notificationCount</td>
                <td>String, Number</td>
                <td>Displays a red notification counter in place of the icon.</td>
              </tr>
              <tr>
                <td>route</td>
                <td>String</td>
                <td>Menu route when clicked.</td>
              </tr>
              <tr>
                <td>clickHandler</td>
                <td>Function</td>
                <td>Click handler.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card class="my-6 mr-3">
        <v-card-title>
          Slots
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Slot
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>preMenu</td>
                <td>Slot directly above the menu (below the profile on mobile viewports).</td>
              </tr>
              <tr>
                <td>postMenu</td>
                <td>Slot directly underneath the menu.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card class="my-6 mr-3">
        <v-card-title>
          Events
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Event
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>logout</td>
                <td>Triggered when logout clicked on mobile profile.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Usage'
  }
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
code {
  color: #333333;
  background: #ebe8e8;
  padding: .5rem 1.5rem;
}
</style>