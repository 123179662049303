<template>
  <v-row>
    <v-col cols="9">
      <v-container class="usage">
        <h2 class="my-6">
          Button types
        </h2>
        <h3>Primary (High emphasis)</h3>
        <p>
          By default, the primary button should be used for the main call to action. Use only 1 primary button per page
          where possible as this button commands the most attention and makes it clear that the other buttons have less
          importance in the hieracrchy.
        </p>

        <h3 class="mt-4">
          Secondary (Medium emphasis)
        </h3>
        <p>
          This outlined style is suitable for secondary actions and non-compulsory actions. These actions are important
          but not the primary action.
        </p>

        <h3 class="mt-4">
          Tertiary (Low emphasis)
        </h3>
        <p>
          This text button is for less-pronounced actions such as those on cards where the focus needs to stay on the
          content rather than the button. Its container only becomes visible when the user is interacting with it.
        </p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/buttons/buttontypes.png">
          </v-col>
        </v-card>
        <h3 class="mt-10">
          Icon buttons
        </h3>
        <p>
          Icon buttons have an icon to the left of the label to help clarify its action. The icon can be moved to the
          right where appropriate such as a chevron pointing to the right in a ‘Next’ button.
        </p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/buttons/iconbuttons.png">
          </v-col>
        </v-card>
        <h3 class="mt-10">
          Button sizes
        </h3>
        <p>
          By default, ADS uses the ‘Large’ button which is 44px in height. Where appropriate, the smaller 28px button
          can be used.
        </p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/buttons/buttonsizes.png">
          </v-col>
        </v-card>
        <h2 class="mt-10">
          Button states
        </h2>
        <h3 class="mt-6">
          Primary button states
        </h3>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/buttons/primary-button-states.png">
          </v-col>
        </v-card>
        <h3 class="mt-6">
          Secondary button states
        </h3>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/buttons/secondary-button-states.png">
          </v-col>
        </v-card>
        <h3 class="mt-6">
          Tertiary button states
        </h3>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center">
            <img src="../../../assets/buttons/tertiary-button-states.png">
          </v-col>
        </v-card>
        <h3 class="mt-6">
          Loading states
        </h3>
        <v-card>
          <v-col
            cols="6"
            class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex flex-wrap justify-center"
          >
            <img src="../../../assets/buttons/loadingstate.png">
          </v-col>
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span
              style="color:#800080; "
>{</span> AdsButton <span style="color:#800080; ">}</span> from <span
              style="color:#800000; "
>"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span
              style="color:#800000; "
>";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-alerts-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-alerts-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span
                    class="pl-kos"
>&lt;</span><span class="pl-ent">v-container</span><span
                    class="pl-kos"
>&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-alerts-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span
                    class="pl-ent"
>AdsButton</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-alerts-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:secondary</span>=<span
                    class="pl-s"
>secondary</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-alerts-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:tertiary</span>="<span
                    class="pl-s"
>tertiary</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-alerts-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:compact</span>="<span
                    class="pl-s"
>compact</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-alerts-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">buttonText</span>="<span
                    class="pl-s"
>Button Text</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                  <td
id="file-alerts-html-LC7"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:disabled</span>="<span
                    class="pl-s"
>disabled</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-alerts-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:icon</span>="<span
                    class="pl-s"
>plus_circle_outline</span>"</td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-alerts-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-alerts-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span
                    class="pl-ent"
>AdsButton</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-alerts-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-alerts-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span
                    class="pl-ent"
>v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>type</td>
                <td>String</td>
                <td>Specify type of the button.(default to info)</td>
              </tr>
              <tr>
                <td>secondary</td>
                <td>Boolean</td>
                <td>Outlined button with white background.(default to false)</td>
              </tr>
              <tr>
                <td>tertiary</td>
                <td>Boolean</td>
                <td>button with transparent background and no borders (default to false)</td>
              </tr>
              <tr>
                <td>compact</td>
                <td>Boolean</td>
                <td>button with small size.(default to false)</td>
              </tr>
              <tr>
                <td>disabled</td>
                <td>Boolean</td>
                <td>Disable button of any type.(default to false)</td>
              </tr>
              <tr>
                <td>ripple</td>
                <td>Boolean</td>
                <td>Activate ripple effect from button.(default to false)</td>
              </tr>
              <tr>
                <td>icon</td>
                <td>String</td>
                <td>Show icon with button.(default to null)</td>
              </tr>
              <tr>
                <td>iconOutlined</td>
                <td>Boolean</td>
                <td>Support for material icons.(default to true)</td>
              </tr>
              <tr>
                <td>buttonText</td>
                <td>String</td>
                <td>Text to show on the button.</td>
              </tr>
              <tr>
                <td>depressed</td>
                <td>Boolean</td>
                <td>Shadow show/hide for the button.(default to false)</td>
              </tr>
              <tr>
                <td>buttonClass</td>
                <td>String</td>
                <td>custom class to override button styles.</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Usage'
}
</script>

<style lang="scss" scoped>
.usage {
  font-family: Arial;
  font-style: normal;

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }

  p {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
  }
}

.pl-ent {
  color: #22863a;
}

.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
