<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card outlined>
          <AdsDataTable
            :headers="headers"
            :items="items"
            search-label="Find student"
            sort-by="name"
            sort-desc
            @click:row="rowAction"
          >
            <template v-slot:[`item.name`]="{ item }">
              <a
                href="https://vuetifyjs.com/en/components/data-tables/"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-bind="getStatusChipDetails(item.status)"
                small
              >
                {{ item.status }}
              </v-chip>
            </template>
          </AdsDataTable>
        </v-card>
        <Dialog
          :title="dialogTitle"
          max-width="520px"
          :open-dialog="openDialog"
          :display-close-btn="true"
          message=""
          @close="openDialog=false"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { AdsDataTable, Dialog } from '@nswdoe/doe-ui-core';

const Y67T_STATUS_CHIPS = {
  NEW: {
    text: 'NEW',
    color: '#041E42',
    outlined: true,
    textColor: '#041E42'
  },
  VIEWED: {
    text: 'VIEWED',
    color: '#C8DCF0',
    textColor: '#041E42'
  },
  DECLINED: {
    text: 'DECLINED',
    color: '#CE0037',
    outlined: false,
    textColor: '#FFFFFF'
  },
  WITHDRAWN: {
    text: 'WITHDRAWN',
    color: '#6D7079',
    outlined: false,
    textColor: '#FFFFFF'
  },
  ACCEPTED: {
    text: 'ACCEPTED',
    color: '#00A908',
    outlined: false,
    textColor: '#FFFFFF'
  },
  READY: {
    text: 'READY',
    color: '#041e42',
    outlined: false,
    textColor: '#FFFFFF'
  }
}

export default {
  name: 'AdsDataTableDemo',
  components: {
    AdsDataTable,
    Dialog
  },
  data() {
    return {
      headers: [
        {
          text: 'Student name',
          value: 'name',
        },
        { text: 'Previous School', value: 'prevSchool' },
        { text: 'Scholastic year', value: 'scholasticYear' },
        { text: 'Flags', value: 'flags' },
        { text: 'Start date', value: 'startdate' },
        { text: 'Status', value: 'status' },
      ],
      items: [
        {
          name: 'Smith John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Kindergarten',
          flags: 'Personal learning',
          startdate: 'Term 1 2021',
          status: 'NEW',
        },
        {
          name: 'John Smith',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 1',
          flags: 'None',
          startdate: 'Term 1 2021',
          status: 'DECLINED',
        },
        {
          name: 'Smith John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 2',
          flags: 'Personal learning',
          startdate: 'Term 2 2021',
          status: 'VIEWED',
        },
        {
          name: 'Jack John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 3',
          flags: 'Personal learning',
          startdate: 'Term 3 2021',
          status: 'READY',
        },
       {
          name: 'Smith John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 4',
          flags: 'None',
          startdate: 'Term 4 2021',
          status: 'ACCEPTED',
        },
        {
          name: 'Smith John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 5',
          flags: 'None',
          startdate: 'Term 1 2021',
          status: 'WITHDRAWN',
        },
        {
          name: 'Stacy John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Kindergarten',
          flags: 'Personal learning',
          startdate: 'Term 2 2021',
          status: 'READY',
        },
        {
          name: 'Albert John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 6',
          flags: 'None',
          startdate: 'Term 1 2021',
          status: 'NEW',
        },
        {
          name: 'Smith John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Kindergarten',
          flags: 'Personal learning',
          startdate: 'Term 4 2021',
          status: 'ACCEPTED',
        },
        {
          name: 'Smith John',
          prevSchool: 'Albert Park Public School',
          scholasticYear: 'Year 7',
          flags: 'None',
          startdate: 'Term 1 2021',
          status: 'DECLINED',
        }
      ],
      openDialog: false,
      dialogTitle: ''
    };
  },
  methods: {
    rowAction(item) {
      // event handeler
      this.openDialog = true
      this.dialogTitle = item.name
    },
    getStatusChipDetails (status) {
       return Y67T_STATUS_CHIPS[status]
    }
  }
};
</script>

<style scoped lang="scss">
a,
a:not(.v-btn):visited {
  color: #041e42;
}
::v-deep .v-chip{
  width: 100%;
  .v-chip__content {
    width: 100%;
    justify-content: center;
    font-weight: 700;
  }
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 16px;
}

</style>
