<template>
  <v-container fluid>
    <Banner
      heading="Change Logs"
      subtitle="Change Logs of the ADS Library (doe-ui-core)"
      content-class="pl-0"
    />
    <v-container fluid>
      <div class="d-flex flex-wrap pa-12">
        <Markdown />
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner';
import Markdown from '@nswdoe/doe-ui-core/README.md';

export default {
  name: 'ChangeLogs',
  components: {
    Banner,
    Markdown
  },
}
</script>

<style scoped>

</style>
