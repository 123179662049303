<template>
  <v-container fluid>
    <Banner
      heading="Template Project Structure"
      subtitle="ADS Developer's Guide"
      content-class="pl-0"
    />
    <v-container fluid>
      <div class="d-flex flex-wrap pa-12">
        <Markdown />
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '@/components/Banner';
import Markdown from './template-project-structure.md';

export default {
  name: 'ProjectStructure',
  components: {
    Banner,
    Markdown
  }
}
</script>

<style lang="scss" scoped>

</style>
