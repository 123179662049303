<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card
            outlined
            height="500"
          >
            <v-row class="pa-5 d-flex justify-center">
              <div class="mr-2">
                <MyEssentials
                  :essentials-data="myEssentialsApps"
                  title="Recently used"
                  essential-text="Go to My Essentials"
                  essential-url="https://google.com"
                >
                  <!--          Note: you can also pass in a slot for your customized header as shown below-->
                  <!--          <template v-slot:header>-->
                  <!--            <ul>-->
                  <!--              <li>paragraph 1</li>-->
                  <!--              <li>paragraph 2</li>-->
                  <!--            </ul>-->
                  <!--          </template>-->
                </MyEssentials>
              </div>
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {MyEssentials} from '@nswdoe/doe-ui-core';

export default {
  name: 'Demo',
  components: {
    MyEssentials,
  },
  data() {
    return {
      myEssentialsApps: [
        {
          'id': 65880999901,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-amu',
          'url': 'https://education.nsw.gov.au/curriculum/multicultural-education/interpreting-and-translations',
          'description': 'AMU',
          'title': 'AMU'
        },
        {
          'id': 65880899902,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-hns-esafety',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Esafety',
          'title': 'Esafety'
        },
        {
          'id': 65880899903,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-learning-systems-support',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Support',
          'title': 'Support'
        },
        {
          'id': 65880899904,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-buzz',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Buzz',
          'title': 'Buzz'
        },
        {
          'id': 65880899905,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-bi',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'BI',
          'title': 'BI'
        },
        {
          'id': 65880899906,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-plasst',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Plasst',
          'title': 'Plasst'
        },
        {
          'id': 65880899907,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-bloged',
          'url': 'https://online.det.nsw.edu.au/bloged',
          'description': 'Bloged',
          'title': 'Bloged'
        },
        {
          'id': 65880899908,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-rmu',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'RMU',
          'title': 'RMU'
        },
        {
          'id': 65880899909,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-sap',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'SAP',
          'title': 'SAP'
        },
        {
          'id': 65880899910,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-buzz',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Buzz',
          'title': 'Buzz'
        },
        {
          'id': 65880899911,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-oasis',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Oasis',
          'title': 'Oasis'
        },
        {
          'id': 65880899912,
          'category': {
            'ids': ['244617', '4336540', '6397522'],
            'titles': ['webifier', 'my applications', 'my essentials']
          },
          'dates': {
            'created': 1518741815555,
            'display': 978267600000,
            'expiration': 1518741815555,
            'modified': 1521006362108,
            'published': 978267600000
          },
          'contact': {
            'email': 'noone@example.com',
            'phone': '0112345678',
            'name': 'test employee'
          },
          'icon': 'my-buzz',
          'url': 'https://online.det.nsw.edu.au/amu',
          'description': 'Buzz',
          'title': 'Buzz'
        },
      ],
    }
  },
};
</script>
