<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <p class="pt-5">
          Notifications can be seen from appbar button to show notifications for user. Notifications component need to add to project to show notifications from the appbar.
        </p>
        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center">
            <img
              src="../../../assets/notifications/notification.jpg"
              style="width: 290px;"
            >
          </v-col>
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>

        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- generated via https://wordtohtml.net/ -->
            <code>
              <span>import</span><span><span>&nbsp;{&nbsp;</span><span>Notifications</span><span>&nbsp;}&nbsp;</span><span>from</span><span style="color: #000000;">&nbsp;</span><span style="color: #0000FF">&#39;@nswdoe/doe-ui-core&#39;</span><span style="color: #000000;">;</span></span>
            </code>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <pre style="margin: 0; line-height: 125%">
  <span style="color: #007700">&lt;v-container&gt;</span>
    <span style="color: #007700">&lt;NavigationDrawer</span><span style="color: #0000CC">:notificationData=</span><span>&quot;notificationData&quot;</span><span style="color: #007700">/&gt;</span>
  <span style="color: #007700">&lt;/v-container&gt;</span></pre>
          </v-col>
        </v-row>
      </v-card>
      <Card :definition="definition.componentProperties" />
      <Card :definition="definition.componentsEvents" />
    </v-col>
  </v-row>
</template>

<script>
import Card from './Card.vue'
  export default {
    name: 'Usage',
    components: {
      Card
    },
    data(){
      return{
        definition: {
          componentProperties: {
            title: 'Componenet Properties',
            heading: [ 'Name', 'Type', 'Desc'],
            properties: [
              {
                id: 1,
                name : 'notificationData',
                type : 'Array',
                desc : 'Notification items from edpush api'
              },
              {
                id: 2,
                name : 'iconsToMap',
                type : 'iconsToMap',
                desc : '[{ app: "APP_NAME", icon: "APP_ICON" }]'
              }
            ]
          },
          componentsEvents: {
              title: 'Component Events',
              heading: [ 'Name', 'Type', 'Desc'],
              events: [
                {
                id: 1,
                name : 'toggleNotification',
                type : 'Emit',
                desc : 'Close notification drawer. (Handled via appbar if using)'
              },
              {
                id: 2,
                name : 'toggleNotification',
                type : 'Emit',
                desc : 'Delete notification clicked. (You manually need to call api to delete notification and update notificationData)'
              }
            ]
          },
        }
      }
    }
  }
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
code {
  color: #333333;
  background: #ebe8e8;
  padding: .5rem 1.5rem;
}
</style>
