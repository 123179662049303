<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card outlined>
          <v-row class="pa-5 d-flex justify-center">
            <ToggleButtonGroup
              :left-click-handler="leftClickHandler"
              :right-click-handler="rightClickHandler"
            />
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
  import { ToggleButtonGroup } from '@nswdoe/doe-ui-core';

  export default {
    name: 'Demo',
    components: {
      ToggleButtonGroup
    },
    data() {
      return {
        toggleCardView: 0
      }
    },
    computed:{
      gridActive: function () {
        return this.toggleCardView === 0;
      },
      listActive: function () {
        return this.toggleCardView === 1;
      }
    },
    methods:{
      leftClickHandler: function () {
        alert('Grid view clicked')
      },
      rightClickHandler: function () {
        alert('List view clicked')
      }
    }
  };
</script>

<style lang="scss" scoped>
@import  '../../../scss/variables';
.activeButton {
  background-color: $ads-navy !important;

  .v-icon {
    color: $ads-white !important;
  }
}

.activeButton:focus {
  outline: 3px solid -webkit-focus-ring-color !important;
}
.v-item-group {
  background-color: $ads-white !important;
}
</style>