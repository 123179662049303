<template>
  <v-row>
    <v-col>
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card outlined>
          <EnvironmentInfo
            :versionNo="versionNo"
            :environment="environment"
            :isVisible="isVisible"
            @hide="hide"
          />
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>

 import { EnvironmentInfo } from '@nswdoe/doe-ui-core';
 export default {
  name: 'Demo',
  components:{
    EnvironmentInfo
  },
  data() {
    return {
      versionNo: 'Demo-test',
      environment: 'yellow-1.1.0',
      isVisible: true
    } 
    },
  methods: {
    hide() {
      this.isVisible = false
    }
  }
};
</script>