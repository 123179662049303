<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <SessionCountdown
          :showCountdown="showCountdown"
          :minutesWarning="showTimedoutWarning ? 0 : 1"
          sessionTimeoutDialogCssClass="session-timeout"
          sessionCountdownAlertCssClass="session-countdown"
          @continue="continueSession"
          @login="login"
          @expired="removeTokens"
        />
        <h2 class="mt-6">
          Demo
        </h2>
        <v-container>
          <v-card outlined>
            <v-row class="pa-5 d-flex justify-center">
              <v-col cols="2">
                <h3>Option 1</h3>
              </v-col>
              <v-col>
                <v-btn
                  class="primary"
                  @click="showCountdown = true; showTimedoutWarning = false"
                >
                  Show Session Countdown Warning
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-5 d-flex justify-center">
              <v-col cols="2">
                <h3>Option 2</h3>
              </v-col>
              <v-col>
                <v-btn
                  class="primary"
                  @click="showCountdown = true; showTimedoutWarning = true"
                >
                  Show Session Timed Out Warning
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="pa-5 d-flex justify-center">
              <v-col cols="2">
                <h3>Option 3</h3>
              </v-col>
              <v-col>
                Don't press any keys or navigate away from this page and wait for about 30 seconds and the session countdown alert (in page) will be displayed automatically.
                You can close this countdown alert by clicking on the link 'CONTINUE SESSION' or just click anywhere on this page.
                <br>
                <br>
                After the countdown is complete, another modal dialog (page overlay) will be displayed.
                To continue, you must click on the link 'LOGIN TO CONTINUE', the modal can't be closed and you can't click on anywhere else on this page.
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { SessionCountdown } from '@nswdoe/doe-ui-core';

export default {
  name: 'Demo',
  components: {
    SessionCountdown
  },
  data() {
    return {
      showTimedoutWarning: false
    }
  },
  beforeMount() {
    this.$activityMonitor.activate()
  },
  beforeDestroy() {
    this.$activityMonitor.deactivate()
  },
  computed: {
    showCountdown: {
      get() {
        return this.$store.state.showTimeoutBanner
      },
      set(value) {
        this.$store.dispatch('set', ['showTimeoutBanner', value])
      }
    }
  },
  methods: {
    continueSession() {
      this.showCountdown = false
    },
    async login() {
      this.showCountdown = false
      this.showTimedoutWarning = false
      this.$activityMonitor.activate()
    },
    removeTokens() {}
  }
};
</script>
<style lang="scss">
.session-countdown {
  margin-top: 2rem;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.session-timeout {
  top: 12rem;
}
</style>
