<template>
  <v-row>
    <v-col
      cols="12"
      md="10"
    >
      <v-container>
        <h2 class="mt-6">
          Demo
        </h2>
        <v-card
          outlined
          class="pa-6"
          :dark="!light"
        >
          <div>
            <DataSwitcher
              v-model="selected"
              :items="items"
              return-object
              placeholder="Search for a fruit..."
              :prepend-inner-icon="icon ? 'mdi-magnify' : null"
              :clearable="clearable"
            >
              <template
                v-if="useListItemSlot"
                v-slot:item="data"
              >
                <span>{{ `${data.item.text} (${data.item.value})` }}</span>
              </template>
            </DataSwitcher>
          </div>
          <div class="mt-6">
            <span>Selected value: {{ selected }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <v-switch
              v-model="useListItemSlot"
              label="Use list item slot"
            />
            <v-switch
              v-model="icon"
              label="Prepend icon (default mdi-magnify)"
            />
            <v-switch
              v-model="clearable"
              label="clearable (default true)"
            />
            <v-switch
              v-model="light"
              label="light theme"
            />
          </div>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { DataSwitcher } from '@nswdoe/doe-ui-core';

export default {
  name: 'DataSwitcherDemo',
  components: {
    DataSwitcher
  },
  data() {
    return {
      useListItemSlot: false,
      icon: true,
      clearable: true,
      light: false,
      items: [{
        value: 1,
        text: 'Apple'
      }, {
        value: 2,
        text: 'Pen'
      }, {
        value: 3,
        text: 'Pineapple'
      }, {
        value: 4,
        text: 'Quince'
      }, {
        value: 5,
        text: 'Rambutan'
      }, {
        value: 6,
        text: 'Red Grapefruit'
      }, {
        value: 7,
        text: 'Strawberry'
      }, {
        value: 8,
        text: 'Watermelon'
      }],
      selected: null 
    };
  }
};
</script>

<style scoped lang="scss">
</style>
