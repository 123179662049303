<template>
  <v-container class="mb-12">
    <v-row>
      <v-col cols="9">
        <v-container>
          <v-row>
            <v-col>
              <v-row justify="end">
                <p>Last updated: 23 August 2022 - v1.3.0</p>
              </v-row>
            </v-col>
          </v-row>
          <h2 class="mt-6">
            SSO Authentication
          </h2>
          <p>
            The OAuth plugin provides workflow to log into an OpenAM SSO or Azure SSO server
            and utilities to pass tokens to api calls.
          </p>
          <p>
            A <b>"secured"</b> page will redirect the user to an authorise page
            where a user logs in and is then redirected back to your app with the
            appropriate access tokens.
          </p>
          <div class="d-flex justify-center">
            <img
              src="../../../assets/oauth/ssoLogin.png"
              style="border: 1px solid #000000; max-width: 300px"
            >
          </div>

          <h3>Security implications</h3>
          <p>Apps that implement OAuth via the front-end (i.e. Javascript) are intrinsically less secure than an app that performs the OAuth authentication process on the server side. Their source
            code (HTML, JavaScript, CSS and images) are all publicly accessible without an active SSO session. As a result the business logic of your application is essentially observable.</p>
          <p>The intrinsically insecure nature of a SPA app that implements OAuth on the front-end means that you should not embed any confidential content or personally identifiable information (PII)
            within the app's source code. Any data/content that is not intended for unrestricted consumption must be loaded dynamically post authentication from secure sources (ideally requiring OAuth
            OpenID Connect identity tokens).</p>
          <p>If you wish to secure all application assets including source code then you must implement OAuth SSO integration on the server side. Note that this does not preclude you from using the
            ADS.</p>
          <p>If you are hosting your ADS SPA using CloudFront + S3 you can achieve server side OAuth based authentication using Lambda@Edge functions. The department has established a software design
            pattern for this along with working code. Contact the <a href="https://confluence.education.nsw.gov.au/display/SD/Solution+Design+and+Engineering">Solution Design & Engineering team</a>
            for more information.</p>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="11"
        md="10"
        xl="9"
      >
        <v-container>
          <h2 class="mt-6">
            Getting started
          </h2>

          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                Installing on your Vue Instance - main.js
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <pre>
  import {OAuth} from '@nswdoe/doe-ui-core';
  import router from '@/router'

                  <h3>// all settings below to be provided by your OAuth provider (the only difference between Azure SSO and OpenAM SSO is the <code>tokenUri</code> field) </h3>
  const authOptions = {
    router: router,
    pkce: true, (<span style="color: #4b69c6">recommended to be <code>true</code> for OpenAM SSO, and required to be <code>true</code> for Azure SSO</span>)
    authoriseUri: 'https://your-sso-server.com/sso/oauth2/authorize',
    <span style="color: #4b69c6">tokenUri: 'https://login.microsoftonline.com/{your-id-here}/oauth2/v2.0/token' (this field is only required for Azure SSO) </span>
    logoutUri: 'your-sso-server logout URI',
    secureApp: true,

  <h3>// url params to pass when redirecting to the OAuth server's authorize url</h3>
    params: {
      client_id: YOUR-APPS-CLIENT-ID,
      redirect_uri: encodeURIComponent('https://your-app.com/login/callback'),
      scope: encodeURIComponent('openid your-app-scope')
    }
  };

  Vue.use(OAuth, authOptions);
                </pre>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                Configuring a protected route
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <pre>
  // vue route config example - add meta.protected to the route config
  // The plugin adds a route shield to the configured router instance
  // to check user auth before accessing the page.
  // Tip: This configuration is not required if using the 'secureApp' plugin option
  {
    path: '/',
    name: 'base',
    component: Home,
    <b>meta: {
      protected: true,
    }</b>
  },
                </pre>
              </v-col>
            </v-row>
          </v-card>

          <SimpleTableCard
            class="mt-6"
            title="Options"
            :headers="pProps.headers"
            :keys="pProps.key"
            :data="pProps.data"
          />

          <SimpleTableCard
            class="mt-6"
            title="Methods"
            :headers="mProps.headers"
            :data="mProps.data"
          />

          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                Other Code snippets
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <pre>
  <h3>// Access decoded id_token data within a component</h3>
  // The data returned in data.profile depends on your sso config
  computed: {
    profile() {
      return this.$OAuth.data.profile;
    }
  }


  <h3>// Call buildHttpHeader method in an api</h3>
  import axios from 'axios';
  import Vue from 'vue';

  function callMyApi() {
    return axios.post("https://my-api-url/v1/api", {
      post-value: "1234"
    }, {
      headers: {
        <b>...Vue.prototype.$OAuth.buildHttpHeader</b>({
          "Accept": "application/json",
          "Content-Type": "application/json; charset=UTF-8"
        })
      }
    })
    .then((response) => {
      return response.data;
    });
  }
                </pre>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="11"
        md="10"
        xl="9"
      >
        <v-container>
          <h2 class="mt-6">
            Registering an OAuth ADS app
          </h2>

          <p>To register your app with the department's Single Sign-On (SSO) service, fill out the <a href="https://forms.office.com/r/4eQvbLgt3L">New Application Single Sign On (SSO) Questionnaire form</a></p>

          <p><abbr title="nota bene">NB</abbr>: The "redirection URL" should contain the path "<em>/login/callback</em>" if using the "<em>router</em>" option detailed above. e.g. <em>ads.education.nsw.gov.au/login/callback</em>
          </p>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="11"
        md="10"
        xl="9"
      >
        <v-container>
          <h2 class="mt-6">
            AWS CloudFront + S3 hosting
          </h2>

          <p>A common scenario for hosting an ADS app is to use AWS CloudFront and S3 as a highly scalable low cost static web server.</p>

          <p>In order to support the OAuth plugin there is a small easily missed CloudFront configuration detail that must be set for the plugin to work correctly.</p>

          <h3>Instructions</h3>
          <ol>
            <li>In the AWS CloudFront console (accessed via myapps.microsoft.com) select CloudFront distribution you wish to configure</li>
            <li>Select the "<em>Error pages</em>" tab and then click "<em>Create custom error response</em>"</li>
            <li>Select "<em>403: Forbidden</em>" from the "<em>HTTP error code</em>" dropdown and the "<em>Yes</em>" radio button under "<em>Customise error response</em>"</li>
            <li>Enter "<em>/index.html</em>" as the "<em>Response page path</em>"</li>
            . The "<em>HTTP Response code</em>" dropdown should have "<em>403: Forbidden</em>" auto-selected.
            <li>Click "<em>Create custom error response</em>"</li>
          </ol>

          <h4 class="mt-12">
            What does this do?
          </h4>
          <p>It's important to understand how and where routing occurs during the lifecycle of the login process to understand why the above CloudFront configuration is required. At different stages
            in the lifecycle "routing" is handled either by the frontend (i.e. Vue.js router), the backend (AWS CloudFront) or our SSO service. It can be a bit of a complex dance but one that is
            valuable to know. Below is a sequence diagram that explains the the interaction. Note that some of the steps have been deliberately simplified for brevity.</p>


          <div class="d-flex justify-center">
            <img
              src="../../../assets/oauth/ads-sso-integration-sequence.png"
              style="border: 1px solid #000000; max-width: 761px"
            >
          </div>
        </v-container>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="11"
        md="10"
        xl="9"
      >
        <v-container>
          <h2 class="mt-6">
            Sample SSO configurations
          </h2>
          <h3 class="mt-3">
            Service NSW
          </h3>
          <p>If Service NSW is configured as a SSO provider, users will be redirected to SNSW login page as below for authentication. </p>
          <div class="d-flex justify-center">
            <img
              src="../../../assets/oauth/snsw-log-in.png"
              style="border: 1px solid #000000; max-width: 400px"
            >
          </div>

          <h4 class="mt-3">
            Link to SNSW log in page
          </h4>
          <a
            href="https://webident.service.nsw.gov.au/as/authorization.oauth2?client_id=apigee_myaccount&response_type=code&scope=openid%20profile%20email&redirect_uri=https://api.g.service.nsw.gov.au/v1/identity/oauth/callback&state=rrt-1282947790128630620-a-gsy1-19599-5635723-1&nonce=MyD6hOlWdflDNpRHQQaaIZCG5IM-uk-ttpF89F2uj1U&code_challenge=W55MDeGMYHhjqAfzPyFRtwQkiPdFwKfz1JXBVWIQdpw&code_challenge_method=S256#/email"
          >SNSW log in</a>

          <h4 class="mt-3">
            Sample oAuth plugin configuration for Service NSW
          </h4>
          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                <pre>
                  // main.js
                  ...
                  Vue.use(OAuth,
                    {
                      router,
                      pkce: true, // set to false for implicit auth
                      authoriseUri: process.env.VUE_APP_SNSW_AUTH_URL, // set in .env
                      tokenUri: process.env.VUE_APP_SNSW_TOKEN_URL, // set in .env
                      forceProdMode: true, // Don't want to mock OAuth, even in dev environment

                      // authorise url params
                      params: {
                        client_id: process.env.VUE_APP_SNSW_AUTH_CLIENT_ID, // set in .env
                        redirect_uri: `${window.location.origin}/login/callback/`,
                        scope: 'openid profile email'
                      }
                    }
                  )
                </pre>
              </v-col>
            </v-row>
          </v-card>
          <h4 class="mt-3">
            .env files to be used in localhost and CICD pipeline
          </h4>
          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                <pre>
                  // .env localhost
                  ...
                 # SNSW OAUTH CONFIGURATION
                VUE_APP_SNSW_AUTH_CLIENT_ID=your auth client ID for SNSW SSO dev environment
                VUE_APP_SNSW_BASE=dev-doe-snsw
                VUE_APP_SNSW_AUTH_URL=//${VUE_APP_SNSW_BASE}.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize
                VUE_APP_SNSW_TOKEN_URL=//${VUE_APP_SNSW_BASE}.auth.ap-southeast-2.amazoncognito.com/oauth2/token
                </pre>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                <pre>
                  // .env.dev
                  ...
                 # SNSW OAUTH CONFIGURATION
                VUE_APP_SNSW_AUTH_CLIENT_ID=your auth client ID for SNSW SSO dev environment
                VUE_APP_SNSW_BASE=dev-doe-snsw
                </pre>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                <pre>
                  // .env.pre
                  ...
                 # SNSW OAUTH CONFIGURATION
                VUE_APP_SNSW_AUTH_CLIENT_ID=your auth client ID for SNSW SSO pre environment
                VUE_APP_SNSW_BASE=pre-doe-snsw
                </pre>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pl-6 mt-6">
            <v-row style="position: relative; left: -12px;">
              <v-col>
                <pre>
                  // .env.prod
                  ...
                 # SNSW OAUTH CONFIGURATION
                VUE_APP_SNSW_AUTH_CLIENT_ID=your auth client ID for SNSW SSO prod environment
                VUE_APP_SNSW_BASE=doe-snsw
                </pre>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimpleTableCard from '@/components/SimpleTableCard';

export default {
  name: 'OAuthUsage',
  components: {
    SimpleTableCard
  },
  data() {
    return {
      mProps: {
        headers: ['Method', 'Params', 'Desc'],
        data: [{
          method: 'buildHttpHeader',
          params: 'header: Object',
          desc: 'Accepts a header param object and returns a new Object with additional Authorization headers based on the provided configuration.'
        }, {
          method: 'logout',
          params: '',
          desc: 'Clears tokens and redirects user to the logoutUri.'
        }]
      },
      pProps: {
        headers: ['Name', 'Type', 'Mandatory?', 'Desc'],
        keys: ['name', 'type', 'mandatory', 'desc'],
        data: [
          {
            name: 'authoriseUri',
            type: 'String',
            mandatory: 'Mandatory',
            desc: 'The uri to redirect the user for SSO authentication.'
          },
          {
            name: 'tokenUri',
            type: 'String',
            mandatory: 'Mandatory for Azure',
            desc: 'The tokenUri is optional for OpenAM SSO but mandatory for Azure SSO'
          },
          {
            name: 'params',
            type: 'Object',
            mandatory: 'Mandatory',
            desc:
              'An object map of URL params to include in the Authorize redirect. By default, a response_type=\'token id_token\' will be requested for implicit auth, and response_type=\'code\' for pkce auth.'
          },
          {
            name: 'params.client_id',
            type: 'String',
            mandatory: 'Mandatory',
            desc: 'The client_id of your app; provided by the SSO provider.'
          },
          {
            name: 'params.redirect_uri',
            type: 'String',
            mandatory: 'Mandatory',
            desc:
              'The callback uri that the SSO server will redirect back to with the necessary tokens once successfully logged in. This must be configured on the SSO server under your app\'s configuration.<br>Note: if router is passed, this url\'s path will be used to infer the callback redirect route that handles the token exchange.'
          },
          {
            name: 'afterLogin',
            type: 'Function',
            mandatory: 'Optional',
            desc: 'Callback function called after login flow completed. Receives access and id tokens as parameters on success, or undefined on failure.'
          },
          {
            name: 'pkce',
            type: 'Boolean',
            mandatory: 'Optional',
            desc:
              'Toggles Proof Key for Code Exchange (PKCE) Authorisation Code Auth mode. This is the more secure and preferred method of OAuth.<br><a href="https://auth0.com/docs/flows/concepts/auth-code-pkce">https://auth0.com/docs/flows/concepts/auth-code-pkce</a>. Required to be <code>true</code> for Azure SSO'
          },
          {
            name: 'router',
            type: 'vue-router',
            mandatory: 'Optional',
            desc:
              'The vue router instance. When provided, the plugin will automatically attach a \'/login/callback\' route, and navigation shields for protected routes.'
          },
          {
            name: 'secureApp',
            type: 'Boolean',
            mandatory: 'Optional',
            desc: 'Set to true to make all routes protected by default.'
          },
          {
            name: 'logoutUri',
            type: 'String',
            mandatory: 'Optional',
            desc: 'The uri to log the user out of the SSO server.'
          },
          {
            name: 'apiHttpHeaders',
            type: 'Object',
            mandatory: 'Optional',
            desc:
              'An object map of additional http headers to attach along with the \'Authorize\' header.'
          },
          {
            name: 'devIdTokenOverride',
            type: 'String',
            mandatory: 'Optional',
            desc:
              'When in dev mode, overrides the default dev id-token for testing different id-token properties.'
          },
          {
            name: 'forceProdMode',
            type: 'Boolean',
            mandatory: 'Optional',
            desc: 'Enables OAuth from external server when in dev mode.'
          },
          {
            name: 'enablePopupAuth',
            type: 'Boolean',
            mandatory: 'Optional',
            desc:
              'Set to true for app to pop up a new window to perform re-authorisation to attempt to preserve app state when session times out. Note, popups are not very reliable due to various popup blockers. Auth will fallback to redirection if popup fails to load.'
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
