<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <v-row>
          <v-col class="mt-8">
            <v-row justify="end">
              <p>Last updated: 30 April 2020</p>
            </v-row>
          </v-col>
        </v-row>

        <h2 class="mt-6">
          About
        </h2>

        <ol
          class="mt-2"
          type="1"
        >
          <li>
            The avatar, name and job title of the user.
          </li>
          <li>
            Options for the user to manage their DoE account. These link to the Staff Portal.
          </li>
          <li>
            Log out button - since we use a single sign on experience, this will log the user out of all DoE apps.
          </li>
        </ol>
        <p class="pt-5">
          This component cannot be customised.
        </p>

        <v-card>
          <v-col class="ml-0 mt-4 mb-6 pa-5 imgBackground d-flex justify-center">
            <img
              src="../../../assets/accountmenu/accountmenu.png"
              style="width: 290px;"
            >
          </v-col>
        </v-card>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <!-- todo: link to codepen -->
            <!-- todo: figure out an efficient way to generate formatted code in html -->
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> Profile <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-accountmenu-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-accountmenu-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-accountmenu-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">Profile</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-accountmenu-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">givenName</span>="<span class="pl-s">Sam</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-accountmenu-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">surname</span>="<span class="pl-s">Hancock</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-accountmenu-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">jobTitle</span>="<span class="pl-s">Community Liason</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-accountmenu-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:updateProfile</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L7"
class="blob-num js-line-number"
data-line-number="7"
/>
                  <td
id="file-accountmenu-html-LC7"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:changePassword</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L8"
class="blob-num js-line-number"
data-line-number="8"
/>
                  <td
id="file-accountmenu-html-LC8"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:updateSecretQuestion</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L9"
class="blob-num js-line-number"
data-line-number="9"
/>
                  <td
id="file-accountmenu-html-LC9"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:portalSettings</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L10"
class="blob-num js-line-number"
data-line-number="10"
/>
                  <td
id="file-accountmenu-html-LC10"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">:logout</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-accountmenu-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">Profile</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-accountmenu-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>givenName</td>
                <td>String</td>
                <td>The first part of the Name to be displayed</td>
              </tr>
              <tr>
                <td>surname</td>
                <td>String</td>
                <td>The second part of the name to be displayed</td>
              </tr>
              <tr>
                <td>jobTitle</td>
                <td>String</td>
                <td>Job Title for the profile</td>
              </tr>
              <tr>
                <td>updateProfile</td>
                <td>Boolean</td>
                <td>Show/Hide Update profile link</td>
              </tr>
              <tr>
                <td>changePassword</td>
                <td>Boolean</td>
                <td>Show/Hide change password link</td>
              </tr>
              <tr>
                <td>updateSecretQuestion</td>
                <td>Boolean</td>
                <td>Show/Hide link to update security question</td>
              </tr>
              <tr>
                <td>portalSettings</td>
                <td>Boolean</td>
                <td>Show/Hide the link to update portal settings</td>
              </tr>
              <tr>
                <td>logout</td>
                <td>Boolean</td>
                <td>Show/Hide the logout link <span class="orange--text"> **Available Soon**, till then please use logoutHandler</span></td>
              </tr>
              <tr>
                <td>updateProfileHandler</td>
                <td>Function</td>
                <td>Function to handle the update profile request</td>
              </tr>
              <tr>
                <td>changePasswordHandler</td>
                <td>Function</td>
                <td>Function to handle change password request</td>
              </tr>
              <tr>
                <td>updateSecretQuestionHandler</td>
                <td>Function</td>
                <td>Function to handle update security question request</td>
              </tr>
              <tr>
                <td>portalSettingsHandler</td>
                <td>Function</td>
                <td>Function to handle portal settings request</td>
              </tr>
              <tr>
                <td>logoutHandler</td>
                <td>Function</td>
                <td>Function to handle logout request</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Usage'
  }
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>
