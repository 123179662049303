<template>
  <v-container fluid>
    <Banner
      heading="Chips"
      subtitle="Chips are used to convey small pieces of information such as a status or property."
      content-class="pl-0"
    />
    <v-container fluid>
      <v-row justify="center">
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-tabs>
            <v-container class="d-flex">
              <v-tab
                v-for="tab in tabs"
                :key="tab.key"
                :href="`#tab-${tab.key}`"
              >
                {{ tab.title }}
              </v-tab>
            </v-container>
            <v-tab-item value="tab-usage">
              <Usage />
            </v-tab-item>
            <v-tab-item value="tab-demo">
              <Demo />
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Banner from '../../../components/Banner';
import Usage from './Usage';
import Demo from './Demo';

export default {
  name: 'Chips',
  components: {
    Banner,
    Usage,
    Demo
  },
  data() {
    return {
      tabs: [
        {
          title: 'Usage',
          key: 'usage'
        },
        {
          title: 'Demo',
          key: 'demo'
        }
      ]
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__wrapper,
.v-slide-group__wrapper .v-card {
  .v-card__text,
  .v-tab,
  .v-tab--active {
    color: #121212;
  }
  .v-tabs-slider-wrapper {
    border-bottom: 4px solid #ce0037;
  }
  .v-tab--active,
  .v-tab--active .v-card .v-card__text {
    font-weight: bold;
  }
}
::v-deep .imgBackground {
  background-color: #fff;
}
::v-deep .v-tabs-items {
  padding-top: 24px;
}
</style>
