
<template>
  <v-row>
    <v-col cols="9">
      <v-container>
        <h2 class="mt-6">
          About
        </h2>
        <p>Environment information only works on local or test environment to display the version number and environment name</p>
      </v-container>
      <v-card class="pl-6 mt-6">
        <v-row
          style="position: relative; left: -12px;"
        >
          <v-card-title class="pb-0">
            Usage
          </v-card-title>
        </v-row>
        <v-row>
          <v-col>
            <pre style="color:#000000;background:#ffffff;"><span style="color:#800000; font-weight:bold; ">import</span> <span style="color:#800080; ">{</span> EnvironmentInfo <span style="color:#800080; ">}</span> from <span style="color:#800000; ">"</span><span style="color:#0000e6; ">@nswdoe/doe-ui-core</span><span style="color:#800000; ">";</span></pre>
            <pre>
              <table
class="highlight tab-size js-file-line-container"
data-tab-size="8"
>
                <tbody><tr>
                  <td
id="file-accountmenu-html-L1"
class="blob-num js-line-number"
data-line-number="1"
/>
                  <td
id="file-accountmenu-html-LC1"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L2"
class="blob-num js-line-number"
data-line-number="2"
/>
                  <td
id="file-accountmenu-html-LC2"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;</span><span class="pl-ent">EnvironmentInfo</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L3"
class="blob-num js-line-number"
data-line-number="3"
/>
                  <td
id="file-accountmenu-html-LC3"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">versionNo</span>="<span class="pl-s">Demo-test</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L4"
class="blob-num js-line-number"
data-line-number="4"
/>
                  <td
id="file-accountmenu-html-LC4"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">environment</span>="<span class="pl-s">yellow-1.1.0</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L5"
class="blob-num js-line-number"
data-line-number="5"
/>
                  <td
id="file-accountmenu-html-LC5"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">isVisible</span>="<span class="pl-s">true</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L6"
class="blob-num js-line-number"
data-line-number="6"
/>
                  <td
id="file-accountmenu-html-LC6"
class="blob-code blob-code-inner js-file-line"
>    <span class="pl-c1">@hide</span>="<span class="pl-s">hide</span>"</td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L11"
class="blob-num js-line-number"
data-line-number="11"
/>
                  <td
id="file-accountmenu-html-LC11"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L12"
class="blob-num js-line-number"
data-line-number="12"
/>
                  <td
id="file-accountmenu-html-LC12"
class="blob-code blob-code-inner js-file-line"
>  <span class="pl-kos">&lt;/</span><span class="pl-ent">EnvironmentInfo</span><span class="pl-kos">&gt;</span></td>
                </tr>
                <tr>
                  <td
id="file-accountmenu-html-L13"
class="blob-num js-line-number"
data-line-number="13"
/>
                  <td
id="file-accountmenu-html-LC13"
class="blob-code blob-code-inner js-file-line"
><span class="pl-kos">&lt;/</span><span class="pl-ent">v-container</span><span class="pl-kos">&gt;</span></td>
                </tr>
               </tbody>
              </table>
              </pre>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-6">
        <!-- todo: componentise and format this parmas table better -->
        <v-card-title>
          Component Properties
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Desc
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>versionNo</td>
                <td>String</td>
                <td>Version number</td>
              </tr>
              <tr>
                <td>environment</td>
                <td>String</td>
                <td>Environment name</td>
              </tr>
              <tr>
                <td>isVisible</td>
                <td>Boolean</td>
                <td>Only true for test/local environment and false in production</td>
              </tr>
              <tr>
                <td>hide</td>
                <td>Function</td>
                <td>Set isVisible to false when double click</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Usage'
  }
</script>

<style scoped>
.pl-ent {
  color: #22863a;
}
.pl-c1,
.pl-s .pl-v {
  color: #005cc5;
}

.imgBackground img{
  width: 100%;
  height: 100%;
}
.pl-pds,
.pl-s,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sra,
.pl-sr .pl-sre {
  color: #032f62;
}
</style>