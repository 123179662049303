<template>
  <v-container
    fluid
  >
    <Banner
      heading="Contact Us"
      content-class="pl-0"
    />
    <v-row
      class="contentSpacer mt-15"
    >
      <v-col cols="10">
        <p>The DoE Application Design System is currently under development - but even after its completion, it will be a living system, constantly evolving based on user and business needs.</p>
        <p>
          We welcome all feedback to help us make ADS the best it can possibly be and provide real value to designers,
          developers and managers.
        </p>
        <v-row>
          <v-col cols="4">
            <v-card
              style="text-align: center;"
              class="pa-6"
              height="184"
            >
              <v-card-text>
                <div style="width: 87px; height: 34px; display: inline-block;">
                  <v-img src="./assets/npm.png" />
                </div>
                <div class="title-color mt-5">
                  NPM
                </div>
                <div class="mt-2">
                  <a
                    href="https://www.npmjs.com/package/@nswdoe/doe-ui-core"
                    target="_blank"
                    style="color: #407EC9;"
                  >@nswdoe/doe-ui-core</a>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
              style="text-align: center;"
              class="pa-4"
              height="184"
            >
              <v-card-text>
                <div style="width: 48px; display: inline-block;">
                  <v-img src="./assets/slack.png" />
                </div>
                <div class="title-color mt-4">
                  Slack
                </div>
                <div class="mt-2 mb-2">
                  <a
                    href="https://edutalktech.slack.com/archives/CPNHUS8FQ"
                    target="_blank"
                    style="color: #407EC9;"
                  >#ask-ads-app-design-system</a>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card
              style="text-align: center;"
              class="pa-4"
              height="184"
            >
              <v-card-text>
                <div
                  style="width: 48px; display: inline-block;"
                  class="mt-2"
                >
                  <v-icon
                    class="material-icons-outlined"
                    x-large
                    color="#041E42"
                  >
                    email
                  </v-icon>
                </div>
                <div class="title-color mt-5">
                  Email
                </div>
                <div class="mt-2 mb-2">
                  <a
                    href="mailto:ui@det.nsw.edu.au"
                    style="color: #407EC9;"
                  >ui@det.nsw.edu.au</a>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import Banner from '../../components/Banner';

  export default {
    name: 'ContactUs',
    components: {
      Banner
    }
  }
</script>

<style scoped lang="scss">

  .apptitle {
    font-size: 3em;
    font-weight: 600;
    color: #fff;
    display: inline-block;
    padding: 0;
    vertical-align: top;
  }
  .theme--dark .v-icon{ color: $ads-light-20 !important; }
  ::v-deep .v-avatar img {
    width: 60px;
    margin: auto;
  }

  ::v-deep .v-list-item__title.headline {
    font-size: 18px !important;
    font-weight: bold;
    color: #1D428A;
  }

  .title-color {
    color: $ads-navy;
    font-weight: bold;
    font-size: 20px;
  }
</style>
