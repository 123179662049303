<template>
  <ul>
    <li
      v-for="(li, i) in items"
      :key="i"
    >
      <router-link
        v-if="li.href && li.href !== ''"
        :to="`${li.href}`"
      >
        {{ getText(li) }}
      </router-link>
      <a
        v-else-if="li.url && li.url !== ''"
        :href="`${li.url}`"
      >
        {{ getText(li) }}
      </a>
      <span v-else>{{ getText(li) }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PropsList',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getText(item) {
      if (item instanceof Object && item.text) {
        return item.text;
      }
      return item;
    }
  }
}
</script>